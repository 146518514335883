import { type IDrawerListItem, WorkloadCreationDrawerEntities } from "@/models/workload-creation-drawer-model";
import { computeResourceService } from "@/services/control-plane/compute-resource.service/compute-resource.service";
import { environmentService } from "@/services/control-plane/environment.service/environment.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { workloadHybridUtil } from "@/utils/workload-hybrid.util/workload-hybrid.util";
import type { IAssetsFilter } from "@/models/filter.model";
import { type ISelectOption } from "@/models/global.model";
import { DATA_SOURCES_TYPES_TO_NAMES } from "@/models/data-source.model";
import { DATA_SOURCE_ICONS_NAMES } from "@/common/icons.constant";

interface IDrawerEntityMeta {
  entityLabel: string;
  title: string;
  defaultIcon: string;
  creationOptions?: ISelectOption[];
  loadFunction: (filter: IAssetsFilter) => Promise<IDrawerListItem[]>;
}

const loadDataSources = async (filter: IAssetsFilter) => {
  const dataSources = await dataSourceService.list(filter);
  return workloadHybridUtil.dataSourceListToDrawerItems(dataSources);
};

const loadEnvironments = async (filter: IAssetsFilter) => {
  const environments = await environmentService.list(filter);
  return workloadHybridUtil.environmentListToDrawerItems(environments);
};

const loadComputeResources = async (filter: IAssetsFilter) => {
  const computeResources = await computeResourceService.list(filter);
  return workloadHybridUtil.computeListToDrawerItems(computeResources);
};

export const workloadCreationDrawerEntityMapper: Record<WorkloadCreationDrawerEntities, IDrawerEntityMeta> = {
  [WorkloadCreationDrawerEntities.Environment]: {
    entityLabel: "environment",
    title: "Environments",
    defaultIcon: "environment-gray-new",
    loadFunction: loadEnvironments,
  },
  [WorkloadCreationDrawerEntities.Compute]: {
    entityLabel: "compute resource",
    title: "Compute Resources",
    defaultIcon: "compute-resource-gray-new",
    loadFunction: loadComputeResources,
  },
  [WorkloadCreationDrawerEntities.DataSource]: {
    entityLabel: "data source",
    title: "Data Sources",
    defaultIcon: "data-source-gray-new",
    creationOptions: [
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.NFS,
        value: DATA_SOURCES_TYPES_TO_NAMES.NFS,
        svg: DATA_SOURCE_ICONS_NAMES.nfs,
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.PVC,
        value: DATA_SOURCES_TYPES_TO_NAMES.PVC,
        svg: DATA_SOURCE_ICONS_NAMES.pvc,
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.S3,
        value: DATA_SOURCES_TYPES_TO_NAMES.S3,
        svg: DATA_SOURCE_ICONS_NAMES.s3,
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.GIT,
        value: DATA_SOURCES_TYPES_TO_NAMES.GIT,
        svg: DATA_SOURCE_ICONS_NAMES.git,
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH,
        value: DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH,
        svg: DATA_SOURCE_ICONS_NAMES.hostPath,
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP,
        value: DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP,
        svg: DATA_SOURCE_ICONS_NAMES["config-map"],
      },
      {
        label: DATA_SOURCES_TYPES_TO_NAMES.SECRET_VOLUME,
        value: DATA_SOURCES_TYPES_TO_NAMES.SECRET_VOLUME,
        svg: DATA_SOURCE_ICONS_NAMES["secret-volume"],
      },
    ],
    loadFunction: loadDataSources,
  },
};
