/* eslint-disable */

import type { PolicyMeta, PolicyType } from "@/swagger-models/policy-service-client";
import {
  PolicySyncStatusOfClusterStatusEnum,
  ScopeType,
  type PolicyListEntry,
} from "@/swagger-models/policy-service-client";

import { tableUtil } from "../table.util/table.util";
import type { IStatusColOptions } from "@/models/table.model";
import { capitalizeString } from "../string.util/string.util";
import type { TPolicyScopes } from "@/models/policy.model";
import { errorMessages } from "@/common/error-message.constant";
import type { ISelectOption } from "@/models/global.model";
import type { UnifiedPolicyInfoPerReplicaType, WorkspaceSpecSpec } from "@/swagger-models/workloads-client";
import type {
  InferenceSpecificRunParams,
  PolicyInfo,
  WorkspaceSpecificRunParams,
} from "@/swagger-models/assets-service-client";

import { makeId } from "../common.util";

export const policyUtil = {
  createPolicyIdFromPolicyTypeAndMeta,
  createPolicyId,
  createPolicyIdFromPolicyListEntry,
  getPolicyTypeScopeAndScopeId,
  getPolicyStatusColOptions,
  getQueryObjectFromPolicyId,
  getPolicyScopePropertiesFromPolicyId,
  getOptionsWithRules,
  cleanupItemizeExcludeDuplicates,
  cleanupLockedItems,
  cleanupItemizeBeforeSubmission,
  cleanupFlatItemizeBeforeSubmission,
};

export const policyIdSeperator = "_";

type IListPolicyQueryPolicyType = "Workspace" | "Training" | "Distributed";
export interface IListPolicyQuery {
  policyType?: IListPolicyQueryPolicyType;
  scope?: TPolicyScopes;
  departmentId?: string;
  projectId?: string;
  clusterId?: string;
}

export interface IPolicyTypeScopeAndScopeId {
  type: PolicyType;
  scope: TPolicyScopes;
  scopeId: string | undefined;
}

export interface IPolicyScopeProperties {
  scope: ScopeType;
  projectId?: string | null;
  departmentId?: string | null;
  clusterId?: string | null;
}

function createPolicyIdFromPolicyListEntry(policy: PolicyListEntry) {
  if (!policy.type) return policyIdSeperator;
  return createPolicyIdFromPolicyTypeAndMeta(policy.type, policy.meta);
}

function createPolicyIdFromPolicyTypeAndMeta(policyType: PolicyType, policyMeta?: PolicyMeta): string {
  if (!policyMeta) return policyIdSeperator;
  const { scope, projectId, departmentId, clusterId } = policyMeta;
  switch (scope) {
    case ScopeType.Tenant:
      return policyUtil.createPolicyId(policyType, ScopeType.Tenant);
    case ScopeType.Cluster:
      return policyUtil.createPolicyId(policyType, ScopeType.Cluster, String(clusterId));
    case ScopeType.Department:
      return policyUtil.createPolicyId(policyType, ScopeType.Department, String(departmentId));
    case ScopeType.Project:
      return policyUtil.createPolicyId(policyType, ScopeType.Project, String(projectId));
    default:
      return policyIdSeperator;
  }
}

function createPolicyId(policyType: PolicyType, policyScope: ScopeType, scopeId?: string | number): string {
  return !!scopeId
    ? `${policyType}${policyIdSeperator}${policyScope}${policyIdSeperator}${scopeId}`
    : `${policyType}${policyIdSeperator}${policyScope}`;
}

function getPolicyTypeScopeAndScopeId(policyId: string): IPolicyTypeScopeAndScopeId {
  const [type, scope, scopeId] = policyId.split(policyIdSeperator);
  return {
    type: type as PolicyType,
    scope: scope as TPolicyScopes,
    scopeId,
  };
}

function getQueryObjectFromPolicyId(policyId: string): IListPolicyQuery {
  const policyTypeAndScope: IPolicyTypeScopeAndScopeId = getPolicyTypeScopeAndScopeId(policyId);

  const policyType = capitalizeString(policyTypeAndScope.type) as IListPolicyQueryPolicyType;

  switch (policyTypeAndScope.scope) {
    case ScopeType.Tenant:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
      };
    case ScopeType.Cluster:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        clusterId: policyTypeAndScope.scopeId,
      };
    case ScopeType.Department:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        departmentId: policyTypeAndScope.scopeId,
      };
    case ScopeType.Project:
      return {
        policyType,
        scope: policyTypeAndScope.scope,
        projectId: policyTypeAndScope.scopeId,
      };
  }
}
function getPolicyScopePropertiesFromPolicyId(policyId: string): IPolicyScopeProperties {
  const policyTypeAndScope: IPolicyTypeScopeAndScopeId = getPolicyTypeScopeAndScopeId(policyId);

  switch (policyTypeAndScope.scope) {
    case ScopeType.Tenant:
      return {
        scope: policyTypeAndScope.scope,
      };
    case ScopeType.Cluster:
      return {
        scope: policyTypeAndScope.scope,
        clusterId: String(policyTypeAndScope.scopeId) || undefined,
      };
    case ScopeType.Department:
      return {
        scope: policyTypeAndScope.scope,
        departmentId: String(policyTypeAndScope.scopeId) || undefined,
      };
    case ScopeType.Project:
      return {
        scope: policyTypeAndScope.scope,
        projectId: Number(policyTypeAndScope.scopeId) || undefined,
      };
  }
}

function getPolicyStatusColOptions(
  status?: PolicySyncStatusOfClusterStatusEnum,
  errorMessage?: string,
): IStatusColOptions {
  if (!status) {
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
      filterKey: "status",
    };
  }
  return tableUtil.getStatusColOptions(policyStatusMap[status], errorMessage);
}

export const policyStatusMap: Record<PolicySyncStatusOfClusterStatusEnum, IStatusColOptions> = {
  [PolicySyncStatusOfClusterStatusEnum.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.NotReady]: {
    status: "Not Ready",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.DeletionFailed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Applying]: {
    status: "Updating...",
    displayAnimation: true,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [PolicySyncStatusOfClusterStatusEnum.PendingDeletion]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
};

interface IRuleOpt<T> {
  value: T | null;
  displayed?: string | null;
}

interface ISelectOptionWithRules<T> extends Omit<ISelectOption, "value"> {
  value: T | null;
}

function getOptionsWithRules<T>(
  options: ISelectOption[],
  ruleOptions?: IRuleOpt<T>[] | null,
  isClosedList?: boolean,
): ISelectOption[] {
  if (!ruleOptions?.length) return options;

  if (isClosedList) {
    return options.map((option: ISelectOption) => {
      // looking for the option in the rules options to get the displayed name and know what needs to be disabled
      const foundInRulesOptions = ruleOptions?.find((rule) => rule.value === option.value);
      const opt = {
        ...option,
        label: foundInRulesOptions?.displayed || option.label,
      };
      if (!foundInRulesOptions) {
        opt.disable = true;
        opt.disabledTooltip = errorMessages.CANT_BE_SELECTED_POLICY;
      }
      return opt;
    });
  } else {
    // in case of open list we return the rules options only everything else is ignored
    return ruleOptions.map((ruleOption: IRuleOpt<T>) => {
      return {
        value: ruleOption.value,
        label: ruleOption.displayed || ruleOption.value,
      } as ISelectOption;
    });
  }
}

function cleanupItemizeExcludeDuplicates<T extends { exclude?: boolean | null }>(
  items: T[],
  mainKey: keyof T,
): Array<T> {
  const nameMap: { [key: string]: T } = {};
  for (const item of items) {
    const mainKeyValue = item[mainKey] as string;
    // add if not exists, if exclude exists in the map replace it.
    if (!nameMap[mainKeyValue] || (!item.exclude && nameMap[mainKeyValue].exclude)) {
      nameMap[mainKeyValue] = item;
    } else if (!nameMap[mainKeyValue].exclude && !item.exclude) {
      // if we have a duplicate we will add it with a new id
      nameMap[makeId(6)] = item;
    }
  }
  return Object.values(nameMap);
}

function cleanupLockedItems<T>(items: T[], mainKey: keyof T, locked: string[]): T[] {
  return items.filter((item) => !locked.includes(item[mainKey] as string));
}

function cleanupItemizeBeforeSubmission<T extends WorkspaceSpecificRunParams & InferenceSpecificRunParams>(
  specificRunParams: T,
  policyRules?: PolicyInfo | null,
): T {
  const annoNoDups = cleanupItemizeExcludeDuplicates(specificRunParams.annotations || [], "name");
  specificRunParams.annotations = cleanupLockedItems(
    annoNoDups || [],
    "name",
    policyRules?.rules?.specificRunParams?.annotations?.instances?.locked || [],
  );

  const labelNoDups = cleanupItemizeExcludeDuplicates(specificRunParams.labels || [], "name");
  specificRunParams.labels = cleanupLockedItems(
    labelNoDups || [],
    "name",
    policyRules?.rules?.specificRunParams?.labels?.instances?.locked || [],
  );

  const envVarNoDups = cleanupItemizeExcludeDuplicates(specificRunParams.environmentVariables || [], "name");
  specificRunParams.environmentVariables = cleanupLockedItems(
    envVarNoDups || [],
    "name",
    policyRules?.rules?.specificRunParams?.environmentVariables?.instances?.locked || [],
  );

  const tolNoDups = cleanupItemizeExcludeDuplicates(specificRunParams.tolerations || [], "name");
  specificRunParams.tolerations = cleanupLockedItems(
    tolNoDups || [],
    "name",
    policyRules?.rules?.specificRunParams?.tolerations?.instances?.locked || [],
  );

  return specificRunParams;
}

function cleanupFlatItemizeBeforeSubmission<T extends WorkspaceSpecSpec>(
  flatSpec: T,
  policy?: UnifiedPolicyInfoPerReplicaType | null,
): T {
  const annoNoDups = cleanupItemizeExcludeDuplicates(flatSpec.annotations || [], "name");
  flatSpec.annotations = cleanupLockedItems(
    annoNoDups || [],
    "name",
    policy?.rules?.annotations?.instances?.locked || [],
  );

  const labelNoDups = cleanupItemizeExcludeDuplicates(flatSpec.labels || [], "name");
  flatSpec.labels = cleanupLockedItems(labelNoDups || [], "name", policy?.rules?.labels?.instances?.locked || []);

  const envVarNoDups = cleanupItemizeExcludeDuplicates(flatSpec.environmentVariables || [], "name");
  flatSpec.environmentVariables = cleanupLockedItems(
    envVarNoDups || [],
    "name",
    policy?.rules?.environmentVariables?.instances?.locked || [],
  );

  const tolNoDups = cleanupItemizeExcludeDuplicates(flatSpec.tolerations || [], "name");
  flatSpec.tolerations = cleanupLockedItems(
    tolNoDups || [],
    "name",
    policy?.rules?.tolerations?.instances?.locked || [],
  );

  if (flatSpec.compute?.extendedResources) {
    const eResNoDups = cleanupItemizeExcludeDuplicates(flatSpec.compute.extendedResources || [], "resource");
    flatSpec.compute.extendedResources = cleanupLockedItems(
      eResNoDups || [],
      "resource",
      policy?.rules?.compute?.extendedResources?.instances?.locked || [],
    );
  }

  return flatSpec;
}
