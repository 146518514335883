import { Action, ResourceType } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";
import { EWorkloadViewType, WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const workloadRoutes: RunaiRouteRecord = {
  path: "/workloads",
  component: () => import("@/views/workload/workload-app.vue"),
  children: [
    {
      path: "",
      component: () => import("@/views/workload/workload-index.vue"),
      name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
      meta: {
        pageTitle: "Workloads",
        resourceType: [ResourceType.Workloads, ResourceType.Inferences],
        requiresAuth: true,
        minPermittedActions: [Action.Read],
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      props: () => ({ viewType: EWorkloadViewType.Active }),
    },
    {
      path: "deleted",
      component: () => import("@/views/workload/workload-index.vue"),
      name: WORKLOAD_ROUTE_NAMES.WORKLOAD_DELETED_INDEX,
      meta: {
        pageTitle: "Deleted workloads",
        resourceType: [ResourceType.Workloads, ResourceType.Inferences],
        requiresAuth: true,
        minPermittedActions: [Action.Read],
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
      props: () => ({ viewType: EWorkloadViewType.Deleted }),
    },
  ],
};
