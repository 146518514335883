<template>
  <section style="min-width: 500px">
    <p>Copy the client ID and client secret and store them securely</p>
    <div class="row items-center">
      <span class="field-type q-mr-xl">Application: </span>
      <q-input class="application-field" :model-value="name" readonly borderless> </q-input>
    </div>
    <div class="row items-center">
      <span class="field-type q-mr-xl">Client ID: </span>
      <q-input class="application-field" :model-value="client" readonly borderless type="text">
        <template v-slot:after>
          <runai-copy-to-clipboard-btn :text-to-copy="client" />
        </template>
      </q-input>
    </div>
    <div class="row items-center">
      <span class="field-type q-mr-xl">{{ !isCreateMode ? "New client secret: " : "Client secret: " }} </span>
      <q-input
        class="application-field client-secret"
        :type="isPassHidden ? 'password' : 'text'"
        :model-value="secret"
        readonly
        borderless
      >
        <template v-slot:append>
          <q-btn
            flat
            size="10px"
            round
            :icon="isPassHidden ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
            @click="isPassHidden = !isPassHidden"
          >
            <q-tooltip class="tool-tip-dark">{{ isPassHidden ? "Show secret" : "Hide secret" }} </q-tooltip>
          </q-btn>
        </template>
        <template v-slot:after>
          <runai-copy-to-clipboard-btn :text-to-copy="secret" />
        </template>
      </q-input>
    </div>
  </section>
</template>
<script lang="ts">
import { RunaiCopyToClipboardBtn } from "@/components/common/runai-copy-to-clipboard-btn/";
import { defineComponent } from "vue";

export default defineComponent({
  components: { RunaiCopyToClipboardBtn },
  props: {
    isCreateMode: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPassHidden: true as boolean,
    };
  },
});
</script>
<style lang="scss" scoped>
.tool-tip-dark {
  background-color: $positive;
  color: $white;
}
.field-type {
  min-width: 100px;
  color: $black-54;
}
.application-field {
  width: 340px;
}
.client-secret {
  margin-left: -10px;
}
</style>
