<template>
  <section class="pvc-instance-section">
    <div class="q-mb-md">
      <div class="q-py-md">Set the data origin</div>
      <div class="row">
        <runai-tooltip-wrapper
          :display-tooltip="allowOnlyContainerPath"
          tooltip-text="This was defined when the data source was created and can't be modified"
          anchor="bottom middle"
          self="bottom middle"
          class="col-6"
        >
          <runai-select
            :disabled="allowOnlyContainerPath"
            class="claim-name-select"
            standard
            :model-value="modelValue.claimName"
            :options="pvcClaimNames"
            label="Claim name"
            stack-label
            placeholder="Select..."
            :rules="[isClaimNameSelected]"
            @update:model-value="$emit('update:model-value', { ...modelValue, configMap: $event })"
            :loading="loadingPvcClaimNames"
            no-option-text="No names found"
          />
        </runai-tooltip-wrapper>
      </div>
    </div>

    <div>
      <div class="q-py-md">{{ containerPathTitle }}</div>
      <div class="row">
        <q-input
          class="col-6"
          aid="mount-path-input"
          :model-value="modelValue.path"
          no-error-icon
          label="Container path"
          stack-label
          placeholder="e.g: /home/local/data"
          input-class="placeholder-italic"
          :rules="[notEmpty, isDirectoryPath]"
          @update:model-value="$emit('update:model-value', { ...modelValue, path: $event })"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
// Models
import type { IUIPvcInstance } from "@/models/workload.model";
// Utils
import { isNotEmpty, isValidDirectoryPath } from "@/common/form.validators";
// Constants
import { errorMessages } from "@/common/error-message.constant";
import { assetsUtil } from "@/utils/assets.util/assets.util";

export default defineComponent({
  name: "pvc-instance-section",
  components: {
    RunaiSelect,
    RunaiTooltipWrapper,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Object as PropType<IUIPvcInstance>,
      required: true,
    },
    allowOnlyContainerPath: {
      type: Boolean,
      default: false,
    },
    pvcClaimNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    loadingPvcClaimNames: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    containerPathTitle(): string {
      return assetsUtil.containerPathTitle();
    },
  },
  methods: {
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.PATH_NOT_EMPTY;
    },
    isClaimNameSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SELECT_A_NAME;
    },
  },
});
</script>
