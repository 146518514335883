import {
  NotificationChannelsApi,
  NotificationStateApi,
  NotificationTypesApi,
  SubscriptionsApi,
  Configuration,
  type ConfigurationParameters,
} from "@/swagger-models/notifications-proxy-client";

class NotificationServiceApi {
  configuration: Configuration;

  notificationChannelsApi: NotificationChannelsApi;
  notificationStateApi: NotificationStateApi;
  notificationTypesApi: NotificationTypesApi;
  subscriptionApi: SubscriptionsApi;

  constructor() {
    this.configuration = new Configuration();

    this.notificationChannelsApi = new NotificationChannelsApi(this.configuration, this.baseUrl);
    this.notificationStateApi = new NotificationStateApi(this.configuration, this.baseUrl);
    this.notificationTypesApi = new NotificationTypesApi(this.configuration, this.baseUrl);
    this.subscriptionApi = new SubscriptionsApi(this.configuration, this.baseUrl);
  }
  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const notificationServiceApi = new NotificationServiceApi();
