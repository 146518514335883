import { K8S_API } from "@/common/api.constant";
import type { IPrometheusResponse } from "@/models/prometheus.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
const PROM_BASE_URL = `${K8S_API.v1}/prom/proxy/api/v1/`;
const enum PrometheusQueryType {
  QUERY = "query",
  QUERY_RANGE = "query_range",
}

export const prometheusService = {
  multipleQueries,
  multipleQueriesWithTimeRange,
  rangeQuery,
  get,
};

function multipleQueries(queries: Record<string, string>): Promise<Array<IPrometheusResponse>> {
  const requestKeys: Array<string> = Object.keys(queries);
  const queriesPrms: Array<Promise<IPrometheusResponse>> = requestKeys.map(
    (key: string): Promise<IPrometheusResponse> => {
      const time: number = Math.floor(Date.now() / 1000);
      return get({ query: queries[key], time })
        .then((res) => res.data)
        .then((data) => {
          if (!data?.result) {
            console.error(`no data return for query with key ${key}`);
            return { key, data: [] };
          }
          return { key, data: data.result };
        });
    },
  );

  // Return only successful Prometheus responses.
  return Promise.allSettled(queriesPrms).then((results) =>
    results
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<IPrometheusResponse>).value),
  );
}

function multipleQueriesWithTimeRange(
  queries: Record<string, string>,
  start: number,
  end: number,
  step: number,
): Promise<Array<IPrometheusResponse>> {
  const requestKeys: Array<string> = Object.keys(queries);
  const queriesPrms: Array<Promise<IPrometheusResponse>> = requestKeys.map(
    (key: string): Promise<IPrometheusResponse> => {
      return get({ query: queries[key], start, end, step }, PrometheusQueryType.QUERY_RANGE)
        .then((res) => res.data)
        .then((data) => ({ key, data: data.result }));
    },
  );
  //return only successful prometheus responses.
  return Promise.allSettled(queriesPrms).then((results) =>
    results
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<IPrometheusResponse>).value),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function rangeQuery(query: string, start: number, end: number, step: number): Promise<any> {
  const res = await get({ query, start, end, step }, PrometheusQueryType.QUERY_RANGE);
  return res?.data?.result[0]?.values;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(query: Record<string, string | number>, queryType = PrometheusQueryType.QUERY): Promise<any> {
  return controlPlaneService.get(PROM_BASE_URL + queryType, query);
}
