import { ResourceType, Action } from "@/swagger-models/authorization-client";

import JobNew from "@/views/job/job-new.vue";
import JobHistory from "@/views/job/job-history.vue";
import { JOB_ROUTE_NAMES } from "./job.routes.names";
import type { RunaiRouteRecord } from "vue-router";
import { EMenuSubjectNames } from "@/components/infra/runai-layout/routes-model/routes-model";

export const jobRoutes: RunaiRouteRecord = {
  path: "/jobs",
  component: () => import("@/views/job/job-app.vue"),
  children: [
    {
      path: "submit",
      name: JOB_ROUTE_NAMES.JOB_NEW,
      component: JobNew,
      meta: {
        pageTitle: "Jobs (legacy)",
        resourceType: ResourceType.Jobs,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideVersion: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
    },
    {
      path: "history",
      name: JOB_ROUTE_NAMES.JOB_HISTORY,
      component: JobHistory,
      meta: {
        pageTitle: "Jobs (legacy)",
        resourceType: ResourceType.Jobs,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
        parentSubject: EMenuSubjectNames.WORKLOADS_MANAGER,
      },
    },
  ],
};
