<template>
  <section class="environment-tool-box q-mb-md" v-if="tool.id">
    <q-btn
      aid="remove-tool-button"
      round
      flat
      icon="fa-regular fa-xmark"
      class="close-btn"
      @click="$emit('removed')"
      v-if="!isAssetBasedWorkloadForm && removeable"
      :disable="disable"
    />

    <section class="row gap q-pa-md top-section">
      <span>
        <tool-type-select
          :disable="isAssetBasedWorkloadForm || disable"
          :tool-type="tool.toolType"
          :options="availableToolTypeOptions"
          @value-changed="updateToolType"
          :not-required="defaultToolBox"
          aid="tool-type-select"
        />
        <q-tooltip v-if="isAssetBasedWorkloadForm" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
      </span>
      <span :class="showAuthorizedSection ? 'col-5 flex-1' : 'col-6'">
        <q-input
          v-if="tool.toolType === 'custom'"
          :model-value="tool.name"
          @update:model-value="updateToolName($event?.toString() || '')"
          label="Tool name"
          no-error-icon
          stack-label
          placeholder="Enter a name"
          :disable="isAssetBasedWorkloadForm || disable"
          :rules="[nameNotEmpty]"
          aid="tool-type-custom-input"
        />
        <q-input
          aid="tool-type-wandb-input"
          v-else-if="isExternalTool"
          label="URL"
          stack-label
          placeholder="Enter a URL"
          :rules="[urlNotEmpty]"
          no-error-icon
          :model-value="tool.externalUrl"
          :disable="disable"
          @update:model-value="updateExternalUrl($event?.toString() || '')"
        />
        <q-tooltip v-if="isAssetBasedWorkloadForm" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
      </span>
      <div v-if="showAuthorizedSection && clusterId" class="private-connection-toggle row items-center">
        <connection-access
          :access="toolAccessModel"
          :tool-name="tool.name"
          @save="onToolAccessChanged"
          :is-multi-users="isMultiUsers"
          connection-type="tool"
        />
      </div>
    </section>

    <transition name="slide-fade">
      <section v-if="tool.toolType && !isExternalTool" class="bottom-section row gap justify-between q-pa-md">
        <section class="row gap">
          <connection-type-select
            v-if="tool.connectionType"
            :disable="isAssetBasedWorkloadForm || disable"
            :connection-type="tool.connectionType"
            :options="availableConnectionTypeOptions"
            @value-changed="updateConnectionType"
            :tool-tip-text="disabledTooltipText"
          />
          <template v-if="tool.connectionType === 'ExternalUrl' && tool.isCustomUrl !== null">
            <span>
              <autogen-select
                :disable="isAssetBasedWorkloadForm || disable"
                :init-value="tool.isCustomUrl"
                custom-label="Custom URL"
                @value-changed="updateIsCustomUrl"
              />
              <q-tooltip v-if="isAssetBasedWorkloadForm" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
            </span>
            <span v-if="tool.isCustomUrl">
              <q-input
                aid="custom-url-input"
                :disable="isEnvironmentForm || disable"
                stack-label
                :input-style="{ minWidth: '220px' }"
                input-class="placeholder-italic"
                placeholder="e.g https://runai.domain.hpc.org"
                :debounce="300"
                :model-value="tool.externalUrl"
                @update:model-value="updateTool({ ...tool, externalUrl: $event as string })"
                label="URL"
                :rules="isRequired ? [urlNotEmpty, isUrl] : [isUrl]"
                no-error-icon
              />
              <q-tooltip max-width="250px" v-if="isEnvironmentForm">
                The URL is configured within the workspace using this environment
              </q-tooltip>
            </span>
          </template>
          <template v-if="tool.connectionType === 'NodePort' && tool.isCustomPort !== null">
            <span>
              <autogen-select
                :init-value="tool.isCustomPort"
                :disable="isAssetBasedWorkloadForm"
                custom-label="Custom port"
                @value-changed="updateIsCustomPort"
              />
              <q-tooltip v-if="isAssetBasedWorkloadForm" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
            </span>
            <span v-if="tool.isCustomPort">
              <q-input
                aid="custom-port-input"
                type="number"
                :debounce="300"
                stack-label
                :input-style="{ minWidth: '220px' }"
                input-class="placeholder-italic"
                placeholder="e.g 30065"
                :disable="isEnvironmentForm || disable"
                :model-value="tool.nodePort"
                @update:model-value="updateTool({ ...tool, nodePort: Number($event) })"
                label="Node port"
                :rules="isRequired ? [validPort, validPortRange] : [validPortRange]"
                no-error-icon
              />
              <q-tooltip max-width="250px" v-if="isEnvironmentForm">
                The node port is configured within the workspace using this environment
              </q-tooltip>
            </span>
          </template>
        </section>
        <span>
          <q-input
            :disable="isAssetBasedWorkloadForm || disable"
            label="Container port"
            :input-style="{ maxWidth: '100px' }"
            type="number"
            :model-value="tool.containerPort"
            @update:model-value="updateTool({ ...tool, containerPort: Number($event) })"
            :rules="[validPort]"
            no-error-icon
          />
          <q-tooltip v-if="isAssetBasedWorkloadForm" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
        </span>
      </section>
    </transition>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Stores
import { useClusterStore } from "@/stores/cluster.store";

// Utils
import { isNotEmpty, isValidUrl } from "@/common/form.validators";

// Models
import { InternalConnectionType, ToolType } from "@/swagger-models/assets-service-client";
import { errorMessages } from "@/common/error-message.constant";
import type { ISelectOption } from "@/models/global.model";
import {
  connectionTypeOptions,
  toolTypeOptions,
  type IUIConnection,
  type IConnectionTypeSelectOption,
  type IToolTypeSelectOption,
} from "@/models/environment.model";
import {
  type IConnectionAccess,
  EAccessOptions,
} from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";

// Cmps
import ConnectionTypeSelect from "../connection-type-select.vue";
import AutogenSelect from "../autogen-select.vue";
import ToolTypeSelect from "../tool-type-select.vue";
import { ConnectionAccess } from "@/components/environment/connection-access";

// constants
import { MIN_CLUSTER_VERSION_FOR_WORKLOAD_ACCESS_MULTI_USERS } from "@/common/version.constant";

export enum EToolBoxFormType {
  Environment = "environment",
  WorkloadAssetsBased = "workload-assets",
}

export default defineComponent({
  components: {
    ToolTypeSelect,
    ConnectionTypeSelect,
    AutogenSelect,
    ConnectionAccess,
  },
  emits: ["removed", "update-tool"],
  props: {
    defaultToolBox: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    tools: {
      type: Array as PropType<Array<IUIConnection>>,
      required: true,
    },
    tool: {
      type: Object as PropType<IUIConnection>,
      required: true,
    },
    removeable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formType: {
      type: String as PropType<EToolBoxFormType>,
      required: false,
    },
    toolNames: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    clusterId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      externalTools: [ToolType.Wandb, ToolType.Comet] as Array<ToolType>,
    };
  },
  computed: {
    defaultToolTypeAndConnectionForName(): { toolType: string; connectionType: string } {
      if (!this.tool.name) return { toolType: "", connectionType: "" };
      const [toolType, connectionType] = this.tool.name.split(" - ");
      return { toolType: toolType || "", connectionType: connectionType || "" };
    },
    isEnvironmentForm(): boolean {
      return this.formType === EToolBoxFormType.Environment;
    },
    isAssetBasedWorkloadForm(): boolean {
      return this.formType === EToolBoxFormType.WorkloadAssetsBased;
    },
    isExternalTool(): boolean {
      if (!this.tool.toolType) return false;
      return this.externalTools.includes(this.tool.toolType);
    },
    disabledTooltipText(): string {
      const urlText = "The tool and its connection are defined within the environment. Set the URL here.";
      const portText = "The tool and its connection are defined within the environment. Set the node port here.";
      return this.tool?.isCustomPort ? portText : urlText;
    },
    availableToolTypeOptions(): Array<IToolTypeSelectOption> {
      const otherTools: Array<IUIConnection> = this.tools.filter((tool: IUIConnection) => tool.id !== this.tool.id);
      return toolTypeOptions.map((typeOption: IToolTypeSelectOption) => {
        if (
          typeOption.value !== ToolType.Custom &&
          otherTools.filter((tool: IUIConnection) => tool.toolType === typeOption.value).length >=
            connectionTypeOptions.length
        )
          return {
            ...typeOption,
            disable: true,
            toolTip: "This tool is already used with all possible connection types",
          };
        return typeOption;
      });
    },
    availableConnectionTypeOptions(): Array<IConnectionTypeSelectOption> {
      const usedConnectionTypes: Record<InternalConnectionType, number> = this.tools.reduce(
        (acc, { id, toolType, connectionType }) => {
          if (id === this.tool.id || toolType !== this.tool.toolType || !connectionType || toolType === ToolType.Custom)
            return acc;
          acc[connectionType] = 1;
          return acc;
        },
        {} as Record<InternalConnectionType, number>,
      );
      return connectionTypeOptions.map((typeOption: IConnectionTypeSelectOption) => {
        const unavailable = !!usedConnectionTypes[typeOption.value];
        const updatedOption: IConnectionTypeSelectOption = { ...typeOption, disable: unavailable };
        if (unavailable) updatedOption.toolTip = "This connection type is already in use by this tool";
        return updatedOption;
      });
    },
    showAuthorizedSection(): boolean {
      if (this.isEnvironmentForm) return false;
      return this.tool.connectionType === InternalConnectionType.ExternalUrl;
    },
    toolAccessModel(): IConnectionAccess {
      let accessOption = EAccessOptions.EVERYONE;
      if (this.tool.authorizedUsers) {
        accessOption = EAccessOptions.SPECIFIC_USERS;
      } else if (this.tool.authorizedGroups) {
        accessOption = EAccessOptions.GROUPS;
      }
      return {
        authorizedUsers: this.tool.authorizedUsers,
        authorizedGroups: this.tool.authorizedGroups,
        accessOption,
      };
    },
    isMultiUsers(): boolean {
      return this.clusterStore.isClusterVersionSufficient(
        this.clusterId || "",
        MIN_CLUSTER_VERSION_FOR_WORKLOAD_ACCESS_MULTI_USERS,
      );
    },
  },
  methods: {
    updateIsCustomUrl(val: boolean): void {
      this.updateTool({ ...this.tool, isCustomUrl: val, externalUrl: null, nodePort: undefined });
    },
    updateIsCustomPort(val: boolean): void {
      this.updateTool({ ...this.tool, isCustomPort: val, nodePort: null, externalUrl: undefined });
    },
    updateTool(updatedTool: IUIConnection): void {
      this.$emit("update-tool", updatedTool);
    },
    nameNotEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.NAME_NOT_EMPTY;
    },
    urlNotEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.URL_NOT_EMPTY;
    },
    isUrl(val: string): boolean | string {
      if (!val) return true;
      return isValidUrl(val) || errorMessages.INVALID_URL;
    },
    validPort(val: number): boolean | string {
      return !!val || "Enter port";
    },
    validPortRange(val: number): boolean | string {
      if (!val) return true;
      return (val >= 30000 && val <= 32767) || "Enter a port between 30000 and 32767";
    },
    updateConnectionType(connectionType: ISelectOption): void {
      const updatedTool = { ...this.tool };
      switch (connectionType.value) {
        case InternalConnectionType.ExternalUrl:
          updatedTool.isCustomUrl = false;
          updatedTool.isCustomPort = undefined;
          break;
        case InternalConnectionType.NodePort:
          updatedTool.isCustomPort = false;
          updatedTool.isCustomUrl = undefined;
          updatedTool.authorizedGroups = undefined;
          updatedTool.authorizedUsers = undefined;
          break;
        default:
          updatedTool.isCustomPort = undefined;
          updatedTool.isCustomUrl = undefined;
          updatedTool.authorizedGroups = undefined;
          updatedTool.authorizedUsers = undefined;
      }
      updatedTool.connectionType = connectionType.value as InternalConnectionType;

      if (this.tool.toolType !== ToolType.Custom) {
        updatedTool.name = this.getToolName({ connectionTypeForName: connectionType.label });
      }
      this.updateTool(updatedTool);
    },
    updateToolType(toolType: ISelectOption): void {
      const toolTypePortMap: Record<string, number> = {
        tensorboard: 6006,
        "jupyter-notebook": 8888,
        "visual-studio-code": 8080,
        mlflow: 5000,
        rstudio: 8787,
        custom: 8080,
        matlab: 8888,
        "chatbot-ui": 3000,
      };

      const updatedTool = {
        ...this.tool,
        containerPort: toolTypePortMap[toolType.value as ToolType] || 0,
        toolType: toolType.value as ToolType,
      };

      const shouldInitConnectionType = this.availableConnectionTypeOptions.find(
        (option) => option.disable && option.value === this.tool.connectionType,
      );
      if (shouldInitConnectionType) {
        // When changing the toolType, setting the connectionType to the first one available for this toolType if the current one isn't.
        updatedTool.connectionType = this.availableConnectionTypeOptions.filter((o) => !o.disable)[0]
          .value as InternalConnectionType;
      }

      updatedTool.name = this.getToolName({ toolTypeForName: toolType.label });
      this.updateTool(updatedTool);
    },
    getToolName(opt?: { toolTypeForName?: string; connectionTypeForName?: string }): string {
      const { toolType, connectionType } = this.tool;
      if (toolType === ToolType.Custom) {
        return "";
      } else {
        let toolName = opt?.toolTypeForName || this.defaultToolTypeAndConnectionForName.toolType;
        if (
          toolType !== ToolType.Wandb &&
          toolType !== ToolType.Comet &&
          connectionType !== InternalConnectionType.ExternalUrl
        ) {
          toolName += ` - ${opt?.connectionTypeForName || this.defaultToolTypeAndConnectionForName.connectionType}`;
        }
        return toolName;
      }
    },
    onToolAccessChanged(toolAccess: IConnectionAccess): void {
      this.updateTool({
        ...this.tool,
        authorizedUsers: toolAccess.authorizedUsers,
        authorizedGroups: toolAccess.authorizedGroups,
      });
    },
    updateToolName(name: string): void {
      this.updateTool({ ...this.tool, name });
    },
    updateExternalUrl(url: string): void {
      this.updateTool({ ...this.tool, externalUrl: url });
    },
  },
});
</script>

<style lang="scss" scoped>
.environment-tool-box {
  position: relative;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $black-54;
    font-size: 12px;
  }

  .gap {
    gap: 35px;
  }

  .bottom-section,
  .top-section {
    border: 1px solid $black-12;
  }

  .bottom-section {
    background-color: $body-background-color;
    border-top: none;
  }
}
</style>
