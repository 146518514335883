<template>
  <runai-expansion-item class="notifications-section settings-container" label="Notifications" :subheader="subheader">
    <email-notifications
      :setting="emailSettings"
      class="q-pb-lg"
      v-if="shouldShowEmailSection"
      @deleted="deleteEmailSettings"
      @updated="updateEmailSettings"
    />
    <template v-if="shouldShowEnableNotificationToggle">
      <div class="q-pb-lg">
        <toggle-setting
          :setting="emailNotificationsEnabledSettings"
          emit-only
          @update-setting="enableNotificationsForTenant"
        />
      </div>
      <q-separator class="q-mb-md" />
    </template>
    <system-notifications :setting="adminMessageSetting" />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { DEFAULT_EMPTY_EMAIL_SETTINGS, EmailErrorMessages, SettingKeys } from "@/models/setting.model";
import type { IAdminMessageSetting, ISettingEmailServer } from "@/models/setting.model";
import { useSettingStore } from "@/stores/setting.store";
import type { Setting } from "@/swagger-models/backend-client";
import SystemNotifications from "@/components/settings/sections/notifications/system-notifications/system-notifications.vue";
import EmailNotifications from "@/components/settings/sections/notifications/email-notifications/email-notifications.vue";
import { notificationService } from "@/services/control-plane/notifications.service/notification.service";
import { settingsUtil } from "@/utils/settings.util";
import { useAppStore } from "@/stores/app.store";
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";
import { alertUtil } from "@/utils/alert.util";
import { useAuthStore } from "@/stores/auth.store";
import { HttpErrorResponse, NotFoundError } from "@/models/http-response.model";

export default defineComponent({
  name: "notifications-section",
  components: { ToggleSetting, EmailNotifications, SystemNotifications, RunaiExpansionItem },
  data() {
    return {
      settingStore: useSettingStore(),
      appStore: useAppStore(),
      authStore: useAuthStore(),
      emailSettings: undefined as ISettingEmailServer | undefined,
      emailSettingLoaded: false,
      emailNotificationsEnabledSettings: {} as Setting,
    };
  },
  created: async function () {
    if (this.appStore.isMultiTenant) {
      this.emailNotificationsEnabledSettings = await this.notificationsEnableSettings();
    } else {
      this.emailSettings = await this.emailServerSetting();
    }
    this.emailSettingLoaded = true;
  },
  computed: {
    adminMessageSetting(): IAdminMessageSetting | undefined {
      return this.settingStore.settingList.find((setting: Setting) => setting.key === SettingKeys.AdminMessage) as
        | IAdminMessageSetting
        | undefined;
    },
    shouldShowEmailSection(): boolean {
      return this.emailSettings != undefined && this.emailSettingLoaded && !this.appStore.isMultiTenant;
    },
    shouldShowEnableNotificationToggle(): boolean {
      return this.emailNotificationsEnabledSettings?.value != undefined && this.appStore.isMultiTenant;
    },
    subheader(): string {
      const emailTitle =
        this.emailNotificationsEnabledSettings?.value || this.emailSettings?.smtpHost ? "Email notifications" : "";
      const systemTitle =
        this.adminMessageSetting != undefined && this.adminMessageSetting.value?.msg ? "System notification" : "";

      return emailTitle && systemTitle
        ? `${systemTitle} / ${emailTitle}`
        : emailTitle || systemTitle
        ? emailTitle || systemTitle
        : "None";
    },
  },
  methods: {
    async emailServerSetting(): Promise<ISettingEmailServer | undefined> {
      try {
        const notificationChannel = await notificationService.getSmtpConfig();
        if (notificationChannel?.config) {
          return settingsUtil.smtpConfigToEmailSettings(notificationChannel.config);
        }
        return DEFAULT_EMPTY_EMAIL_SETTINGS;
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse && error.statusCode === NotFoundError.statusCode) {
          return DEFAULT_EMPTY_EMAIL_SETTINGS;
        }
        console.warn("SMTP configuration retrieval failed. Notification service may be down.");
        return undefined;
      }
    },
    async notificationsEnableSettings(): Promise<Setting> {
      let isEmailNotificationsEnable: boolean;
      try {
        isEmailNotificationsEnable = await notificationService.isSmtpEnabledForTenant();
      } catch {
        console.warn("Failed to get smtp enabled setting");
        isEmailNotificationsEnable = false;
      }
      return {
        key: SettingKeys.SmtpEnabled,
        value: String(isEmailNotificationsEnable),
        label: "Enable email notifications",
        description: "Enable users to receive email notifications and set their own preferences via the user settings.",
      };
    },
    async deleteEmailSettings(): Promise<void> {
      this.emailSettings = DEFAULT_EMPTY_EMAIL_SETTINGS;
      await this.enableNotificationsForTenant(false);
    },
    async updateEmailSettings(emailServerSettings: ISettingEmailServer): Promise<void> {
      this.emailSettings = emailServerSettings;
      await this.enableNotificationsForTenant(true);
    },
    async enableNotificationsForTenant(enable: boolean): Promise<void> {
      try {
        await notificationService.enableEmailNotificationsForTenant(enable);
      } catch (error: unknown) {
        this.emailNotificationsEnabledSettings.value = enable ? "" : "true";
        this.$q.notify(
          alertUtil.getError(
            enable
              ? EmailErrorMessages.EMAIL_NOTIFICATION_ENABLE_ERROR
              : EmailErrorMessages.EMAIL_NOTIFICATION_DISABLE_ERROR,
          ),
        );
        return;
      }
      this.emailNotificationsEnabledSettings.value = enable ? "true" : "";
      return Promise.resolve();
    },
  },
});
</script>
