<template>
  <q-page>
    <dashboard-page-filter
      :filter-options="filterOptions"
      :async-filter-options-promise-map="asyncFilterOptionsPromiseMap"
      @filter-changed="onFilterChanged"
      :filter-by="filterBy"
    />
    <widget-layout
      :widgets-layout="widgetsLayout"
      :cluster-id="clusterId"
      :node-pool-name="nodePoolName"
      :filter-by-dates="filterByDates"
      :filter-by="filterBy"
      @date-changed="onDateChanged"
    />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
//cmps
import { DashboardPageFilter } from "@/components/dashboard-v2/dashboard-page-filter/";
import { WidgetLayout } from "@/components/dashboard-v2/widgets/common/widget-layout/";
//model
import { type IFilterBy, type IFilterModel, type IFilterOption } from "@/models/filter.model";
import type { ISelectOption } from "@/models/global.model";
import { EWidgetComponent } from "@/models/chart.model";
import { PREDEFINED_DATES } from "@/models/date.model";

//store
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useClusterStore } from "@/stores/cluster.store";

// utils
import { dateUtil, type IRangeDates } from "@/utils/date.util";
import { storageUtil } from "@/utils/storage.util";
//constants
import { OVERVIEW_DATE_FILTERS, OVERVIEW_FILTERS } from "@/common/storage.constant";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { type Nodepool, NodepoolSortFilterFields } from "@/swagger-models/cluster-service-client";

export default defineComponent({
  name: "overview-index",
  components: { WidgetLayout, DashboardPageFilter },
  data() {
    return {
      settingsStore: useSettingStore(),
      clusterStore: useClusterStore(),
      hideNodePoolsFilter: false as boolean,
      asyncFilterOptionsPromiseMap: {
        nodepool: this.getFilteredNodePools,
      } as unknown as Record<string, (searchQuery: string) => Promise<Array<ISelectOption>>>,
      nodepools: [] as Nodepool[],
      filterByDates: {
        dateStart: dateUtil.dateSevenDaysAgo(),
        dateEnd: new Date() as Date,
        label: PREDEFINED_DATES.LAST_7_DAYS,
      } as IRangeDates,
      filterBy: {} as IFilterBy,
    };
  },
  created() {
    this.loadFilters();
    useAppStore().setPageLoading(false);
  },
  computed: {
    widgetsLayout(): EWidgetComponent[][] {
      return [
        [
          EWidgetComponent.ReadyNodesWidget,
          EWidgetComponent.ReadyGpuWidget,
          EWidgetComponent.AllocatedGpuWidget,
          EWidgetComponent.IdleAllocatedGpuWidget,
          EWidgetComponent.RunningWorkloads,
          EWidgetComponent.PendingWorkloads,
        ],
        [EWidgetComponent.WorkloadByTypeWidget, EWidgetComponent.WorkloadStatus],
        [EWidgetComponent.GpuAllocationRatioWidget, EWidgetComponent.NodePoolFreeResourcesWidget],
        [EWidgetComponent.OverviewTimeRangeWidgets],
        [EWidgetComponent.IdleGpuWorkloadWidget],
      ];
    },
    filterOptions(): IFilterOption[] {
      return [
        {
          field: "nodepool",
          name: "Node pool",
          label: "Node pool",
        },
      ];
    },
    isDepartmentEnabled(): boolean {
      return this.settingsStore.isDepartmentEnabled;
    },
    nodePoolName(): string | undefined {
      return this.filterBy?.columnFilters?.find((filter: IFilterModel) => filter.field === "nodepool")?.term;
    },
    clusterId(): string {
      return this.clusterStore.selectedClusterId;
    },
  },
  methods: {
    loadFilters(): void {
      this.filterBy = storageUtil.get<IFilterBy | null>(OVERVIEW_FILTERS) ?? {
        clusterUuid: this.clusterId,
        columnFilters: [],
      };

      this.loadDateFilters();
    },
    loadDateFilters(): void {
      const dateFilters = storageUtil.get<{ startDate: Date; endDate: Date; label: string } | null>(
        OVERVIEW_DATE_FILTERS,
      );

      if (dateFilters !== null) {
        this.filterByDates.dateStart = new Date(dateFilters?.startDate);
        this.filterByDates.dateEnd = new Date(dateFilters?.endDate);
        this.filterByDates.label = dateFilters?.label;
      }
    },
    onFilterChanged(filterBy: IFilterBy): void {
      storageUtil.save(OVERVIEW_FILTERS, filterBy);
      this.filterBy = { ...filterBy };
    },
    onDateChanged(data: { startDate: Date; endDate: Date; label: string }): void {
      storageUtil.save(OVERVIEW_DATE_FILTERS, data);

      this.filterByDates = {
        dateStart: data.startDate,
        dateEnd: data.endDate,
        label: data.label,
      };
    },
    async getFilteredNodePools(searchQuery: string): Promise<string[]> {
      if (this.nodepools.length === 0) {
        await this.fetchNodePools();
      }

      const filteredOptions = this.nodepools
        .map((nodePool: Nodepool) => nodePool.name)
        .filter((name: string) => name.includes(searchQuery));

      return filteredOptions;
    },
    async fetchNodePools(): Promise<void> {
      try {
        this.nodepools = await clusterService.getNodepools([
          filterUtil.getEqualsFilterString(NodepoolSortFilterFields.ClusterId, this.clusterId),
        ]);
      } catch (error) {
        console.error("Error fetching node pools:", error);
        this.nodepools = [];
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
