export const uniqueNotificationRunaiId = "runai-email";

export interface CategorizedNotificationSettings {
  [category: string]: NotificationSettings[];
}

export interface NotificationSettings {
  type: string;
  enabled: boolean;
  category: string;
  description?: string;
}
