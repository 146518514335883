<template>
  <runai-expansion-item v-bind="$props" should-validate>
    <template #subheader>
      <slot name="subheader" />
    </template>
    <template #content-header>
      <section class="text-black-54 q-px-md q-py-sm row justify-between items-center" aid="header-content">
        <q-btn aid="load-existing-templates" flat padding="9px" @click="$emit('load-section-templates')">
          <q-icon name="fa-solid fa-arrow-down-to-square" size="18px" />
          <q-tooltip>Load from existing setup</q-tooltip>
        </q-btn>
        <div v-if="selectedName">
          <q-btn flat class="q-mr-sm" aid="reset-btn" no-caps @click="$emit('reset')">
            <q-icon name="fa-solid fa-rotate-left" size="18px" class="q-mr-sm" />
            <span class="q-mr-xs">Reset to</span>
            <q-item-label aid="selected-name" lines="1" style="max-width: 40ch">{{ selectedName }}</q-item-label>
          </q-btn>
          <q-btn aid="clear-btn" flat padding="9px" @click="$emit('clear')">
            <q-icon name="fa-solid fa-xmark" size="18px" />
            <q-tooltip>Clear setup</q-tooltip>
          </q-btn>
        </div>
      </section>
    </template>
    <slot />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";

export default defineComponent({
  name: "hybrid-section-wrapper",
  components: {
    RunaiExpansionItem,
  },
  emits: ["load-section-templates", "reset", "clear"],
  props: {
    selectedName: {
      type: String as PropType<string>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    subheader: {
      type: String as PropType<string>,
      required: false,
    },
    defaultOpened: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hideExpendIcon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    showSubHeader: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableClosing: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    disableOpening: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    sectionInvalid: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
</script>
