/*
 *Page feature intro
 */

import { EBadgeLabel } from "@/models/setting.model";

export interface IPageFeatureIntroModalOptions {
  header?: string;
  cancelBtnText?: string;
  confirmBtnText: string;
}

/*
 *App wide feature intro
 */

export interface IAppWideFeatureIntroItem {
  svgName: string;
  title: string;
  description: string;
  objective?: string;
  additionalInfo?: string[];
  stageBadge?: EBadgeLabel;
}

export const featureIntroItems: IAppWideFeatureIntroItem[] = [
  {
    svgName: "2-20-feature-intro-nim",
    title: "Deploy and autoscale Nvidia NIM efficiently",
    description: "Simplify deployments with NVIDIA NIM",
    objective:
      "Ensure LLMs are automatically scaled and optimized for specific hardware, configurations, and requirements.",
    additionalInfo: ["From cluster v2.20 onward"],
  },
  {
    svgName: "2-20-feature-intro-user-application",
    title: "User applications",
    description: "Create your applications for API integration",
    objective: `Easily create and manage applications for API authentication directly from your "User settings" page. Automate workload submission, resource allocation, and more, using scripts and integrations with the Run:ai API.`,
    additionalInfo: ["From cluster v2.20 onward"],
  },
  {
    svgName: "2-20-feature-intro-multi-gpu-fractions",
    title: "Multi-GPU fractions",
    description: "Optimize resource consumption with precise allocation",
    objective:
      "Submit workloads using multiple partial GPU devices, allowing multi-GPU workloads to share those partial GPUs in parallel.",
    additionalInfo: ["From cluster v2.20 onward"],
  },
  {
    svgName: "2-20-feature-intro-multi-gpu-swap",
    title: "Multi-GPU SWAP",
    description: "Extract more from your GPUs with multi-GPU SWAP",
    objective:
      "Use SWAP with multi-GPU workloads, and share multi-GPUs between large models, notebooks, or training workloads.",
    additionalInfo: ["From cluster v2.20 onward"],
    stageBadge: EBadgeLabel.Beta,
  },
];

export const CURRENT_INTRO_VERSION = "v2.20";
