export const prodDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?run\.ai$/;
export const stagingDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?staging\.run\.ai$/;
export const testDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)?test\.run\.ai$/;
// matches any nv subdomain - example: https://blabla.nv.run.ai
export const nvDomains = /^(https:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]*nv[a-zA-Z0-9-]*\.run\.ai$/;

export enum EnvType {
  TEST,
  STAGING,
  PROD,
  DEV,
}

export function getEnvType(hostName: string = window.location.hostname): EnvType {
  const isProd = prodDomains.test(hostName) || nvDomains.test(hostName);
  const isStaging = stagingDomains.test(hostName);
  const isTest = testDomains.test(hostName);

  if (isTest) {
    return EnvType.TEST;
  } else if (isStaging) {
    return EnvType.STAGING;
  } else if (isProd) {
    return EnvType.PROD;
  }
  return EnvType.DEV;
}
