import type { IStatusColOptions } from "@/models/table.model";
import type { EQuotaEntity } from "@/models/resource.model";
import type { Scope } from "@/swagger-models/assets-service-client";

import type { ISelectOption } from "@/models/global.model";
import type { Project, QuotaStatusNodePool, Resources } from "@/swagger-models/org-unit-service-client";
import { ProjectPhase } from "@/swagger-models/org-unit-service-client";
import type { IFilterBy } from "@/models/filter.model";

export enum EWorkloadNodeAffinity {
  Train = "train",
  Interactive = "interactive",
}

export enum EProjectModalEntity {
  Department = "Department",
}

export enum EProjectConditionDeletionReason {
  DataVolumesDeletionHandlerFailed = "DataVolumesDeletionHandlerFailed",
  WorkloadsDeletionHandlerFailed = "WorkloadsDeletionHandlerFailed",
  SecretsDeletionHandlerFailed = "SecretsDeletionHandlerFailed",
  PvcsDeletionHandlerFailed = "PvcsDeletionHandlerFailed",
}

export const ProjectPhaseMap: Record<ProjectPhase, IStatusColOptions> = {
  [ProjectPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [ProjectPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [ProjectPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    tooltipText: "The project’s subordinates workloads or assets are being deleted",
  },
  [ProjectPhase.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
  },
  [ProjectPhase.NotReady]: {
    status: "Not Ready",
    color: "negative",
    displayAnimation: false,
  },
  [ProjectPhase.Initializing]: {
    status: "Initializing...",
    displayAnimation: true,
  },
  [ProjectPhase.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
  },
  [ProjectPhase.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
  },
};

export interface INodeAffinityOption {
  affinityType: string;
  selectedTypes: string[];
}

export interface INodeAffinitySelectOption {
  label: string;
  value: string;
  loading: boolean;
  selectedTypes: ISelectOption[];
}

export interface IProjectsModalOptions {
  parentId: string;
  entityName: string;
  entityType: EProjectModalEntity;
  clusterId: string;
}

export interface IProjectTable extends Project {
  rolesNames?: string[];
  averageGpuAllocation?: number;
  averageGpuUtilization?: number;
}

export interface IProjectResources {
  id: number;
  tenantId: number;
  clusterUuid: string;
  name: string;
  deservedGpus: number;
  allocatedGpus: number;
  createdAt?: string;
}

export interface INodePoolsResourcesRow extends Resources {
  priority?: number | string;
  allocatedGpus?: number;
  allocatedCpu?: number;
  allocatedMemory?: number;
  overQuotaWeightLabel?: string;
}
export interface IResourceQuota {
  deserved?: number | null;
  maxAllowed?: number | null;
  overQuotaWeight?: number | null;
}

export interface IProjectSectionModel {
  scope: Scope;
  projectId: number | null;
}

export interface INodePoolModalOptions {
  header: string;
  entityType: EQuotaEntity;
  nodePoolQuotaStatuses: QuotaStatusNodePool[];
  resources: Resources[];
  defaultNodePools?: string[];
  loading?: boolean;
  showRedirectBtn?: boolean;
}

export enum EProjectColumnName {
  ProjectName = "name",
  DepartmentName = "department-name",
  Status = "status",
  NodePools = "node-pools",
  AccessRules = "accessRules",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  GpuQuota = "gpu-quota",
  CpuQuota = "cpu-quota",
  CpuMemoryQuota = "cpu-memory-quota",
  NodeAffinityTrain = "node-affinity-train",
  NodeAffinityInteractive = "node-affinity-interactive",
  TrainingJobMaxIdleDurationSecs = "training-job-max-idle-duration-secs",
  InteractivePreemptibleJobMaxIdleDurationSecs = "interactive-preemptible-job-max-idle-duration-secs",
  InteractiveJobMaxIdleDurationSecs = "interactive-job-max-idle-duration-secs",
  InteractiveJobTimeLimitSecs = "interactive-job-time-limit-secs",
  TrainingJobTimeLimitSecs = "training-job-time-limit-secs",
  CreatedAt = "created-at",
  Workloads = "workloads",
}

export interface IProjectTableFilterBy extends IFilterBy {
  avgGpuUtilizationTimeframe: string;
  avgGpuAllocationTimeframe: string;
}
