<template>
  <section class="runai-brand-logo">
    <img :height="height" v-if="brandingLogo" :src="brandingLogo" alt="logo" />
    <runai-svg-icon v-else :name="runaiLogo" :size="`${height}px`" />
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    height: {
      type: String as PropType<string>,
      default: "34",
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  computed: {
    brandingLogo(): string | undefined {
      return this.dark ? this.appStore.branding?.logo.dark : this.appStore.branding?.logo.light;
    },
    runaiLogo(): string {
      return this.dark ? "logo-blue" : "logo-white";
    },
  },
});
</script>
<style lang="scss" scoped></style>
