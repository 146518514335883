<template>
  <hybrid-section-wrapper
    class="hybrid-compute-resource-section"
    label="Compute resources"
    :selected-name="selectedName"
    :disable="sectionDisabled"
    @load-section-templates="$emit('load-section-templates')"
    @clear="$emit('clear')"
    @reset="$emit('reset')"
    default-opened
  >
    <runai-section title="Set the resource types for a single pod" tooltip-text="A pod is scheduled to a single node">
      <resource-boxes-section
        :support-multi-fraction-gpu="supportMultiFractionGpu"
        :resources-model="resourcesModel"
        :policy-rules="policy?.rules?.compute"
        @update-resources-model="updateResourcesModel"
      />
    </runai-section>

    <runai-folding-sub-section label="Extended resources">
      <large-shm-toggle
        :model-value="workloadSpec.compute?.largeShmRequest || false"
        @update:model-value="updateLargeShmRequest"
        :policy-rules="policy?.rules?.compute?.largeShmRequest"
      />

      <extended-resources
        :extended-resources="workloadSpec.compute?.extendedResources || undefined"
        @update="updateExtendedResources"
        :policy-defaults="policy?.defaults?.compute?.extendedResources"
        :policy-rules="policy?.rules?.compute?.extendedResources"
      />
    </runai-folding-sub-section>
    <runai-folding-sub-section label="Nodes">
      <node-pools-resource-section
        v-if="nodePoolsResources?.length"
        :node-pools-list-origin="ENodePoolsListOrigin.Project"
        :selected-node-pools="defaultNodePools"
        :all-node-pools="allNodePoolsNames"
        :policy="nodePoolsPolicy"
        :disabled="sectionDisabled"
        @list-changed="updateNodePools"
      />

      <node-affinity
        class="q-ml-none"
        :input-type="nodeAffinities.length ? 'select' : 'input'"
        :node-type="workloadSpec.nodeType || null"
        :disabled="sectionDisabled"
        @node-type-changed="updateNodeType"
        :nodes-affinity="nodeAffinities"
        :policy-rules="policy?.rules?.nodeType || undefined"
      />

      <tolerations-section
        v-if="isTolerationsEnabled"
        class="q-mt-md"
        :tolerations="workloadSpec.tolerations || undefined"
        @update-tolerations="updateTolerations"
        :read-only="sectionDisabled"
        :policy-rules="policy?.rules?.tolerations"
        :policy-defaults="policy?.defaults?.tolerations"
        :allow-any-effect="isAnyTolerationAllowed"
      />
    </runai-folding-sub-section>
  </hybrid-section-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { HybridSectionWrapper } from "../hybrid-section-wrapper";
import { RunaiFoldingSubSection } from "@/components/common/runai-folding-sub-section";
import { RunaiSection } from "@/components/common/runai-section";
import { ResourceBoxesSection } from "@/components/compute-resource/compute-resource-resources-section/resource-boxes-section";
import { LargeShmToggle } from "@/components/compute-resource/large-shm-toggle";
import { ExtendedResources } from "@/components/compute-resource/compute-resource-resources-section/extended-resources";
import { TolerationsSection } from "@/components/section/compute-resource-section/tolerations-section";
import { NodeAffinity } from "@/components/section/compute-resource-section/node-affinity";
import type { IComputeSectionNodePoolsPolicy } from "@/components/section/compute-resource-section/node-pools-resource-section";
import {
  ENodePoolsListOrigin,
  NodePoolsResourceSection,
} from "@/components/section/compute-resource-section/node-pools-resource-section";
// Models
import type { IUIWorkspaceSpec } from "@/models/workload.model";
import type { IResourcesSectionModel } from "@/models/compute-resource.model";
import type { UnifiedPolicyInfoPerReplicaType } from "@/swagger-models/workloads-client";
import type { ComputeFields, ExtendedResource, StringOption, Toleration } from "@/swagger-models/assets-service-client";
// Stores
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  name: "hybrid-compute-resource-section",
  components: {
    NodePoolsResourceSection,
    HybridSectionWrapper,
    RunaiFoldingSubSection,
    RunaiSection,
    ResourceBoxesSection,
    LargeShmToggle,
    ExtendedResources,
    TolerationsSection,
    NodeAffinity,
  },
  emits: ["load-section-templates", "reset", "clear", "update"],
  props: {
    selectedName: {
      type: String as PropType<string>,
      required: false,
    },
    workloadSpec: {
      type: Object as PropType<IUIWorkspaceSpec>,
      required: true,
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    policy: {
      type: [Object, null] as PropType<UnifiedPolicyInfoPerReplicaType | null>,
      required: false,
    },
    nodePoolsResources: {
      type: Array as PropType<StringOption[]>,
      required: false,
      default: () => [],
    },
    supportMultiFractionGpu: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      ENodePoolsListOrigin,
    };
  },
  computed: {
    nodeAffinities(): string[] {
      return this.policy?.rules?.nodeType?.options?.map((nt) => nt.value) || [];
    },
    nodePoolsPolicy(): IComputeSectionNodePoolsPolicy | null {
      return {
        rules: this.policy?.rules?.nodePools || undefined,
        defaults: this.policy?.defaults?.nodePools || undefined,
      };
    },
    isTolerationsEnabled(): boolean {
      return this.settingStore.isWorkloadTolerationsEnabled;
    },
    isAnyTolerationAllowed(): boolean {
      return true;
      // TODO: need to think where to put this logic.
      // return this.clusterStore.isClusterVersionSufficient(
      //     this.clusterUid,
      //     MIN_CLUSTER_VERSION_FOR_ANY_EFFECT,
      //   ),
    },
    resourcesModel(): IResourcesSectionModel {
      return {
        gpuDevicesRequest: this.workloadSpec.compute?.gpuDevicesRequest || 0,
        gpuRequestType: this.workloadSpec.compute?.gpuRequestType,
        gpuPortionRequest: this.workloadSpec.compute?.gpuPortionRequest,
        gpuPortionLimit: this.workloadSpec.compute?.gpuPortionLimit,
        gpuMemoryRequest: this.workloadSpec.compute?.gpuMemoryRequest,
        gpuMemoryLimit: this.workloadSpec.compute?.gpuMemoryLimit,
        migProfile: this.workloadSpec.compute?.migProfile,
        cpuCore: {
          request: this.workloadSpec.compute?.cpuCoreRequest || 0,
          limit: this.workloadSpec.compute?.cpuCoreLimit || null,
        },
        cpuMemory: {
          request: this.workloadSpec.compute?.cpuMemoryRequest || "0M",
          limit: this.workloadSpec.compute?.cpuMemoryLimit || null,
        },
      };
    },
    defaultNodePools(): string[] {
      return this.workloadSpec?.nodePools || [];
    },
    allNodePoolsNames(): string[] {
      return this.nodePoolsResources.map((nodePoolResource: StringOption) => nodePoolResource.value || "");
    },
  },
  methods: {
    updateTolerations(tolerations: Toleration[]) {
      this.$emit("update", { ...this.workloadSpec, tolerations });
    },
    updateLargeShmRequest(largeShmRequest: boolean) {
      this.$emit("update", { ...this.workloadSpec, compute: { ...this.workloadSpec.compute, largeShmRequest } });
    },
    updateResourcesModel(resourcesModel: IResourcesSectionModel) {
      const computeFields: ComputeFields = {
        gpuDevicesRequest: resourcesModel.gpuDevicesRequest,
        gpuRequestType: resourcesModel.gpuRequestType,
        gpuPortionRequest: resourcesModel.gpuPortionRequest,
        gpuPortionLimit: resourcesModel.gpuPortionLimit,
        gpuMemoryRequest: resourcesModel.gpuMemoryRequest,
        gpuMemoryLimit: resourcesModel.gpuMemoryLimit,
        migProfile: resourcesModel.migProfile,
        cpuCoreRequest: resourcesModel.cpuCore.request,
        cpuCoreLimit: resourcesModel.cpuCore.limit,
        cpuMemoryRequest: resourcesModel.cpuMemory.request,
        cpuMemoryLimit: resourcesModel.cpuMemory.limit,
      };

      this.$emit("update", {
        ...this.workloadSpec,
        compute: {
          ...this.workloadSpec.compute,
          ...computeFields,
        },
      });
    },
    updateExtendedResources(extendedResources: ExtendedResource[]) {
      this.$emit("update", { ...this.workloadSpec, compute: { ...this.workloadSpec.compute, extendedResources } });
    },
    updateNodeType(nodeType: string) {
      this.$emit("update", { ...this.workloadSpec, nodeType });
    },
    updateNodePools(nodePools: Array<string>): void {
      this.$emit("update", { ...this.workloadSpec, nodePools });
    },
  },
});
</script>
