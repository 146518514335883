// models
import { MappersKeys, type IIdpMapperItem, type ISettingEmailServer, DEFAULT_SMTP_PORT } from "@/models/setting.model";
import type {
  IdpCreationRequest,
  Mappers,
  OcpCreationData,
  OidcCreationData,
  SamlCreationData,
} from "@/swagger-models/identity-manager-client";
import { IdpTypeEnum } from "@/swagger-models/identity-manager-client";
import type { QNotifyCreateOptions } from "quasar/dist/types";

// utils
import { alertUtil } from "@/utils/alert.util";

// store
import { useAuthStore } from "@/stores/auth.store";
import type { Setting } from "@/swagger-models/backend-client";
import { EmailAuthType, type NotificationChannelForPatchConfig } from "@/swagger-models/notifications-proxy-client";
export const settingsUtil = {
  getIdpMappersItems,
  isIdpMappersEqual,
  getMappersFromIdpMapperItems,
  createIdpCreationRequest,
  getSettingErrorMessage,
  getToggleSectionCollapsedTitle,
  emailSettingsToSmtpConfig,
  smtpConfigToEmailSettings,
};

function getIdpMappersItems(mappers: Mappers): Record<string, IIdpMapperItem> {
  return {
    uid: {
      key: MappersKeys.Uid,
      label: "Linux user ID",
      value: mappers?.uid || "",
    },
    gid: {
      key: MappersKeys.Gid,
      label: "Linux group ID",
      value: mappers?.gid || "",
    },
    groups: {
      key: MappersKeys.Groups,
      label: "User role groups",
      value: mappers?.groups || "",
    },
    supplementaryGroups: {
      key: MappersKeys.SupplementaryGroups,
      label: "SUPPLEMENTARYGROUPS",
      value: mappers?.supplementaryGroups || "",
    },
    email: {
      key: MappersKeys.Email,
      label: "Email",
      value: mappers?.email || "",
    },
  };
}

function isIdpMappersEqual(mappers: Mappers, items: Record<string, IIdpMapperItem>): boolean {
  return (
    mappers.uid === items[MappersKeys.Uid]?.value &&
    mappers.gid === items[MappersKeys.Gid]?.value &&
    mappers.groups === items[MappersKeys.Groups]?.value &&
    mappers.supplementaryGroups === items[MappersKeys.SupplementaryGroups]?.value &&
    mappers.email === items[MappersKeys.Email]?.value
  );
}

function getMappersFromIdpMapperItems(items: Record<string, IIdpMapperItem>): Mappers {
  return {
    uid: items[MappersKeys.Uid].value,
    gid: items[MappersKeys.Gid].value,
    groups: items[MappersKeys.Groups].value,
    supplementaryGroups: items[MappersKeys.SupplementaryGroups].value,
    email: items[MappersKeys.Email].value,
  };
}

function createIdpCreationRequest(
  alias: string,
  idpNewFields: OidcCreationData | SamlCreationData | OcpCreationData,
  mappers: Mappers,
): IdpCreationRequest | null {
  switch (alias) {
    case IdpTypeEnum.Oidc:
      return {
        type: alias,
        oidcData: idpNewFields as OidcCreationData,
        mappers: mappers,
      };

    case IdpTypeEnum.Saml:
      return {
        type: alias,
        samlData: idpNewFields as SamlCreationData,
        mappers: mappers,
      };

    case IdpTypeEnum.OpenshiftV4:
      return {
        type: alias,
        ocpData: idpNewFields as OcpCreationData,
        mappers: mappers,
      };

    default:
      return null;
  }
}

function getSettingErrorMessage(): QNotifyCreateOptions {
  const email = useAuthStore().currentUser.username;
  const message = `Settings couldn't be saved. Try again. If the issue persists, <a href="https://runai.my.salesforce-sites.com/casesupport/CreateCaseForm?email=${email}" target="_blank" style="color: white; text-decoration: underline">contact Run:ai support.</a>`;
  return alertUtil.getError(message, { html: true });
}

function getToggleSectionCollapsedTitle(settings: object): string {
  let enabled = 0;
  let disabled = 0;
  Object.values(settings)
    .filter((value) => typeof value?.value === "boolean")
    .forEach((value: Setting) => {
      if (!value) return;
      if (value.value) {
        enabled++;
      } else {
        disabled++;
      }
    });
  return `Enabled (${enabled}) / Disabled (${disabled})`;
}

function emailSettingsToSmtpConfig(emailSettings: ISettingEmailServer): NotificationChannelForPatchConfig {
  return {
    username: emailSettings.username,
    password: emailSettings.password,
    smtpHost: emailSettings.smtpHost,
    from: emailSettings.fromEmail,
    smtpPort: emailSettings.smtpPort,
    authType: emailSettings.authType,
    smtpTlsEnabled: true,
    recipients: [],
    fromDisplayName: emailSettings.fromName,
    directNotifications: true,
    subject: "{{ .title }}",
  };
}

function smtpConfigToEmailSettings(
  notificationChannelForPatchConfig: NotificationChannelForPatchConfig,
): ISettingEmailServer {
  return {
    smtpHost: notificationChannelForPatchConfig.smtpHost || "",
    smtpPort: notificationChannelForPatchConfig.smtpPort || DEFAULT_SMTP_PORT,
    username: notificationChannelForPatchConfig.username || "",
    password: notificationChannelForPatchConfig.password || "",
    fromEmail: notificationChannelForPatchConfig.from || "",
    fromName: notificationChannelForPatchConfig.fromDisplayName || "",
    authType: notificationChannelForPatchConfig.authType || EmailAuthType.Plain,
  };
}
