<template>
  <q-select
    class="runai-select"
    :options="displayedOptions"
    :model-value="modelValue"
    @update:model-value="updateModelValue"
    :emit-value="emitValue"
    :rules="selectRules"
    :outlined="outlined"
    no-error-icon
    :hide-bottom-space="hideBottomSpace"
    :disable="disabled || policyRules?.canEdit === false"
    :label="label"
  >
    <template v-slot:selected>
      <div class="row items-center" v-if="selectedOption">
        <runai-svg-icon
          v-if="typeof selectedOption === 'object' && selectedOption.icon"
          :name="selectedOption.icon"
          :key="selectedOption.icon"
          size="20px"
          class="q-mr-sm"
        />
        <span>{{ typeof selectedOption === "object" ? selectedOption.label : selectedOption }}</span>
      </div>
      <template v-else-if="placeholder">
        <span class="select-placeholder">{{ placeholder }}</span>
      </template>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" class="row items-center justify-between runai-select-item">
        <div class="row items-center">
          <runai-svg-icon v-if="scope.opt.icon" :name="scope.opt.icon" size="25px" class="q-mr-md" />
          <q-item-label>{{ scope.opt.label || scope.opt }}</q-item-label>
        </div>

        <!-- display a tooltip when hovering on the row -->
        <q-tooltip
          v-if="scope.opt.disable && scope.opt.disabledTooltip"
          class="q-pa-sm runai-tooltip"
          max-width="250px"
          anchor="bottom middle"
          self="top middle"
        >
          {{ scope.opt.disabledTooltip }}
        </q-tooltip>

        <!-- display a tooltip when hovering on the icon only -->
        <runai-tooltip
          v-if="scope.opt.toolTip"
          tooltip-position="right"
          :tooltip-text="scope.opt.toolTip"
          class="q-ml-md"
          :width="iconTooltipWidth"
        />
      </q-item>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">{{ noOptionText }}</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
// Models
import type { ISelectOption } from "@/models/global.model";
import type { ValidationRule } from "quasar";
import { policyUtil } from "@/utils/policy.util";
import { isNotEmpty } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import { type IGenericSelectPolicyRules } from "@/models/policy.model";

export default defineComponent({
  name: "runai-select",
  components: { RunaiSvgIcon, RunaiTooltip },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [Object, String, Number, Boolean, null, undefined] as PropType<
        ISelectOption | string | number | boolean | null | undefined
      >,
      required: true,
    },
    options: {
      type: Array as PropType<Array<ISelectOption | string | number | boolean>>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
    noOptionText: {
      type: String as PropType<string>,
      default: "",
    },
    rules: {
      type: Array as PropType<Array<ValidationRule>>,
      default: () => [],
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    emitValue: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    unclearable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideBottomSpace: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    policyRules: {
      type: Object as PropType<IGenericSelectPolicyRules>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    iconTooltipWidth: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    displayedOptions() {
      if (!this.policyRules?.options) return this.options;
      const displayedOptions = this.options.map((option) => {
        if (typeof option !== "object") return { label: option.toString(), value: option };
        return option;
      });
      return policyUtil.getOptionsWithRules(displayedOptions, this.policyRules?.options, this.policyRules.isClosedList);
    },
    selectedOption(): ISelectOption | string | number | boolean | null {
      return (
        this.options.find((option) => {
          const value = typeof option === "object" ? option.value : option;
          if (this.modelValue && typeof this.modelValue === "object") return value === this.modelValue.value;
          return value === this.modelValue;
        }) || null
      );
    },
    selectRules(): ValidationRule[] {
      return this.policyRules?.required
        ? [...this.rules, this.requiredRule, this.isValidSelection]
        : [...this.rules, this.isValidSelection];
    },
  },
  methods: {
    updateModelValue(value: ISelectOption | string | number | boolean | null): void {
      if (this.unclearable && value === null) return;
      this.$emit("update:modelValue", value);
    },
    requiredRule(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.REQUIRED_FIELD;
    },
    isValidSelection(): boolean | string {
      if (!Array.isArray(this.policyRules?.options)) return true;
      const valueToCheck =
        this.modelValue && typeof this.modelValue === "object" ? this.modelValue.value : this.modelValue;
      return (
        this.policyRules.options.map((o) => o.value).includes(valueToCheck as string) ||
        errorMessages.CANT_BE_SELECTED_POLICY
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.select-placeholder {
  opacity: 0.7;
}
</style>
