import type { IStatusColOptions } from "@/models/table.model";
import { NodepoolPhase } from "@/swagger-models/cluster-service-client";

export enum PlacementStrategy {
  Binpack = "binpack",
  Spread = "spread",
}

export enum ChartType {
  line = "line",
  area = "area",
}

export enum ChartDataKeys {
  totalGpus = "totalGpus",
  gpuAllocated = "gpuAllocated",
  gpuNotAllocated = "gpuNotAllocated",
  gpuPerNodeMetricInSlicedRanges = "gpuPerNodeMetricInSlicedRanges",
  gpuMemoryUtilization = "gpuMemoryUtilization",
  gpuUtilization = "gpuUtilization",
  cpuUtilization = "cpuUtilization",
  cpuMemoryUtilization = "cpuMemoryUtilization",
}

export enum EOverProvisioningText {
  AsRequested = "As requested",
  NotEnforced = "Not enforced",
}

export const binPackLabel = "Bin-pack";
export const spreadLabel = "Spread";
export const MIN_OVER_PROVISIONING_RATIO = 1;
export const MAX_OVER_PROVISIONING_RATIO = 5;

export const OVER_PROVISIONING_RATIO: { [index: string]: string } = {
  [MIN_OVER_PROVISIONING_RATIO]: EOverProvisioningText.AsRequested,
  2: "0.5",
  3: "0.33",
  4: "0.25",
  [MAX_OVER_PROVISIONING_RATIO]: "0.2",
};

export const PlacementOptions = {
  gpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each GPU and node to use fewer resources and maximize GPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many GPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
  cpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each CPU and node to use fewer resources and maximize CPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many CPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
};

export const NodePoolStatusMessageMap = new Map([
  [
    "Error Deleting NodePool",
    {
      newMessage: "Node pool cannot be deleted, if the issue persists contact run:ai support",
    },
  ],
  [
    "Scheduler is not ready",
    {
      newMessage: "The Run:ai scheduler is not ready, if the issue persists contact run:ai support",
    },
  ],
  [
    "Nodes unavailable",
    {
      newMessage: "Nodes are unavailable, if the issue persists contact run:ai support",
    },
  ],
]);

export const NodePoolStatusMessagePrefix = [
  {
    originalMessage: "Scheduler is not ready, reason:",
    newMessage: "The Run:ai scheduler is not ready, because of the following:",
  },
  {
    originalMessage: "Unschedulable Nodes:",
    newMessage: "The following nodes within the node pool are not ready:",
  },
  {
    originalMessage: "Nodes Being Drained:",
    newMessage:
      "Seems there are workloads on the nodes under this node pool which are not associated with this node pool. Please delete all these running/failed workloads.\nThe nodes are:",
  },
];

export const chartSettings: INodePoolChartSettings[] = [
  {
    key: ChartDataKeys.gpuPerNodeMetricInSlicedRanges,
    title: "GPU utilization distribution",
    color: ["#9B19F5", "#FFA300", "#E6D800", "#00BFA0"],
    container: "container-gpu-utilization-range",
    chartType: ChartType.area,
    names: createLegendUtilizationArray(0, 100, 25).reverse(),
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuAllocated,
    title: "Node GPU allocation",
    color: ["#9B19F5", "#00BFA0"],
    container: "container-gpu-allocation",
    chartType: ChartType.area,
    names: ["Allocated GPUs", "Unallocated GPUs"],
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuUtilization,
    title: "GPU utilization",
    color: "#9B19F5",
    container: "container-gpu-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuMemoryUtilization,
    title: "GPU memory utilization",
    color: "#9B19F5",
    container: "container-gpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.cpuUtilization,
    title: "CPU utilization",
    color: "#0BB4FF",
    container: "container-cpu-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
  {
    key: ChartDataKeys.cpuMemoryUtilization,
    title: "CPU memory utilization",
    color: "#0BB4FF",
    container: "container-cpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
];

export interface INodePoolChartSettings {
  key: string;
  title: string;
  color: string | string[];
  container: string;
  chartType: ChartType;
  names?: string[];
  totalColor?: string;
  isGpuChart?: boolean;
}

// function to create legend for GPU utilization distribution chart
// example: GPU with 0-25% utilization, GPU with 25-50% utilization, etc.
function createLegendUtilizationArray(min: number, max: number, step: number): string[] {
  return Array.from(
    { length: (max - min) / step },
    (_, i) => `GPU with ${min + i * step}-${min + (i + 1) * step}% utilization`,
  );
}

export interface INodePoolsNameAndId {
  name: string;
  id: string;
}

export const NodePoolPhaseMap: Record<NodepoolPhase, IStatusColOptions> = {
  [NodepoolPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [NodepoolPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [NodepoolPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
  },
  [NodepoolPhase.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
  },
  [NodepoolPhase.Empty]: {
    status: "Empty",
    displayAnimation: false,
  },
  [NodepoolPhase.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
  },
  [NodepoolPhase.Unschedulable]: {
    status: "Unschedulable",
    color: "negative",
    displayAnimation: false,
  },
};

//Node pools v2 chart
export const GPU_ALLOCATION_TIME_RANGE_WIDGET_CHART_ID = "gpu-allocation-time-range-widget-chart-id";
export const GPU_UTILIZATION_DISTRIBUTION_TIME_RANGE_WIDGET_CHART_ID =
  "gpu-utilization-distribution-time-range-widget-chart-id";
export const RESOURCE_UTILIZATION_TIME_RANGE_WIDGET_CHART_ID = "resource-utilization-time-range-widget-chart-id";
