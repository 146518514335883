import { uniqueNotificationRunaiId } from "@/models/user-setting.model";
import { notificationServiceApi } from "@/services/infra/client-apis/notification-service-api/notification-service-api";
import {
  type NotificationChannel,
  type NotificationChannelForPatchConfig,
  type NotificationChannelForValidate,
  NotificationChannelType,
  type NotificationType,
  type Subscription,
} from "@/swagger-models/notifications-proxy-client";
import { httpService } from "@/services/infra/https.service/http.service";

export const notificationService = {
  getSmtpConfig,
  updateSmtpConfig,
  enableEmailNotificationsForTenant,
  isSmtpEnabledForTenant,
  verifySmtpConfig,
  deleteSmtpConfig,
  getUserNotificationTypes,
  getUserSubscriptions,
  updateUserSubscription,
  createUserSubscription,
};

async function getSmtpConfig(): Promise<NotificationChannel> {
  try {
    const smtpConfigResponse = await notificationServiceApi.notificationChannelsApi.getNotificationChannelConfig(
      uniqueNotificationRunaiId,
    );
    return smtpConfigResponse.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateSmtpConfig(config: NotificationChannelForPatchConfig): Promise<void> {
  try {
    const notificationChannel: NotificationChannel = {
      name: uniqueNotificationRunaiId,
      type: NotificationChannelType.Email,
      config,
    };
    await notificationServiceApi.notificationChannelsApi.updateNotificationChannelConfig(
      uniqueNotificationRunaiId,
      notificationChannel,
    );
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function deleteSmtpConfig(): Promise<void> {
  try {
    await notificationServiceApi.notificationChannelsApi.deleteNotificationChannelConfig(uniqueNotificationRunaiId);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function verifySmtpConfig(notificationChannelForValidate: NotificationChannelForValidate): Promise<boolean> {
  try {
    const response = await notificationServiceApi.notificationChannelsApi.validateNotificationChannel(
      notificationChannelForValidate,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function isSmtpEnabledForTenant(): Promise<boolean> {
  try {
    const response = await notificationServiceApi.notificationStateApi.getNotificationState();
    return response.data.enabled || false;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function enableEmailNotificationsForTenant(enabled: boolean): Promise<boolean> {
  try {
    const response = await notificationServiceApi.notificationStateApi.updateNotificationState({ enabled });
    return response.data.enabled || false;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getUserNotificationTypes(): Promise<Array<NotificationType>> {
  try {
    const response = notificationServiceApi.notificationTypesApi.getNotificationTypes();
    return (await response).data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getUserSubscriptions(): Promise<Array<Subscription>> {
  try {
    const response = notificationServiceApi.subscriptionApi.getMySubscriptions();
    return (await response).data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateUserSubscription(subscriptionID: string, subscription: Subscription): Promise<Subscription> {
  try {
    const response = await notificationServiceApi.subscriptionApi.updateSubscription(subscriptionID, subscription);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createUserSubscription(subscription: Subscription): Promise<Subscription> {
  try {
    const response = await notificationServiceApi.subscriptionApi.createSubscription(subscription);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
