<template>
  <section class="row" v-if="!registryId">
    <policy-string-field
      :model-value="image"
      @update:model-value="updateModel($event)"
      label="Image URL"
      :rules="[isEmptyField]"
      no-error-icon
      class="col-8"
      aid="environment-image-input"
      :policy-rules="policyRules"
    />
  </section>
  <section class="row" v-else>
    <div class="col-8">
      <runai-autocomplete-select
        aid="repository-autocomplete"
        no-result-label="No repositories match your filter"
        label="Repository"
        search-hint="Search a repository"
        :model-value="selectedRepository"
        @update:model-value="updateRepository"
        :async-options-promise="getImageRepositories"
        :minimum-characters="1"
        @clear="clearRepository"
        :rules="[isEmptyRepository]"
        :is-error="isRepositoryError"
      />
    </div>
    <div class="col-1 separator"><span>:</span></div>
    <div class="col-3">
      <runai-autocomplete-select
        aid="tag-autocomplete"
        no-result-label="No tags match your filter"
        label="Image tag"
        search-hint="Search a tag"
        :model-value="selectedTag"
        @update:model-value="updateTag"
        :async-options-promise="getImageTags"
        :minimum-characters="0"
        :debounce="0"
        :rules="[isEmptyTag]"
        :is-error="isTagError"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Cmps
import { RunaiAutocompleteSelect } from "@/components/common";
import { PolicyStringField } from "@/components/common/policy-string-field";
// Types
import type { RepositoriesListResponse, TagsListResponse } from "@/models/registry.model";
import type { StringRules } from "@/swagger-models/assets-service-client";
// Services
import { registryService } from "@/services/control-plane/registry.service/registry.service";
// Stores
import { useEnvironmentStore } from "@/stores/environment.store";
export default defineComponent({
  name: "form-image-input",
  components: {
    RunaiAutocompleteSelect,
    PolicyStringField,
  },
  emits: ["update"],
  props: {
    image: {
      type: [String, null] as PropType<string | null>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<StringRules | null>,
      required: false,
    },
  },
  data() {
    return {
      environmentStore: useEnvironmentStore(),
      selectedRepository: null as string | null,
      selectedTag: null as string | null,
      isRepositoryError: false,
      isTagError: false,
    };
  },
  async created() {
    if (this.registryId) {
      if (this.image) {
        await this.copyImageUrlToImageAndTag();
      }
    }
  },
  computed: {
    registryId(): string | null {
      return this.environmentStore.registryId || null;
    },
  },
  methods: {
    updateModel(val: string | number | null) {
      this.$emit("update", val);
    },
    isEmptyField(val: string): string | boolean {
      return !!val || "Enter a URL";
    },
    isEmptyRepository(val: string): string | boolean {
      return !!val || "Select a Repository";
    },
    isEmptyTag(val: string): string | boolean {
      return !!val || "Select a Tag";
    },
    async getImageRepositories(searchQuery = ""): Promise<string[]> {
      this.isRepositoryError = false;
      if (!this.registryId) {
        console.warn("No registry id was provided for repositories");
        return [];
      }
      const repositoriesResponse: RepositoriesListResponse = await registryService
        .getRepositories(this.registryId, searchQuery)
        .catch(() => {
          this.isRepositoryError = true;
          return { repositories: [] };
        });
      if (!repositoriesResponse.repositories) return [];
      return repositoriesResponse.repositories;
    },
    async getImageTags(searchQuery = ""): Promise<string[]> {
      this.isTagError = false;
      if (!this.registryId) {
        console.warn("No registry id was provided for tags");
        return [];
      }
      if (!this.selectedRepository) {
        return [];
      }
      const tagsListResponse: TagsListResponse = await registryService
        .getTags(this.registryId, this.selectedRepository, searchQuery)
        .catch(() => {
          this.isTagError = true;
          return { tags: [] };
        });
      if (!tagsListResponse.tags) return [];
      return tagsListResponse.tags;
    },
    updateRepository(val: string): void {
      this.selectedRepository = val;
    },
    updateTag(val: string): void {
      this.selectedTag = val;
      if (this.selectedRepository && this.selectedTag) {
        this.updateModel(`${this.selectedRepository}:${this.selectedTag}`);
      }
    },
    clearRepository(): void {
      this.selectedTag = null;
    },
    async copyImageUrlToImageAndTag(): Promise<void> {
      if (!this.image) return;
      const [repository, tag] = this.image.split(":");
      if (!repository || !tag) return;
      const repositories = await this.getImageRepositories(repository);
      if (repositories.length === 0) {
        return;
      } else {
        this.selectedRepository = repository;
      }
      const tags = await this.getImageTags(tag);
      if (tags.length === 0) {
        this.selectedRepository = null;
        this.selectedTag = null;
      } else {
        this.selectedTag = tag;
      }
    },
  },
});
</script>
<style scoped>
.separator {
  font-size: 24px;
  padding-left: 30px;
  padding-top: 20px;
}
</style>
