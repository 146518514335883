<template>
  <section class="column items-center q-pt-md">
    <department-edit-form
      v-if="!initialLoading && department"
      :department="department"
      @save="save"
      @cancel="onCancel"
      :read-only="isDepartmentViewPage"
      :submitting="submitting"
    ></department-edit-form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useSettingStore } from "@/stores/setting.store";

import { DepartmentEditForm } from "@/components/department/department-edit-form";

import { requestToLeave } from "@/services/infra/router.service/router.service";

import type { INodePoolsNameAndId } from "@/models/node-pool.model";

// route
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import { useAppStore } from "@/stores/app.store";
import { alertUtil } from "@/utils/alert.util";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";
import type { Department, DepartmentCreationRequest } from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { resourceUtil } from "@/utils/resource.util";
import { ErrorAlert } from "@/utils/error-alert.util";
import type { IEmptyDepartmentModelConfig } from "@/models/org-unit.model";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { NodepoolSortFilterFields } from "@/swagger-models/cluster-service-client";

export default defineComponent({
  components: {
    DepartmentEditForm,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      department: null as Department | DepartmentCreationRequest | null,
      loading: false as boolean,
      initialLoading: true as boolean,
      submitting: false as boolean,
      clusterId: "" as string,
    };
  },
  async created() {
    try {
      this.loading = true;
      this.loadClusterId();
      await this.loadDepartment();
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
      this.initialLoading = false;
    }
  },
  computed: {
    isNewDepartment(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW;
    },
    isDepartmentViewPage(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_VIEW;
    },
    departmentId(): string {
      return this.$route.params.id.toString();
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
  },
  methods: {
    loadClusterId(): void {
      if (this.$route.query.clusterId) {
        this.clusterId = this.$route.query.clusterId.toString();
      }
    },
    async loadDepartment(): Promise<void> {
      if (this.isNewDepartment) {
        await this.loadNewDepartment();
      } else {
        await this.loadExistingDepartment();
      }
    },
    async loadNewDepartment(): Promise<void> {
      const emptyModelConfig = await this.getEmptyDepartmentModelConfig();
      const emptyDepartmentFields = orgUnitUtil.getEmptyDepartmentModel(emptyModelConfig);
      this.department = emptyDepartmentFields;
    },
    async getEmptyDepartmentModelConfig(): Promise<IEmptyDepartmentModelConfig> {
      return {
        nodePoolsNamesAndIds: this.clusterId ? await this.getNodePoolsNamesAndIds() : [],
        clusterId: this.clusterId,
        isCpuEnabled: this.isCpuEnabled,
      };
    },
    async loadExistingDepartment(): Promise<void> {
      const department = await orgUnitService.getDepartment(this.departmentId);
      const resources = resourceUtil.sortNodePools(department.resources);
      department.resources = orgUnitUtil.initResourcesOverQuotaWeight(resources, this.isOverQuotaPriorityEnabled);
      if (this.isCpuEnabled) {
        department.resources = orgUnitUtil.enrichResourcesWithCpuAndMemory(department.resources);
      }
      this.department = department;
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX });
    },
    async save(department: Department | DepartmentCreationRequest): Promise<void> {
      const departmentToSave = department as Department | DepartmentCreationRequest;
      this.submitting = true;

      let savedDepartment: Department;
      departmentToSave.resources = orgUnitUtil.updateOverQuotaWeightToDeserved(departmentToSave.resources);
      if (!this.isOverQuotaPriorityEnabled) {
        departmentToSave.resources = orgUnitUtil.removeOverQuotaWeightFields(departmentToSave.resources);
      }
      try {
        if (this.isNewDepartment) {
          savedDepartment = await orgUnitService.createDepartment(departmentToSave as DepartmentCreationRequest);
          await useAuthStore().loadUserOrgUnits();
        } else {
          const departmentUpdateRequest = orgUnitUtil.getDepartmentUpdateRequest(departmentToSave as Department);
          savedDepartment = await orgUnitService.updateDepartment(this.departmentId, departmentUpdateRequest);
        }
        this.$q.notify(
          alertUtil.getSuccess(`Department ${departmentToSave.name} ${this.isNewDepartment ? "created" : "saved"}`),
        );
        this.redirectToDepartmentIndex(savedDepartment);
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.submitting = false;
      }
    },
    handleError(error: unknown) {
      const errorAlert = new ErrorAlert({
        generalMessage: this.isNewDepartment
          ? ErrorAlert.failedCreateMessage("department")
          : ErrorAlert.failedUpdateMessage("department"),
      });
      this.$q.notify(errorAlert.getNotification(error));
    },
    async getNodePoolsNamesAndIds(): Promise<INodePoolsNameAndId[]> {
      const nodePools = await clusterService.getNodepools([
        filterUtil.getEqualsFilterString(NodepoolSortFilterFields.ClusterId, this.clusterId),
      ]);
      return nodePools.map((nodePool) => ({
        id: nodePool.id,
        name: nodePool.name,
      }));
    },
    redirectToDepartmentIndex(department: Department): void {
      if (this.isNewDepartment) {
        this.setClusterColumnFilter(department);
      }
      this.clusterStore.setSelectedClusterById(department.clusterId);
      this.$router.push({
        name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
        query: {
          createdEntityId: department.id,
        },
      });
    },
    setClusterColumnFilter(department: Department): void {
      this.clusterStore.setSelectedClusterById(department.clusterId);
    },
  },
});
</script>
