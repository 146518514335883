<template>
  <runai-base-dialog aid="app-modal-dialog" :model-value="modelValue" size="dynamic" no-padding @close="$emit('close')">
    <template #header>
      <span aid="apps-creation-header-text">{{ headerText }}</span></template
    >
    <template #body>
      <q-stepper
        active-icon="fa-solid fa-pen"
        class="application-stepper"
        v-model="currentStep"
        ref="stepper"
        color="primary"
        animated
        header-class="header-class"
      >
        <q-step :name="0" :title="firstStepTitle" icon="" :done="isLastStep">
          <section style="min-width: 500px">
            <q-form
              aid="first-app-screen"
              class="q-my-md"
              v-if="isCreateMode"
              ref="elNewAppForm"
              @submit.prevent="nextStepAction"
            >
              <applications-creation-screen
                v-model="newApplication.name"
                :is-user-app="isUserApp"
              ></applications-creation-screen>
            </q-form>
            <p v-else aid="first-regenerate-screen" class="q-my-lg text-body1">
              Regenerate the client secret for {{ application?.name }}?
            </p>
          </section>
        </q-step>
        <q-step aid="review-screen" :name="1" title="Review" icon="fa-solid fa-clipboard-list" :done="isLastStep">
          <applications-view-screen
            :is-create-mode="isCreateMode"
            :name="name"
            :secret="secret"
            :client="clientId"
          ></applications-view-screen>
        </q-step>
      </q-stepper>
    </template>
    <template #footer>
      <q-stepper-navigation>
        <q-btn v-if="!isLastStep" aid="cancel-btn" flat color="primary" @click="close" label="Cancel" class="q-ml-sm" />
        <q-btn @click="nextStepAction" aid="next-btn" color="primary" :loading="loading" :label="nextBtnLabel" />
      </q-stepper-navigation>
    </template>
  </runai-base-dialog>
</template>
<script lang="ts">
// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { ApplicationsCreationScreen } from "@/components/applications/applications-creation-screen";
import { ApplicationsViewScreen } from "@/components/applications/applications-view-screen";
// stores
import { useAppStore } from "@/stores/app.store";
// types
import type {
  IApplication,
  IApplicationCreationRequeset,
  IApplicationCreationResponse,
  IApplicationRegenerateSecretResponse,
} from "@/models/applications.model";
// utils
import { HttpErrorResponse } from "@/models/http-response.model";
// services
import { alertUtil } from "@/utils/alert.util";
import type { QStepper } from "quasar";
import type { PropType } from "vue";
import { ActionTypeEnum } from "@/components/user/action-type.model";
import { applicationsService } from "@/services/control-plane/applications.service/applications.service";
import type {
  UserApp,
  UserAppCreationRequest,
  UserAppPostResponse,
  RegenerateUserApplicationSecret200Response,
} from "@/swagger-models/identity-manager-client";
import { userApplicationsService } from "@/services/control-plane/user-applications.service/user-applications.service";

export type TActionTypes = "create" | "regenerate";

export default {
  components: { ApplicationsCreationScreen, ApplicationsViewScreen, RunaiBaseDialog },
  emits: ["close", "load-applications"],
  props: {
    actionType: {
      type: String as PropType<TActionTypes>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    application: {
      type: Object as PropType<IApplication | UserApp>,
      required: false,
    },
    isUserApp: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      appForm: null as HTMLFormElement | null,
      loading: false as boolean,
      currentStep: 0 as number,
      name: "" as string,
      secret: "" as string,
      clientId: "" as string,
      newApplication: {
        name: "",
      } as IApplicationCreationRequeset | UserAppCreationRequest,
    };
  },
  created() {
    useAppStore().setPageLoading(false);
  },
  mounted() {
    this.$nextTick(() => {
      this.appForm = this.$refs.elNewAppForm as HTMLFormElement;
    });
  },
  computed: {
    headerText(): string {
      return this.isCreateMode ? "New Application" : "Regenerate Client Secret";
    },
    isLastStep(): boolean {
      return this.currentStep > 0;
    },
    nextBtnLabel(): string {
      return this.isCreateMode
        ? [ActionTypeEnum.CREATE, ActionTypeEnum.DONE][this.currentStep]
        : [ActionTypeEnum.REGENERATE, ActionTypeEnum.DONE][this.currentStep];
    },
    firstStepTitle(): string {
      return this.isCreateMode ? "Create" : "Regenerate";
    },
    isCreateMode(): boolean {
      return this.actionType === ActionTypeEnum.CREATE;
    },
  },
  methods: {
    close(): void {
      this.$emit("close");
    },
    async nextStepAction(): Promise<void> {
      if (this.isLastStep) {
        this.close();
      } else {
        this.isCreateMode ? await this.createApplication() : await this.regenerateSecret();
      }
    },
    async validate(): Promise<boolean> {
      return await (this.appForm as HTMLFormElement).validate();
    },
    async creatAppByType(): Promise<IApplicationCreationResponse | UserAppPostResponse> {
      if (this.isUserApp) {
        return await userApplicationsService.createUserApp({
          name: this.newApplication.name,
        });
      } else {
        return await applicationsService.create({
          name: this.newApplication.name,
        });
      }
    },
    async generateSecretByType(appId: string): Promise<string | undefined> {
      let secretResponse: IApplicationRegenerateSecretResponse | RegenerateUserApplicationSecret200Response;
      if (this.isUserApp) {
        secretResponse = await userApplicationsService.generateUserAppSecret(appId);
      } else {
        secretResponse = await applicationsService.regenerateSecret(appId);
      }
      return secretResponse.secret;
    },
    async createApplication(): Promise<void> {
      try {
        const isValid = await this.validate();
        if (!isValid) return;

        this.loading = true;
        let newApplication = await this.creatAppByType();
        this.name = newApplication.name;
        this.secret = newApplication.secret;
        this.clientId = newApplication.clientId;
        this.$emit("load-applications", newApplication.id);
        this.$q.notify(alertUtil.getSuccess(`Application ${newApplication.name} created`));
        (this.$refs.stepper as QStepper).next();
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to create application"));
        }
      } finally {
        this.loading = false;
      }
    },
    async regenerateSecret(): Promise<void> {
      if (!this.application) return;
      try {
        this.loading = true;
        const secretResponse = await this.generateSecretByType(this.application.id);
        this.name = this.application.name;
        this.clientId = this.application?.clientId;
        this.secret = secretResponse || "";
        (this.$refs.stepper as QStepper).next();
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to regenerate client secret"));
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.application-stepper {
  .q-stepper__dot {
    .q-icon.fa-solid.fa-pen {
      font-size: 12px;
    }
  }

  .header-class {
    display: flex;
    justify-content: center;

    div.col-grow {
      flex: unset;
    }
  }
}
.footer {
  .q-stepper__nav {
    padding-top: unset;
  }
}
</style>
