// Stores
import { defineStore } from "pinia";
import { useSettingStore } from "./setting.store";
import { useWorkloadStore } from "./workload.store";
import { useClusterStore } from "./cluster.store";

// Models
import {
  type AssetsDefaults,
  type WorkloadCreationRequest,
  type Inference,
  type InferenceCreationRequestV2,
  type InferenceV2,
  type PolicyInfo,
  type InferenceSpecificRunParams,
  Scope,
} from "@/swagger-models/assets-service-client";
import { EWorkloadType, type IUIInferenceCreation } from "@/models/workload.model";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";

// utils
import { inferenceWorkloadUtil } from "@/utils/inference-workload.util/inference-workload.util";
import { deepCopy } from "@/utils/common.util";

// services
import { inferenceService } from "@/services/control-plane/inference.service/inference.service";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";

// constants
import {
  MIN_CLUSTER_VERSION_FOR_SERVING_ENDPOINT_ACCESS,
  MIN_CLUSTER_VERSION_FOR_INFERENCE_TOP_ROW,
  MIN_WORKLOAD_CREATION_V2_VERSION,
} from "@/common/version.constant";

export const useInferenceStore = defineStore("Inference", {
  state: () => ({
    inference: inferenceWorkloadUtil.getEmptyUIInferenceCreation() as IUIInferenceCreation,
    loadedInferenceModelWithDefaultsAndPolicy: null as InferenceV2 | null,
    selectedInference: null as Inference | null,
    originRouteName: "" as string,
  }),
  getters: {
    inferenceName(): string {
      return this.inference.name || "";
    },
    loadedPolicyInfo(): PolicyInfo | null | undefined {
      return this.loadedInferenceModelWithDefaultsAndPolicy?.policyInfo || null;
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedItemizeDefaults(): ILoadedItemizeDefaults | null {
      const itemizeDefaults = {
        annotations: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.annotations || null,
        },
        labels: { instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.labels || null },
        environmentVariables: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.environmentVariables || null,
        },
        tolerations: {
          instances: this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams?.tolerations || null,
          attributes:
            this.loadedInferenceModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.specificRunParams
              ?.tolerations || null,
        },
      };
      return deepCopy(itemizeDefaults);
    },
    // these details are treated as read only after setting (saving the origin model with policies set)
    loadedSpecificRunParamsWithDefaults(): InferenceSpecificRunParams | null {
      return deepCopy(this.loadedInferenceModelWithDefaultsAndPolicy?.specificRunParams || null);
    },
    loadedAssetsAttributesDefaults(): AssetsDefaults | null {
      return this.loadedInferenceModelWithDefaultsAndPolicy?.policyInfo?.attributeDefaults?.assets || null;
    },
    isTopRowSupported:
      () =>
      (clusterId: string): boolean => {
        const clusterStore = useClusterStore();
        return clusterStore.isClusterVersionSufficient(clusterId, MIN_CLUSTER_VERSION_FOR_INFERENCE_TOP_ROW);
      },

    supportingServingPortAccess:
      () =>
      (clusterId: string): boolean => {
        const clusterStore = useClusterStore();
        return (
          clusterStore.isClusterVersionSufficient(clusterId, MIN_CLUSTER_VERSION_FOR_SERVING_ENDPOINT_ACCESS) &&
          unleashService.showServingEndpointAccessInference()
        );
      },
  },
  actions: {
    async createInference(inference: IUIInferenceCreation): Promise<Inference | InferenceV2> {
      const clusterStore = useClusterStore();
      const settingStore = useSettingStore();
      const workloadStore = useWorkloadStore();
      const isWorkloadCreationFromControlPlan =
        clusterStore.isClusterVersionSufficient(inference.clusterId, MIN_WORKLOAD_CREATION_V2_VERSION) &&
        settingStore.isWorkloadSubmissionV2Enabled;

      let workloadVolumes: Array<string> | undefined;
      if (inference.assets.uiVolumes?.length) {
        workloadVolumes = await dataSourceService.createWorkloadVolumes(inference.name, inference.assets.uiVolumes, {
          scope: Scope.Project,
          projectId: inference.projectId,
        });
      }

      let workloadCreated: Inference | InferenceV2;
      if (isWorkloadCreationFromControlPlan) {
        const workloadCreationRequest: InferenceCreationRequestV2 = inferenceWorkloadUtil.getInferenceCreationRequestV2(
          inference,
          this.supportingServingPortAccess(inference.clusterId),
          workloadVolumes,
        );
        workloadCreated = await inferenceService.createInferenceV2(workloadCreationRequest);
      } else {
        const workloadCreationRequest: WorkloadCreationRequest =
          inferenceWorkloadUtil.getInferenceWorkloadCreationRequest(
            inference,
            this.supportingServingPortAccess(inference.clusterId),
            workloadVolumes,
          );

        workloadCreated = await inferenceService.create(workloadCreationRequest);
      }

      if (this.isTopRowSupported(inference.clusterId)) {
        workloadStore.setWorkloadAdded(workloadCreated, EWorkloadType.Inference);
      }
      return workloadCreated;
    },
    setInference(inference: IUIInferenceCreation): void {
      this.inference = inference;
    },
    resetCreateInferenceData(): void {
      this.inference = inferenceWorkloadUtil.getEmptyUIInferenceCreation();
      this.loadedInferenceModelWithDefaultsAndPolicy = null;
    },
    setOriginRoutePage(name: string): void {
      this.originRouteName = name;
    },
    setLoadedInferenceModelWithDefaults(inference: InferenceV2): void {
      this.loadedInferenceModelWithDefaultsAndPolicy = inference;
    },
  },
});
