<template>
  <section class="nfs-instance-section">
    <section>
      <div class="q-mb-md">
        <div class="q-py-md">Set the data origin</div>
        <runai-tooltip-wrapper
          :display-tooltip="allowOnlyContainerPath"
          tooltip-text="This was defined when the data source was created and can't be modified"
          anchor="bottom middle"
          self="bottom middle"
        >
          <q-input
            :disable="allowOnlyContainerPath"
            aid="server-input"
            :model-value="modelValue.server"
            no-error-icon
            label="NFS server (host name or host IP)"
            stack-label
            placeholder="e.g: MyServer"
            input-class="placeholder-italic server-input"
            :rules="[serverNotEmpty]"
            @update:model-value="$emit('update:model-value', { ...modelValue, server: $event })"
          >
          </q-input>
        </runai-tooltip-wrapper>
      </div>

      <div class="q-mb-md">
        <runai-tooltip-wrapper
          :display-tooltip="allowOnlyContainerPath"
          tooltip-text="This was defined when the data source was created and can't be modified"
          anchor="bottom middle"
          self="bottom middle"
        >
          <q-input
            :disable="allowOnlyContainerPath"
            aid="path-input"
            :model-value="modelValue.path"
            no-error-icon
            label="NFS path"
            stack-label
            placeholder="e.g: /usr/data"
            input-class="placeholder-italic"
            :rules="[notEmpty, isDirectoryPath]"
            @update:model-value="$emit('update:model-value', { ...modelValue, path: $event })"
          />
        </runai-tooltip-wrapper>
      </div>

      <div>
        <div class="q-py-md">{{ containerPathTitle }}</div>
        <div class="row">
          <q-input
            class="col-6"
            aid="mount-path-input"
            :model-value="modelValue.mountPath"
            no-error-icon
            label="Container path"
            stack-label
            placeholder="e.g: /home/local/data"
            input-class="placeholder-italic"
            :rules="[notEmpty, isDirectoryPath]"
            @update:model-value="$emit('update:model-value', { ...modelValue, mountPath: $event })"
          />
        </div>
      </div>
    </section>
    <section v-if="showRestrictions">
      <runai-sub-expansion-item label="Restrictions">
        <section class="row items-center q-mb-md">
          <q-toggle
            aid="read-only-toggle"
            :model-value="modelValue.readOnly"
            label="Prevent data modification"
            left-label
            class="text-no-wrap"
            @update:model-value="$emit('update:model-value', { ...modelValue, readOnly: $event })"
          />
          <runai-tooltip
            class="q-ml-md"
            tooltip-position="right"
            tooltip-text="When enabled, the data will be mounted with read-only permissions."
          />
        </section>
      </runai-sub-expansion-item>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSubExpansionItem } from "@/components/common/runai-sub-expansion-item";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
// Models
import type { IUINfsInstance } from "@/models/workload.model";
// Utils
import { isNotEmpty, isValidDirectoryPath } from "@/common/form.validators";
// Constants
import { errorMessages } from "@/common/error-message.constant";
import { assetsUtil } from "@/utils/assets.util/assets.util";

export default defineComponent({
  name: "nfs-instance-section",
  components: {
    RunaiSubExpansionItem,
    RunaiTooltip,
    RunaiTooltipWrapper,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Object as PropType<IUINfsInstance>,
      required: true,
    },
    showRestrictions: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    allowOnlyContainerPath: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    containerPathTitle(): string {
      return assetsUtil.containerPathTitle();
    },
  },
  methods: {
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.PATH_NOT_EMPTY;
    },
    serverNotEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SERVER_NAME_OR_IP_EMPTY;
    },
  },
});
</script>
