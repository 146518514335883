import { AuditLogsApi, Configuration, type ConfigurationParameters } from "@/swagger-models/audit-service-client";

class AuditServiceApi {
  configuration: Configuration;
  auditLogsApi: AuditLogsApi;

  constructor() {
    this.configuration = new Configuration();
    this.auditLogsApi = new AuditLogsApi(this.configuration, this.baseUrl);
  }
  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const auditServiceApi = new AuditServiceApi();
