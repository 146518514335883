<template>
  <q-btn-dropdown
    :style="customStyle"
    :size="size"
    :flat="flat"
    :dropdown-icon="dropdownIcon"
    no-icon-animation
    color="black-54"
    :class="customClasses"
  >
    <q-list>
      <q-item
        clickable
        v-close-popup
        v-for="item in options"
        :key="item.label"
        :active="item.value === selectedValue"
        :focused="item.value === selectedValue"
        @click="$emit('on-select', item.value)"
      >
        <q-item-section>
          <q-item-label>{{ item.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { ISelectOption } from "@/models/global.model";

export default defineComponent({
  emits: ["on-select"],
  props: {
    customStyle: {
      type: Object as PropType<Record<string, string>>,
      required: false,
      default: () => ({}),
    },
    dropdownIcon: {
      type: String as PropType<string>,
      required: false,
      default: "fa-solid fa-caret-down",
    },
    selectedValue: {
      type: String as PropType<string>,
      required: true,
    },
    options: {
      type: Array as PropType<ISelectOption[]>,
      required: true,
    },
    size: {
      type: String as PropType<string>,
      required: false,
      default: "xs",
    },
    flat: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    customClasses: {
      type: String as PropType<string>,
      required: false,
    },
  },
});
</script>

<style lang="scss"></style>
