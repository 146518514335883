<template>
  <section class="user-app-box q-pa-md row items-center">
    <div class="col-10">
      <div class="row">
        <q-input
          aid="user-app-name"
          class="col-3 q-mr-md"
          label="Application name"
          stack-label
          :model-value="userApp.name"
          no-error-icon
          :disable="true"
        />
        <q-input
          aid="user-app-creation-time"
          class="col-4 q-mr-md"
          label="Creation time"
          stack-label
          :model-value="createdAt"
          no-error-icon
          :disable="true"
        />
        <q-input
          aid="user-app-last-login"
          class="col-4"
          label="Last login"
          stack-label
          :model-value="lastLogin"
          no-error-icon
          :disable="true"
        />
      </div>

      <div class="row q-mt-md">
        <q-input
          aid="user-app-client-id"
          class="col-3"
          label="Client ID"
          stack-label
          :model-value="clientId"
          no-error-icon
          :disable="true"
        />
        <div class="col-1 flex items-center"><runai-copy-to-clipboard-btn :text-to-copy="clientId" size="12px" /></div>
      </div>
    </div>

    <div class="col-2 row items-center justify-center">
      <div class="row items-center">
        <q-btn
          aid="remove-user-app-button"
          icon="fa-solid fa-trash-can"
          flat
          round
          @click="removeUserApp"
          class="icon-button"
        >
          <q-tooltip>Delete application</q-tooltip>
        </q-btn>
        <q-btn
          aid="regenerate-password-user-app-button"
          icon="fa-solid fa-lock-hashtag"
          flat
          round
          @click="regeneratePassword"
          class="icon-button"
        >
          <q-tooltip>Regenerate client secret</q-tooltip>
        </q-btn>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
// Models
// Utils
import type { UserApp } from "@/swagger-models/identity-manager-client";
import { dateUtil } from "@/utils/date.util";
import { RunaiCopyToClipboardBtn } from "@/components/common/runai-copy-to-clipboard-btn";

export default defineComponent({
  name: "user-app-box",
  components: { RunaiCopyToClipboardBtn },
  emits: ["remove-user-app", "regenerate-password"],
  props: {
    userApp: {
      type: Object as PropType<UserApp>,
      required: true,
    },
  },
  computed: {
    createdAt(): string {
      return this.userApp.createdAt ? dateUtil.dateAndTimeFormat(new Date(this.userApp.createdAt)) : "";
    },
    lastLogin(): string {
      return this.userApp.lastLogin ? dateUtil.dateAndTimeFormat(new Date(this.userApp.lastLogin as string)) : "Never";
    },
    clientId(): string {
      return this.userApp.clientId || "";
    },
  },
  methods: {
    removeUserApp(): void {
      this.$emit("remove-user-app", this.userApp);
    },
    regeneratePassword(): void {
      this.$emit("regenerate-password", this.userApp);
    },
  },
});
</script>

<style lang="scss" scoped>
.user-app-box {
  background-color: $body-background-color;

  .icon-button {
    color: $black-54;
    font-size: 12px;
  }
}
</style>
