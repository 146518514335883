<template>
  <runai-tooltip-wrapper :display-tooltip="displayTooltip" :tooltip-text="disabledTooltipText">
    <runai-select
      v-bind="$attrs"
      label="Cluster"
      aid="cluster-dropdown"
      :options="clusterOptions"
      :model-value="selectedClusterOption"
      @update:model-value="updateSelectedCluster"
      :rules="[isClusterSelected, ...rules]"
      :disabled="disabled"
      unclearable
    />
  </runai-tooltip-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ValidationRule } from "quasar";
import { RunaiSelect } from "@/components/common/runai-select";
import type { ISelectOption } from "@/models/global.model";
import { errorMessages } from "@/common/error-message.constant";
import { useClusterStore } from "@/stores/cluster.store";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";

export default defineComponent({
  name: "runai-cluster-dropdown",
  components: { RunaiTooltipWrapper, RunaiSelect },
  emits: ["update:cluster-id"],
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rules: {
      type: Array as PropType<ValidationRule[]>,
      default: () => [],
    },
    disabledTooltipText: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
    };
  },
  computed: {
    displayTooltip(): boolean {
      return this.disabled && !!this.disabledTooltipText;
    },
    clusterOptions(): Array<ISelectOption> {
      return this.clusterStore.clusterList.map((cluster) => ({
        label: cluster.name,
        value: cluster.uuid,
      }));
    },
    selectedClusterOption(): ISelectOption | null {
      return this.clusterOptions.find((option) => option.value === this.clusterId) || null;
    },
  },
  methods: {
    updateSelectedCluster(clusterOption: ISelectOption | null): void {
      if (clusterOption !== null) {
        this.$emit("update:cluster-id", clusterOption.value);
      } else {
        this.$emit("update:cluster-id", "");
      }
    },
    isClusterSelected(clusterOption: ISelectOption): boolean | string {
      return !!clusterOption || errorMessages.SELECT_CLUSTER;
    },
  },
});
</script>

<style scoped lang="scss"></style>
