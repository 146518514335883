<template>
  <section class="settings-index q-pt-md">
    <user-profile-section />
    <email-notifications-section
      v-if="notificationsSettingsCategories"
      :notification-categories="notificationsSettingsCategories"
      :smtp-enabled="isSmtpEnabledForTenant"
      @save="saveNotificationsSettings"
      :subscription-id="subscriptionID"
    />
    <user-apps-section v-if="showUserApps" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
// types
import type { CategorizedNotificationSettings, NotificationSettings } from "@/models/user-setting.model";
// services
import { notificationService } from "@/services/control-plane/notifications.service/notification.service";
// utils
import { userNotificationsUtil } from "@/utils/user-notifications.utils";
import { alertUtil } from "@/utils/alert.util";

// components
import { UserAppsSection } from "@/components/user-settings/sections/user-apps-section";
import UserProfileSection from "@/components/user-settings/sections/user-profile-section/user-profile-section.vue";
import EmailNotificationsSection from "@/components/user-settings/sections/email-notifications-section/email-notifications-section.vue";
import type { NotificationType, Subscription } from "@/swagger-models/notifications-proxy-client";
import { useClusterStore } from "@/stores/cluster.store";
import { MIN_CLUSTER_VERSION_FOR_PERSONAL_APPS } from "@/common/version.constant";

export default defineComponent({
  name: "user-settings-index",
  components: { EmailNotificationsSection, UserProfileSection, UserAppsSection },
  data() {
    return {
      subscriptionID: "",
      appStore: useAppStore(),
      authStore: useAuthStore(),
      clusterStore: useClusterStore(),
      isSmtpEnabledForTenant: false as boolean,
      notificationsSettingsCategories: null as CategorizedNotificationSettings | null,
    };
  },
  async created() {
    try {
      this.isSmtpEnabledForTenant = await notificationService.isSmtpEnabledForTenant();
      this.notificationsSettingsCategories = await this.getNotificationsSettings();
    } catch (error) {
      this.$q.notify(alertUtil.getError("Failed to load user notifications settings"));
      this.notificationsSettingsCategories = null;
    }
    this.appStore.setPageLoading(false);
  },
  computed: {
    showUserApps(): boolean {
      const clusterId = this.clusterStore.selectedClusterId;
      return clusterId
        ? this.clusterStore.isClusterVersionSufficient(clusterId, MIN_CLUSTER_VERSION_FOR_PERSONAL_APPS)
        : false;
    },
  },
  methods: {
    async getNotificationsSettings(): Promise<CategorizedNotificationSettings> {
      const subscriptions = await notificationService.getUserSubscriptions();
      if (subscriptions && subscriptions.length > 0) {
        this.subscriptionID = subscriptions[0].id as string;
      }
      const notificationTypes: Array<NotificationType> = await notificationService.getUserNotificationTypes();
      const notificationSettings: Array<NotificationSettings> = notificationTypes.map((notificationType) => ({
        ...notificationType,
        enabled: false,
      }));
      return userNotificationsUtil.categorizeAndUpdateNotificationSettings(notificationSettings, subscriptions);
    },
    async saveNotificationsSettings(subscription: Subscription) {
      try {
        if (!this.subscriptionID) {
          await notificationService.createUserSubscription(subscription);
        } else {
          await notificationService.updateUserSubscription(this.subscriptionID, subscription);
        }
        this.notificationsSettingsCategories = await this.getNotificationsSettings();
        this.$q.notify(alertUtil.getSuccess("Settings saved"));
      } catch (error) {
        this.$q.notify(alertUtil.getError("Failed to save user notifications settings"));
      }
    },
  },
});
</script>
