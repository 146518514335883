<template>
  <section class="page column justify-between bg-white">
    <header class="row justify-between items-center q-mx-xl q-py-md q-px-sm">
      <runai-brand-logo height="50" dark />
      <span v-if="errorNum" class="error text-h3">{{ errorNum }}</span>
    </header>
    <router-view class="flex-1 q-mx-xl" />
    <footer v-if="!brandingLogo" class="row justify-center items-center q-mx-xl q-py-lg">
      <q-icon name="fa-regular fa-copyright" color="black-54"></q-icon>
      <span class="q-ml-xs">{{ currentYear }} Run:ai Labs all rights reserved</span>
    </footer>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppStore } from "@/stores/app.store";
import { RunaiBrandLogo } from "@/components/common/runai-brand-logo";

export default defineComponent({
  components: {
    RunaiBrandLogo,
  },
  data() {
    return {
      errorNum: this.$route.meta?.pageTitle,
      currentYear: new Date().getFullYear(),
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setAppLoaded(true);
    this.appStore.setPageLoading(false);
  },
  computed: {
    brandingLogo(): string | undefined {
      return this.appStore.branding?.logo.dark;
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
}
header {
  border-bottom: 1px solid #e0e0e0;
}

.error {
  color: $black-12;
  font-weight: 700;
}

footer {
  border-top: 1px solid #e0e0e0;
  color: $black-54;
}
</style>
