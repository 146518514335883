<template>
  <hybrid-section-wrapper
    class="hybrid-data-source-section"
    label="Data sources"
    @load-section-templates="$emit('load-section-templates')"
  >
    <template #subheader>
      <span>{{ dataSourceSummary }}</span>
    </template>

    <div>Set the data sources your workload needs to access</div>
    <q-field
      v-if="tabs.length"
      class="q-my-md form-hint no-padding"
      :model-value="isValidationError"
      :rules="[isIncomplete]"
    />

    <q-tabs
      v-if="tabs.length > 0"
      v-model="selectedId"
      outside-arrows
      active-color="primary"
      inline-label
      align="left"
      class="data-source-tabs q-mb-sm underline"
    >
      <q-tab v-for="tab in tabs" class="tab row items-center" :name="tab.id" :key="tab.id">
        <runai-svg-icon v-if="tab.icon" :name="tab.icon" size="24px" class="q-mr-sm"></runai-svg-icon>
        <span class="q-pr-sm text-weight-medium">{{ assetKindToLabelMap[tab.label as AssetKind] }}</span>
        <q-icon v-if="tab.isInvalid" class="q-mr-sm" name="fa-regular fa-circle-exclamation" color="red" size="16px" />
        <q-btn
          aid="remove-storage-btn"
          round
          color="grey-4"
          text-color="black-70"
          unelevated
          size="sm"
          icon="fa-regular fa-xmark"
          class="close-btn"
          @click.stop="removeStorage(tab.id, tab.label)"
        />
      </q-tab>
    </q-tabs>

    <host-path-instance-section
      v-if="selectedHostPath"
      :model-value="selectedHostPath"
      @update:model-value="updateHostPath"
      allow-only-container-path
    />
    <nfs-instance-section
      v-else-if="selectedNfs"
      :model-value="selectedNfs"
      allow-only-container-path
      @update:model-value="updateNfs"
    />
    <s3-instance-section
      v-else-if="selectedS3"
      :model-value="selectedS3"
      :secrets="[selectedS3.accessKeySecret || '']"
      allow-only-container-path
      :loading-secrets="false"
      @update:model-value="updateS3"
    />

    <git-instance-section
      v-else-if="selectedGit"
      :model-value="selectedGit"
      :secrets="[selectedGit.passwordSecret || '']"
      allow-only-container-path
      :support-git-sync-one-parameter="true"
      :loading-secrets="false"
      @update:model-value="updateGit"
    />

    <secret-volume-instance-section
      v-else-if="selectedSecretVolume"
      :model-value="selectedSecretVolume"
      :secrets="[selectedSecretVolume.secret || '']"
      allow-only-container-path
      :loading-secrets="false"
      @update:model-value="updateSecretVolume"
    />

    <config-map-instance-section
      v-else-if="selectedConfigMap"
      :model-value="selectedConfigMap"
      :config-map-names="[selectedConfigMap.configMap || '']"
      allow-only-container-path
      :loading-config-map-names="false"
      @update:model-value="updateConfigMap"
    />

    <pvc-instance-section
      v-else-if="selectedPvc"
      :model-value="selectedPvc"
      :loading-pvc-claim-names="false"
      :pvc-claim-names="[selectedPvc.claimName || '']"
      allow-only-container-path
      @update:model-value="updatePvc"
    />

    <div v-if="!tabs?.length" class="q-mt-xl column items-center text-italic text-black-70">
      <q-icon size="55px" name="fa-light fa-database" class="q-mb-md" color="black-54"></q-icon>
      <div class="text-black-54">No data sources yet.</div>
      <div class="text-black-54">Click the button above to load existing setups</div>
    </div>
  </hybrid-section-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { HybridSectionWrapper } from "../hybrid-section-wrapper";
import { NfsInstanceSection } from "./nfs-instance-section";
import { HostPathInstanceSection } from "./host-path-instance-section";
import { S3InstanceSection } from "./s3-instance-section";
import { PvcInstanceSection } from "./pvc-instance-section";
import { GitInstanceSection } from "@/components/workload-creation/hybrid-data-source-section/git-instance-section";
import { SecretVolumeInstanceSection } from "./secret-volume-instance-section";
import { ConfigMapInstanceSection } from "./config-map-instance-section";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
// Models
import { AssetKind } from "@/swagger-models/assets-service-client";
import { DATA_SOURCE_ICONS_NAMES } from "@/common/icons.constant";
import {
  type IUIConfigMapInstance,
  type IUIGitInstance,
  type IUIHostPathInstance,
  type IUINfsInstance,
  type IUIPvcInstance,
  type IUIS3Instance,
  type IUISecretVolumeInstance,
  type IUIStorageVolumesExcludedFields,
} from "@/models/workload.model";
import type { ITabProps } from "@/components/section/compute-resource-section/container-path-override/container-path-override.model";
import { isNotEmpty, isValidDirectoryPath } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import type { TDataSourceKinds } from "@/models/data-source.model";
import { DATA_SOURCE_KIND_TO_CONTAINER_KEY } from "@/models/data-source.model";

export default defineComponent({
  name: "hybrid-data-source-section",
  components: {
    HybridSectionWrapper,
    NfsInstanceSection,
    S3InstanceSection,
    PvcInstanceSection,
    HostPathInstanceSection,
    GitInstanceSection,
    SecretVolumeInstanceSection,
    ConfigMapInstanceSection,
    RunaiSvgIcon,
  },
  emits: ["load-section-templates", "update"],
  props: {
    workloadStorage: {
      type: Object as PropType<IUIStorageVolumesExcludedFields>,
      default: () => ({}),
    },
  },
  data() {
    return {
      assetKindToCmpMap: {
        [AssetKind.Nfs]: NfsInstanceSection,
        [AssetKind.S3]: S3InstanceSection,
        [AssetKind.HostPath]: HostPathInstanceSection,
        [AssetKind.Git]: GitInstanceSection,
        [AssetKind.SecretVolume]: SecretVolumeInstanceSection,
        [AssetKind.ConfigMap]: ConfigMapInstanceSection,
        [AssetKind.Pvc]: PvcInstanceSection,
      },
      assetKindToLabelMap: {
        [AssetKind.Nfs]: "NFS",
        [AssetKind.S3]: "S3",
        [AssetKind.HostPath]: "Host path",
        [AssetKind.Git]: "Git",
        [AssetKind.SecretVolume]: "Secret",
        [AssetKind.ConfigMap]: "Config map",
        [AssetKind.Pvc]: "PVC",
      } as Record<AssetKind, string>,
      selectedId: null as string | null,
      dataSourceIconNames: DATA_SOURCE_ICONS_NAMES,
    };
  },
  computed: {
    dataSourceSummary(): string {
      let summary = "";
      if (this.tabs.length > 0) {
        summary = `${this.assetKindToLabelMap[this.tabs[0].label as AssetKind]}`;
      }
      if (this.tabs.length > 1) {
        return summary + ` + ${this.tabs.length - 1} more`;
      }
      if (this.tabs.length === 0) {
        return "None";
      }
      return summary;
    },
    selectedNfs(): IUINfsInstance | undefined {
      return this.workloadStorage.nfs?.find((ds) => ds.id === this.selectedId);
    },
    selectedHostPath(): IUIHostPathInstance | undefined {
      return this.workloadStorage.hostPath?.find((ds) => ds.id === this.selectedId);
    },
    selectedS3(): IUIS3Instance | undefined {
      return this.workloadStorage.s3?.find((ds) => ds.id === this.selectedId);
    },
    selectedGit(): IUIGitInstance | undefined {
      return this.workloadStorage.git?.find((ds) => ds.id === this.selectedId);
    },
    selectedSecretVolume(): IUISecretVolumeInstance | undefined {
      return this.workloadStorage.secretVolume?.find((ds) => ds.id === this.selectedId);
    },
    selectedConfigMap(): IUIConfigMapInstance | undefined {
      return this.workloadStorage.configMapVolume?.find((ds) => ds.id === this.selectedId);
    },
    selectedPvc(): IUIPvcInstance | undefined {
      return this.workloadStorage.pvc?.find((ds) => ds.id === this.selectedId);
    },
    tabs(): ITabProps[] {
      return Object.values(this.workloadStorage)
        .flat()
        .filter((ds) => !!ds)
        .map((ds) => {
          return {
            key: ds.id,
            id: ds.id,
            label: ds.type,
            isInvalid: this.isInvalidContainerPath(ds),
            icon: DATA_SOURCE_ICONS_NAMES[ds.type as keyof typeof DATA_SOURCE_ICONS_NAMES],
          };
        });
    },
    isValidationError(): boolean {
      return this.tabs.some((tab) => tab.isInvalid);
    },
  },
  methods: {
    isInvalidContainerPath(
      ds:
        | IUINfsInstance
        | IUIS3Instance
        | IUIPvcInstance
        | IUIHostPathInstance
        | IUIGitInstance
        | IUISecretVolumeInstance
        | IUIConfigMapInstance,
    ) {
      if (!ds) {
        return false;
      }
      const containerPathKey = DATA_SOURCE_KIND_TO_CONTAINER_KEY[ds.type as TDataSourceKinds] as keyof typeof ds;
      const containerPath = ds[containerPathKey];

      return this.notEmpty(containerPath || "") !== true || this.isDirectoryPath(containerPath || "") !== true;
    },
    updateNfs(data: IUINfsInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        nfs: this.workloadStorage.nfs?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateHostPath(data: IUIHostPathInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        hostPath: this.workloadStorage.hostPath?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateS3(data: IUIS3Instance) {
      this.$emit("update", {
        ...this.workloadStorage,
        s3: this.workloadStorage.s3?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateGit(data: IUIGitInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        git: this.workloadStorage.git?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateSecretVolume(data: IUISecretVolumeInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        secretVolume: this.workloadStorage.secretVolume?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateConfigMap(data: IUIConfigMapInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        configMapVolume: this.workloadStorage.configMapVolume?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updatePvc(data: IUIPvcInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        pvc: this.workloadStorage.pvc?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    removeStorage(id: string, type: string) {
      const storage = this.workloadStorage[type as keyof IUIStorageVolumesExcludedFields];
      if (!storage) {
        return;
      }
      this.$emit("update", {
        ...this.workloadStorage,
        [type]: storage.filter((ds) => ds.id !== id),
      });
    },
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.PATH_NOT_EMPTY;
    },
    isIncomplete(val: boolean): boolean | string {
      return !val ? true : errorMessages.AT_LEAST_ONE_TARGET_LOCATION_ISSUE;
    },
  },
  watch: {
    workloadStorage: {
      handler() {
        const selectedTab = this.tabs.find((tab) => tab.id === this.selectedId);
        // Selecting the first tab if there is no selected tab
        if (!selectedTab && this.tabs.length > 0) {
          this.selectedId = this.tabs[0]?.id;
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.hybrid-data-source-section {
  .data-source-tabs {
    border-bottom: 1px solid $black-10;
    .q-tabs__arrow {
      font-size: 18px;
    }
    .q-tab__icon {
      font-size: 18px;
    }
  }

  .q-tab {
    position: relative;
    .close-btn {
      color: $black-54;
      font-size: 12px;
      display: none;
    }

    &:hover {
      .close-btn {
        display: block;
        position: absolute;
        right: -12px;
      }
    }
  }
}
</style>
