import type { StringOption } from "@/swagger-models/assets-service-client";
import type { UnifiedPolicyInfoPerReplicaType, UnifiedProjectInfo } from "@/swagger-models/workloads-client";
import type { IPolicyViewDetails } from "@/utils/policy.util/policy-view.util";
import type { IUIEnvrionmentFields, IUIWorkspaceSpec } from "./workload.model";
import type { Project } from "@/swagger-models/org-unit-service-client";
import type { WorkloadCreationDrawerEntities } from "./workload-creation-drawer-model";

export type THybridFormState<T> = {
  projectInfo: UnifiedProjectInfo;
  projectNodePoolsResources: StringOption[];
  selectedTemplates: THybridSelectedTemplates;
  policy: UnifiedPolicyInfoPerReplicaType | undefined | null;
  policyViewDetails: IPolicyViewDetails;
  selectedProject: Project | null;
  selectedProjectNodeTypes: string[];
  workloadModel: T;
};

export type THybridSelectedTemplates = {
  [WorkloadCreationDrawerEntities.Environment]: {
    name: string;
    defaultData: IUIEnvrionmentFields | null;
    clearedState: IUIEnvrionmentFields | null;
    renderKey: number;
  };
  [WorkloadCreationDrawerEntities.DataSource]: {
    name: string;
    defaultData: IUIWorkspaceSpec["storage"] | null;
    clearedState: IUIWorkspaceSpec["storage"] | null;
  };
  [WorkloadCreationDrawerEntities.Compute]: {
    name: string;
    defaultData: IUIWorkspaceSpec["compute"] | null;
    clearedState: IUIWorkspaceSpec["compute"] | null;
  };
};

export const enum ESubmissionOptions {
  Hybrid = "hybrid",
  Assets = "assets",
}

export const hybridSubmissionOptions = [
  { label: "Dynamic (recommended)", value: ESubmissionOptions.Hybrid },
  { label: "Original", value: ESubmissionOptions.Assets },
];
