import NoSsoEmailMapper from "@/views/errors/no-sso-email-mapper.vue";
import ErrorApp from "@/views/errors/error-app.vue";
import { ERROR_ROUTE_NAMES } from "./error.routes.names";
import NotFound from "@/views/errors/not-found.vue";
import ServerError from "@/views/errors/server-error.vue";
import BadGateway from "@/views/errors/bad-gateway.vue";
import NoEula from "@/views/errors/no-eula.vue";
import type { RunaiRouteRecord } from "vue-router";

export const errorRoutes: RunaiRouteRecord[] = [
  {
    path: "/error",
    component: ErrorApp,
    meta: {
      fullPage: true,
      requiresAuth: false,
      minPermittedActions: null,
      resourceType: null,
    },
    children: [
      {
        path: "/error/404",
        component: NotFound,
        name: ERROR_ROUTE_NAMES.NOT_FOUND,
        meta: {
          pageTitle: "404",
          fullPage: true,
          requiresAuth: false,
          minPermittedActions: null,
          resourceType: null,
        },
      },
      {
        path: "/error/502",
        component: BadGateway,
        name: ERROR_ROUTE_NAMES.BAD_GATEWAY_ERROR,
        meta: {
          pageTitle: "502",
          requiresAuth: false,
          fullPage: true,
          minPermittedActions: null,
          resourceType: null,
        },
      },
      {
        path: "/error/503",
        component: ServerError,
        name: ERROR_ROUTE_NAMES.SERVICE_UNAVAILABLE_ERROR,
        meta: {
          pageTitle: "503",
          requiresAuth: false,
          fullPage: true,
          minPermittedActions: null,
          resourceType: null,
        },
      },
      {
        path: "/no-eula",
        component: NoEula,
        name: ERROR_ROUTE_NAMES.NO_EULA,
        meta: {
          pageTitle: "",
          requiresAuth: false,
          fullPage: true,
          minPermittedActions: null,
          resourceType: null,
        },
      },
      {
        path: "/no-sso-email-mapper",
        component: NoSsoEmailMapper,
        name: ERROR_ROUTE_NAMES.NO_SSO_EMAIL_MAPPER,
        meta: {
          requiresAuth: false,
          fullPage: true,
          minPermittedActions: null,
          resourceType: null,
        },
      },
    ],
  },
];
