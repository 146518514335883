<template>
  <chart-widget-wrapper
    class="idle-gpu-workload-widget"
    aid="idle-gpu-workload-widget"
    :options="options"
    :loading="loading"
    :error="error"
    slot-wrapper-class=""
    hide-actions
    @link-clicked="redirectToWorkloads"
  >
    <runai-table
      :rows="workloads"
      :columns="idleGpuWorkloadColumns"
      :filter-by="filterBy"
      :loading="loading"
      disable-selection
      no-shadow
      :hide-pagination="!empty"
      :bordered="false"
    >
      <template #no-data>
        <runai-table-no-data
          :filter-by="filterBy"
          entity-name="workload"
          custom-message="No idle workloads to display"
          :show-create-btn="false"
          :show-filter-icon-and-button="false"
      /></template>
    </runai-table>
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type Ref, ref } from "vue";
import { EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { type Workload, WorkloadSortFilterFields } from "@/swagger-models/workloads-client";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { idleGpuWorkloadColumns, workloadIndexColumns } from "@/table-models/workload.table-model";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { useRouter } from "vue-router";
import { RunaiTable } from "@/components/common";
import type { IFilterBy } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { ETableFilters } from "@/models/table.model";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";
import { RunaiTableNoData } from "@/components/common/runai-table/runai-table-no-data";
import { useRefresh } from "@/composables/use-refresh.composable";
import { EIntervalLabels } from "@/models/interval.model";

const NUMBER_OF_WORKLOADS = 5;
export default defineComponent({
  name: "idle-gpu-workload-widget",
  components: { RunaiTableNoData, ChartWidgetWrapper, RunaiTable },
  setup() {
    const router = useRouter();

    const loading = ref(true);
    const error = ref(false);
    const empty = ref(false);
    const filterBy: Ref<IFilterBy> = ref({ descending: true, sortBy: "idleAllocatedGpus" });
    const workloads: Ref<Workload[]> = ref([]);
    const options: Ref<IWidgetWrapperOptions> = ref({
      linkText: "All workloads",
      title: "Idle workloads",
      entityType: EWidgetEntity.Workload,
      tooltipText: "Workloads with the highest number of idle GPU devices.",
    });

    const getWorkloads = async () => {
      try {
        const response = await workloadService.getWorkloads(
          false,
          undefined,
          NUMBER_OF_WORKLOADS,
          "desc",
          WorkloadSortFilterFields.IdleGpus,
          [filterUtil.getGreaterThanOrEqualFilterString(WorkloadSortFilterFields.IdleGpus, 1)],
        );
        if (response.length === 0) {
          empty.value = true;
        } else {
          workloads.value = response;
        }
      } catch (err) {
        error.value = true;
      } finally {
        loading.value = false;
      }
    };

    const redirectToWorkloads = () => {
      selectIdleGpuColumn();
      router.push({
        name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
        query: { sortBy: "idleAllocatedGpus", descending: "true" },
      });
    };

    const selectIdleGpuColumn = () => {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        WorkloadSortFilterFields.IdleAllocatedGpus,
        workloadIndexColumns,
      );
      const filters = filterService.loadFilters(window.location, ETableFilters.WORKLOAD, defaultFilters);
      if (
        filters.displayedColumns?.length &&
        !filters?.displayedColumns?.includes(WorkloadSortFilterFields.IdleAllocatedGpus)
      ) {
        filters.displayedColumns.push(WorkloadSortFilterFields.IdleAllocatedGpus);
      }
      filterService.saveFilters(ETableFilters.WORKLOAD, filters);
    };

    useRefresh(EIntervalLabels.IdleGpuWorkloadWidget, getWorkloads);

    return {
      options,
      workloads,
      loading,
      idleGpuWorkloadColumns,
      redirectToWorkloads,
      empty,
      error,
      filterBy,
    };
  },
});
</script>

<style lang="scss">
.idle-gpu-workload-widget {
  .q-table__bottom--nodata {
    min-height: 225px;
  }
  .sortable {
    pointer-events: none;
  }
  .runai-table {
    box-shadow: unset;
  }
  .q-table tbody td {
    font-size: 12px;
  }
  .q-table thead tr,
  .q-table tbody td {
    height: 45px;
  }
  .q-td {
    padding: 0 16px;
  }
}
</style>
