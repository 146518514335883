<template>
  <div class="iframe-view" ref="iframeEl">
    <runai-app-loader v-if="loading" />
    <iframe :src="fullUrl" id="runai-old-app" allow="clipboard-read; clipboard-write" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiAppLoader } from "@/components/common/runai-app-loader";

// Stores
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { oldAppService } from "@/services/infra/old-app.service";

// consts
import { POST_MESSAGE_EVENTS } from "@/common/event.constant";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { NODE_ROUTE_NAMES } from "@/router/node.routes/node.routes.names";
import { useClusterStore } from "@/stores/cluster.store";

interface IUpdateRoute {
  location: string;
}

export default defineComponent({
  components: {
    RunaiAppLoader,
  },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      clusterStore: useClusterStore(),
      fullUrl: "" as string,
      tokenListenerId: "" as string,
      refreshTokenListenerId: "" as string,
      iframeLoadedListenerId: "" as string,
      logoutListenerId: "" as string,
      moadalStatusListenerId: "" as string,
      sideNavStateListenerId: "" as string,
      navigateToListenerId: "" as string,
      clusterListenerId: "" as string,
      updateLocationListenerId: "" as string,
      loading: true as boolean,
      iframeEl: undefined as HTMLElement | undefined,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.setupEventListeners();
    this.buildFullUrl();
  },
  mounted() {
    this.iframeEl = this.$refs.iframeEl as HTMLElement;
  },
  methods: {
    setupEventListeners(): void {
      this.tokenListenerId = oldAppService.addListener(POST_MESSAGE_EVENTS.ACCESS_TOKEN, this.sendTokens);
      this.refreshTokenListenerId = oldAppService.addListener(POST_MESSAGE_EVENTS.REFRESH_TOKEN, this.sendRefreshTokens);
      this.logoutListenerId = oldAppService.addListener(POST_MESSAGE_EVENTS.DO_LOGOUT, this.logout);
      this.moadalStatusListenerId = oldAppService.addListener(POST_MESSAGE_EVENTS.MODAL_MODE, this.handleModalMode);
      this.clusterListenerId = oldAppService.addListener(
        POST_MESSAGE_EVENTS.CURRENT_CLUSTER_CHANGED,
        this.handleClusterChanged,
      );
      this.updateLocationListenerId = oldAppService.addListener(
        POST_MESSAGE_EVENTS.UPDATE_LOCATION,
        this.updateLocation,
      );

      this.sideNavStateListenerId = oldAppService.addListener(
        POST_MESSAGE_EVENTS.SIDENAV_STATE,
        this.handleSideNavState,
      );
      this.navigateToListenerId = oldAppService.addListener(POST_MESSAGE_EVENTS.NAVIGATE_TO, this.handleNavigateTo);
      this.iframeLoadedListenerId = oldAppService.addListener(
        POST_MESSAGE_EVENTS.IFRAME_LOADED,
        this.handleIframeLoaded,
      );
    },
    updateLocation(data: IUpdateRoute): void {
      const location: string = data.location;
      window.history.replaceState(window.history.state, "", `${window.location.origin}/${location}`);
    },
    handleIframeLoaded(): void {
      this.loading = false;
    },
    handleModalMode(data: { isModalOpen: boolean }): void {
      data.isModalOpen ? this.iframeEl?.classList.add("modal-open") : this.iframeEl?.classList.remove("modal-open");
    },
    handleSideNavState(): void {
      oldAppService.send(POST_MESSAGE_EVENTS.SIDENAV_STATE, this.appStore.sidebarState);
    },
    handleClusterChanged(data: { clusterId: string }): void {
      const clusterId = data.clusterId;
      this.clusterStore.setSelectedClusterById(clusterId);
    },
    buildFullUrl(): void {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.append("parent-origin", window.location.origin);
      searchParams.append("clusterId", this.clusterStore.selectedClusterId);
      if (this.appStore.isNewNavigationFeatureOn) {
        searchParams.append("new-layout", "true");
      }

      const baseUrl: string = oldAppService.url;

      // If the user is on the jobs page, we want to return to the jobs page after the submit job flow
      if (window.location.pathname === "/jobs") {
        searchParams.append("returnUrl", "/jobs");
      } else {
        searchParams.append("returnUrl", "/workloads");
      }

      this.fullUrl = `${baseUrl}/#${this.$route.path}?${searchParams.toString()}`;
    },
    sendTokens(): void {
      const tokens = {
        accessToken: this.authStore.accessToken,
        refreshToken: this.authStore.refreshToken,
      };

      oldAppService.send(POST_MESSAGE_EVENTS.ACCESS_TOKEN, tokens);
    },
    handleNavigateTo(event: { page: string; name: string }): void {
      if (event.page === "projects") {
        const columnFilters = `[{ "term": "${event.name}", "name": "project-name" }]`;
        this.$router.push({
          name: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
          query: {
            columnFilters,
          },
        });
      } else if (event.page === "nodes") {
        const columnFilters = `[{ "term": "${event.name}", "name": "node" }]`;
        this.$router.push({
          name: NODE_ROUTE_NAMES.NODE_INDEX,
          query: {
            columnFilters,
          },
        });
      } else if (event.page === "workloads") {
        this.$router.push({
          name: "workload-index",
        });
      }
    },
    async sendRefreshTokens(): Promise<void> {
      try {
        await this.authStore.updateAccessToken();
        const tokens = {
          accessToken: this.authStore.accessToken,
          refreshToken: this.authStore.refreshToken,
        };

        oldAppService.send(POST_MESSAGE_EVENTS.REFRESH_TOKEN, tokens);
      } catch (error: unknown) {
        await this.logout();
      }
    },
    async logout(): Promise<void> {
      await this.authStore.logout();
    },
    removeEventListeners(): void {
      oldAppService.removeListener(POST_MESSAGE_EVENTS.ACCESS_TOKEN, this.tokenListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.IFRAME_LOADED, this.iframeLoadedListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.DO_LOGOUT, this.logoutListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.REFRESH_TOKEN, this.refreshTokenListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.MODAL_MODE, this.moadalStatusListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.NAVIGATE_TO, this.navigateToListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.UPDATE_LOCATION, this.updateLocationListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.SIDENAV_STATE, this.sideNavStateListenerId);
      oldAppService.removeListener(POST_MESSAGE_EVENTS.CURRENT_CLUSTER_CHANGED, this.clusterListenerId);
    },
  },
  unmounted() {
    this.removeEventListeners();
  },
});
</script>

<style lang="scss" scoped>
.iframe-view {
  width: 100%;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }

  &.modal-open {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 11500;
  }
}
</style>
