<template>
  <section class="item-details">
    <div
      v-for="(detail, idx) in item.details"
      class="q-pa-md"
      :class="detail.noSeperator ? '' : 'border-bottom'"
      :key="detail.title + idx"
    >
      <div class="row items-center q-mb-sm">
        <runai-svg-icon v-if="detail.icon" :name="detail.icon" size="20px" class="q-mr-sm" />
        <span class="details-title">{{ detail.title }}</span>
      </div>
      <template v-for="(text, index) in detail.content" :key="text + index">
        <div v-if="renderSpace(text)" aid="spacing-div" class="q-mb-sm" />
        <div v-else class="content-text" :class="index && detail.spaceContent ? 'q-mt-sm' : ''">
          {{ text }}
        </div>
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import type { IDrawerListItem, WorkloadCreationDrawerEntities } from "@/models/workload-creation-drawer-model";
import { LINE_BREAK } from "@/utils/workload-hybrid.util/workload-hybrid.util";
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

export default defineComponent({
  name: "item-details",
  components: {
    RunaiSvgIcon,
  },
  props: {
    item: {
      type: Object as PropType<IDrawerListItem>,
      required: true,
    },
    entity: {
      type: [String, null] as PropType<WorkloadCreationDrawerEntities | null>,
      required: true,
    },
  },
  methods: {
    renderSpace(text: string) {
      return text === LINE_BREAK;
    },
  },
});
</script>
<style lang="scss" scoped>
.item-details {
  .border-bottom {
    border-bottom: 1px solid $black-12;
  }
  .content-text {
    font-size: 12px;
    font-weight: 400;
  }
  .details-title {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
