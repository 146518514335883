export enum EEventStatus {
  Succeeded = "Succeeded",
  Failed = "Failed",
}
export type AuditEventSortBy =
  | "timestamp"
  | "subject"
  | "subject_type"
  | "source_ip"
  | "action"
  | "http_method"
  | "result"
  | "url"
  | "entity_type"
  | "entity_name"
  | "entity_id";
