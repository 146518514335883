<template>
  <section class="resource-boxes-section column no-wrap">
    <section class="row no-wrap gpu-title-row">
      <div class="q-mr-sm q-py-xs flex-1">
        <q-chip square color="grey-7" class="self-start q-px-lg q-ma-none text-white">GPU</q-chip>
      </div>
      <div class="info-title">Based on the current setup, when the workload is scheduled:</div>
    </section>

    <gpu-devices-box
      :gpu-devices-request="resourcesModel.gpuDevicesRequest"
      :policy-rules="policyRules?.gpuDevicesRequest"
      @update-gpu-devices-request="updateGpuDevicesRequest"
      :texts="devicesInfoList"
    />

    <gpu-resource-box
      :disable="isGpuRequestDisabled"
      :gpu-request="gpuRequestModel"
      @update-gpu-request="updateGpuRequest"
      @section-invalid="invalidSection.gpu = $event"
      :texts="gpuRequestInfoList"
      :policy-rules="policyRules"
    />

    <section class="row">
      <div class="col-9 q-mr-sm q-py-xs">
        <q-chip square color="grey-7" class="self-start q-px-lg q-ma-none text-white">CPU</q-chip>
      </div>
    </section>

    <cpu-resource-box
      :cpu-core="resourcesModel.cpuCore"
      @cpu-core-changed="updateCpuCoreRequest"
      @is-section-invalid="invalidSection.cpu = $event"
      class="cpu-box-container"
      :policy-rules="policyRules"
      :texts="cpuInfo"
    />

    <memory-resource-box
      :cpu-memory="resourcesModel.cpuMemory"
      @cpu-memory-changed="updateCpuMemoryRequest"
      @is-section-invalid="invalidSection.cpuMemory = $event"
      class="memory-box-container"
      :policy-rules="policyRules"
      :texts="memoryInfo"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ICpuCore, ICpuMemory, IGpuRequestModel, IResourcesSectionModel } from "@/models/compute-resource.model";
// Components
import { GpuDevicesBox } from "../gpu-devices-box";
import { GpuResourceBox } from "../gpu-resource-box";
import { CpuResourceBox } from "../cpu-resource-box";
import { MemoryResourceBox } from "../memory-resource-box";

import { is } from "quasar";
import { GpuRequestType } from "@/swagger-models/assets-service-client";
import { type ComputeFieldsRules } from "@/swagger-models/workloads-client";
// Models

interface ISectionValidation {
  gpu: boolean;
  cpu: boolean;
  cpuMemory: boolean;
}

const limitOnInfoList = [
  "The workload may be killed/stopped if the used GPU memory exceeds the request",
  "The greater the difference between the GPU memory used and the request, the higher the risk of being killed/stopped",
];
const GPU_PORTION_FACTOR = 100;
const noQuotaInfo = "GPU quota will not be affected when the workload is scheduled";

export default defineComponent({
  name: "resource-boxes-section",
  components: {
    GpuDevicesBox,
    GpuResourceBox,
    CpuResourceBox,
    MemoryResourceBox,
  },
  emits: ["update-resources-model", "is-section-invalid"],
  props: {
    resourcesModel: {
      type: Object as PropType<IResourcesSectionModel>,
      required: true,
    },
    isAmdGpuType: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    supportMultiFractionGpu: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    policyRules: {
      type: [Object, null] as PropType<ComputeFieldsRules | null>,
      required: false,
    },
  },
  data() {
    return {
      isCpuCoreManuallySet: false,
      isCpuMemoryManuallySet: false,
      invalidSection: {
        gpu: false,
        cpu: false,
        cpuMemory: false,
      } as ISectionValidation,
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return this.invalidSection.gpu || this.invalidSection.cpu || this.invalidSection.cpuMemory;
    },
    isGpuRequestDisabled(): boolean {
      return (
        this.gpuRequestModel.gpuDevicesRequest <= 0 ||
        this.isAmdGpuType ||
        (!this.supportMultiFractionGpu && this.gpuRequestModel.gpuDevicesRequest > 1)
      );
    },
    gpuRequestModel(): IGpuRequestModel {
      return {
        gpuDevicesRequest: this.resourcesModel.gpuDevicesRequest,
        gpuRequestType: this.resourcesModel.gpuRequestType,
        gpuPortionRequest: this.resourcesModel.gpuPortionRequest,
        gpuPortionLimit: this.resourcesModel.gpuPortionLimit,
        gpuMemoryRequest: this.resourcesModel.gpuMemoryRequest,
        gpuMemoryLimit: this.resourcesModel.gpuMemoryLimit,
        migProfile: this.resourcesModel.migProfile,
      };
    },
    gpuRequestInfoList(): Array<string> {
      if (
        (!this.supportMultiFractionGpu && this.resourcesModel.gpuDevicesRequest > 1) ||
        this.resourcesModel.gpuDevicesRequest <= 0
      ) {
        return ["GPU memory is available when a single GPU device is set"];
      }
      if (this.resourcesModel.gpuRequestType === GpuRequestType.Portion) {
        return this.gpuPortionInfoList;
      }
      if (this.resourcesModel.gpuRequestType === GpuRequestType.Memory) {
        return this.gpuMemoryInfoList;
      }
      return [];
    },
    gpuPortionInfoList(): Array<string> {
      const infoList = [];
      if (this.resourcesModel.gpuPortionRequest === 0) {
        infoList.push(noQuotaInfo);
      }
      if (this.resourcesModel.gpuPortionLimit === null || this.resourcesModel.gpuPortionLimit === undefined) {
        if (this.resourcesModel.gpuPortionRequest && this.resourcesModel.gpuPortionRequest > 0) {
          const gpuRequest = (this.resourcesModel.gpuPortionRequest * GPU_PORTION_FACTOR).toFixed(0);
          infoList.push(`The workload will allocate ${gpuRequest}% of the GPU memory`);
        }
      } else {
        if (this.resourcesModel.gpuPortionRequest || this.resourcesModel.gpuPortionRequest === 0) {
          const gpuRequest = (this.resourcesModel.gpuPortionRequest * GPU_PORTION_FACTOR).toFixed(0);
          const gpuLimit = (this.resourcesModel.gpuPortionLimit * GPU_PORTION_FACTOR).toFixed(0);
          infoList.push(
            `The workload will allocate ${gpuRequest}% of the GPU memory and can use up to ${gpuLimit}% if available`,
            ...limitOnInfoList,
          );
        }
      }
      return infoList;
    },
    gpuMemoryInfoList(): Array<string> {
      const infoList: Array<string> = [];
      if (this.resourcesModel.gpuMemoryRequest === null || this.resourcesModel.gpuMemoryRequest === undefined) {
        return [];
      }
      const gpuMemoryRequest = parseInt(this.resourcesModel.gpuMemoryRequest);
      if (gpuMemoryRequest === 0) {
        infoList.push(noQuotaInfo);
      }

      if (this.resourcesModel.gpuMemoryLimit === null || this.resourcesModel.gpuMemoryLimit === undefined) {
        if (gpuMemoryRequest && gpuMemoryRequest > 0) {
          infoList.push(`The workload will allocate ${this.resourcesModel.gpuMemoryRequest} of the GPU memory`);
        }
      } else {
        if (gpuMemoryRequest || gpuMemoryRequest === 0) {
          infoList.push(
            `The workload will allocate ${this.resourcesModel.gpuMemoryRequest} of the GPU memory and can use up to ${this.resourcesModel.gpuMemoryLimit} if available`,
            ...limitOnInfoList,
          );
        }
      }
      return infoList;
    },
    devicesInfoList(): Array<string> {
      if (!this.resourcesModel.gpuDevicesRequest) return ["GPU resources will not be consumed"];
      return [`The workload will use ${this.resourcesModel.gpuDevicesRequest} GPU devices per pod`];
    },
    cpuInfo(): Array<string> {
      const cpuCore: ICpuCore = this.resourcesModel.cpuCore;

      if (cpuCore.request === 0) {
        if (is.number(cpuCore.limit)) {
          return [
            "CPU quota will not be affected",
            "The scheduler will try to find free CPU resources but can't guarantee it",
          ];
        } else if (cpuCore.limit === undefined) {
          // limit is disabled
          return [
            "CPU quota will not be affected",
            "The scheduler will try to find free CPU resources but can't guarantee it",
            "The workload may consume all the node's free CPU resources",
          ];
        } else {
          // when limit is enabled but not set the value is null
          return [
            "CPU quota will not be affected",
            "The scheduler will try to find free CPU resources but can't guarantee it",
          ];
        }
      }

      // request > 0
      if (!is.number(this.resourcesModel.cpuCore.limit)) {
        if (this.resourcesModel.cpuCore.limit === null) return []; // limit is enabled but not set
        return ["The workload may consume all the node's free CPU resources"]; // limit is disabled
      }

      // request > 0 and limit > 0
      return [];
    },
    memoryInfo(): Array<string> {
      const request: number = this.getValueFromValueAndUnit(this.resourcesModel.cpuMemory.request);
      if (request === 0) {
        if (this.resourcesModel.cpuMemory.limit) {
          return [
            "CPU memory quota will not be affected",
            "The scheduler will try to find free CPU memory resources but can't guarantee it",
            "The workload may be killed if the CPU memory used exceeds the request",
            "The greater the difference between the CPU memory used and the request, the higher the risk of being killed",
            "If the workload uses more CPU memory than the limit, it will be killed",
          ];
        } else if (this.resourcesModel.cpuMemory.limit === undefined) {
          // limit is disabled
          return [
            "CPU memory quota will not be affected",
            "The scheduler will try to find free CPU memory resources but can't guarantee it",
            "The workload may consume all the node's free CPU memory resources",
            "The workload may be killed if the CPU memory used exceeds the request",
            "The greater the difference between the CPU memory used and the request, the higher the risk of being killed",
          ];
        } else {
          // limit is empty (null)
          return [
            "CPU memory quota will not be affected",
            "The scheduler will try to find free CPU memory resources but can't guarantee it",
          ];
        }
      }

      // request > 0
      if (this.resourcesModel.cpuMemory.limit === null) {
        // limit is enabled but there is no value
        return [];
      }

      if (this.resourcesModel.cpuMemory.limit === undefined) {
        // limit disabled
        return [
          "The workload may consume all the node's free CPU memory resources",
          "The workload may be killed if the CPU memory used exceeds the request",
          "The greater the difference between the CPU memory used and the request, the higher the risk of being killed",
        ];
      }

      // request > 0 && limit is not undefiend or null
      const limit: number = this.getValueFromValueAndUnit(this.resourcesModel.cpuMemory.limit);
      if (request === limit) {
        return ["If the workload uses more CPU memory than the limit, it will be killed"];
      } else {
        return [
          "The workload may be killed if the CPU memory used exceeds the request",
          "The greater the difference between the CPU memory used and the request, the higher the risk of being killed",
          "If the workload uses more CPU memory than the limit, it will be killed",
        ];
      }
    },
  },
  methods: {
    updateGpuDevicesRequest(gpuDevicesRequest: number): void {
      if (gpuDevicesRequest === 0 || (!this.supportMultiFractionGpu && gpuDevicesRequest > 1)) {
        const cpuCore = {
          request: Number((gpuDevicesRequest * 0.1).toFixed(2)),
          limit: is.number(this.resourcesModel.cpuCore.limit) ? Number((gpuDevicesRequest * 0.1).toFixed(2)) : null,
        };
        const cpuMemory = {
          request: gpuDevicesRequest * 100 + "M",
          limit: this.resourcesModel.cpuMemory.limit ? gpuDevicesRequest * 100 + "M" : null,
        };
        this.$emit("update-resources-model", {
          ...this.resourcesModel,
          gpuDevicesRequest,
          cpuCore: this.isCpuCoreManuallySet ? this.resourcesModel.cpuCore : cpuCore,
          cpuMemory: this.isCpuMemoryManuallySet ? this.resourcesModel.cpuMemory : cpuMemory,
          gpuRequestType: undefined,
          gpuPortionRequest: undefined,
          gpuMemoryRequest: undefined,
          gpuPortionLimit: undefined,
          gpuMemoryLimit: undefined,
        });
        return;
      }
      if (!this.resourcesModel.gpuRequestType) {
        this.$emit("update-resources-model", {
          ...this.resourcesModel,
          gpuRequestType: GpuRequestType.Portion,
          gpuPortionRequest: 0,
          gpuDevicesRequest,
        });
        return;
      }

      this.$emit("update-resources-model", {
        ...this.resourcesModel,
        gpuDevicesRequest,
      });
    },
    updateGpuRequest(gpuRequest: IGpuRequestModel): void {
      if (gpuRequest.gpuRequestType !== GpuRequestType.Portion) {
        const cpuCoreDefaultNumber = 0.1;
        const cpuMemoryDefaultNumber = "100M";
        const cpuCore = {
          request: cpuCoreDefaultNumber,
          limit: is.number(this.resourcesModel.cpuCore.limit) ? cpuCoreDefaultNumber : null,
        };

        const cpuMemory = {
          request: cpuMemoryDefaultNumber,
          limit: this.resourcesModel.cpuMemory.limit ? cpuMemoryDefaultNumber : null,
        };
        this.$emit("update-resources-model", {
          ...this.resourcesModel,
          ...gpuRequest,
          cpuCore: this.isCpuCoreManuallySet ? this.resourcesModel.cpuCore : cpuCore,
          cpuMemory: this.isCpuMemoryManuallySet ? this.resourcesModel.cpuMemory : cpuMemory,
        });
        return;
      } else if (gpuRequest.gpuPortionRequest || gpuRequest.gpuPortionRequest === 0) {
        const cpuCore = {
          request: Number((gpuRequest.gpuPortionRequest * 0.1).toFixed(2)),
          limit: is.number(this.resourcesModel.cpuCore.limit)
            ? Number((gpuRequest.gpuPortionRequest * 0.1).toFixed(2))
            : null,
        };
        const cpuMemory = {
          request: (gpuRequest.gpuPortionRequest * GPU_PORTION_FACTOR).toFixed(0) + "M",
          limit: this.resourcesModel.cpuMemory.limit
            ? (gpuRequest.gpuPortionRequest * GPU_PORTION_FACTOR).toFixed(0) + "M"
            : null,
        };
        this.$emit("update-resources-model", {
          ...this.resourcesModel,
          ...gpuRequest,
          cpuCore: this.isCpuCoreManuallySet ? this.resourcesModel.cpuCore : cpuCore,
          cpuMemory: this.isCpuMemoryManuallySet ? this.resourcesModel.cpuMemory : cpuMemory,
        });
        return;
      }
      this.$emit("update-resources-model", { ...this.resourcesModel, ...gpuRequest });
    },
    getValueFromValueAndUnit(val: string): number {
      return Number(val.slice(0, val.length - 1));
    },
    updateCpuCoreRequest(cpuCore: ICpuCore): void {
      this.isCpuCoreManuallySet = true;
      this.updateResourcesModel({ ...this.resourcesModel, cpuCore });
    },
    updateCpuMemoryRequest(cpuMemory: ICpuMemory): void {
      this.isCpuMemoryManuallySet = true;
      this.updateResourcesModel({ ...this.resourcesModel, cpuMemory });
    },
    updateResourcesModel(updatedResources: IResourcesSectionModel): void {
      this.$emit("update-resources-model", updatedResources);
    },
  },
  watch: {
    sectionInvalid: {
      handler() {
        this.$emit("is-section-invalid", this.sectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.resource-boxes-section {
  .info-title {
    font-weight: 700;
    font-size: 11px;
    padding-left: 9px;
    width: 225px;
  }
}
</style>
