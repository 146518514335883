export enum EFeatureToggle {
  /*** General ***/
  ShowNewLayout = "release-19405-show-new-ui-navigation",
  /*** Phoenix ***/
  /*** Lemur ***/
  ShowNimInference = "release-RUN-21924-show-nim-for-inference",
  ShowServingEndpointAccessInference = "release-RUN-22019-show-serving-endpoint-inference",
  ShowPolicyForInference = "release-23112-show-policy-for-inference",
  ShowCredentialsForHFInference = "release-23112-show-credentials-for-hf-inference",
  /*** Viper ***/
  ShowUpdateAssetEnabled = "release-23268-show-asset-editing",
  ShowMigProfilesDisabled = "release-19264-show-mig-profile-deprecation",
  ShowHybridSubmission = "release-19225-show-hybrid-submission-form",
  /*** Suricata ***/
}
