import * as amplitude from "@amplitude/analytics-browser";
import { encodeUtil } from "@/utils/encode.util/encode.util";
import { getEnvType, EnvType } from "@/utils/infra.util/infra.util";
import type { EAmplitudeEvent } from "@/models/amplitude.model";
import type { EventOptions } from "@amplitude/analytics-types";
const apiKey = import.meta.env.VITE_APP_AMPLITUDE;

class Amplitude {
  async init(username: string): Promise<void> {
    const envName: string | undefined = getEnvironment(window.location.hostname);

    if (!envName || !apiKey) return;

    const encodedUser = await encodeUtil.toSha256(username);
    amplitude.init(apiKey, { autocapture: true });

    const identifyObj = new amplitude.Identify();
    identifyObj.set("tenant", window.location.hostname.split(".")[0]);
    identifyObj.set("user", encodedUser);
    identifyObj.set("cp", "runai-saas");
    identifyObj.set("env", envName);

    amplitude.identify(identifyObj);
  }

  trackEvent(
    eventName: EAmplitudeEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties?: Record<string, any> | undefined,
    eventOptions?: EventOptions | undefined,
  ): void {
    amplitude.track(eventName, eventProperties, eventOptions);
  }
}

function getEnvironment(hostname: string): string | undefined {
  const envType = getEnvType(hostname);
  return "test";
  // test/staging should be before the prod check because it's a subset of the prod regex
  switch (envType) {
    case EnvType.TEST:
      return undefined;
    case EnvType.STAGING:
      return "staging";
    case EnvType.PROD:
      return "prod";
    default:
      return undefined;
  }
}
export const amplitudeService = new Amplitude();
