<template>
  <runai-expansion-item
    class="user-apps-container"
    label="Applications"
    :subheader="applicationsSubheader"
    default-opened
  >
    <runai-section
      title="Create applications for quick access to the Run:ai API"
      tooltip-text="Applications (classic) work like ordinary OAuth tokens and are used to authenticate to the Run:ai API. You can create up to 20."
    >
      <user-app-box
        class="q-mb-md"
        v-for="userApp in userApps"
        :key="userApp.id"
        :user-app="userApp"
        @remove-user-app="openDeleteModal(userApp)"
        @regenerate-password="regeneratePassword(userApp)"
      />
      <div class="row items-center q-mb-md">
        <q-btn
          label="+ application"
          flat
          color="primary"
          @click="addApplication"
          aid="aidAddApplication"
          :disable="disableAddApplication"
        />
        <q-tooltip v-if="disableAddApplication"
          >You can create up to 20 applications. To add more, first delete some.</q-tooltip
        >
      </div>
      <runai-delete-modal
        v-if="isDeleteModalOpen && selectedApplication"
        :entity-name="selectedApplication.name"
        entity-type="application"
        @cancel="closeDeleteModal"
        @delete="removeApplication"
        :deleting="deleting"
      >
        <template v-slot:description>
          <div>This can't be undone.</div>
        </template>
      </runai-delete-modal>

      <applications-creation-modal
        v-if="isCreationModalOpen"
        :action-type="modalActionType"
        :model-value="isCreationModalOpen"
        :application="selectedApplication"
        @close="closeCreationModal"
        is-user-app
        @load-applications="getUserApplications"
      />
    </runai-section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// types
import type { UserApp } from "@/swagger-models/identity-manager-client";
import type { TActionTypes } from "@/components/applications/applications-creation-modal/applications-creation-modal.vue";

// service
import { userApplicationsService } from "@/services/control-plane/user-applications.service/user-applications.service";

// components
import { RunaiDeleteModal } from "@/components/common";
import { ApplicationsCreationModal } from "@/components/applications/applications-creation-modal";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import RunaiSection from "@/components/common/runai-section/runai-section.vue";
import UserAppBox from "@/components/user-settings/sections/user-apps-section/user-app-box/user-app-box.vue";

// utils
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  name: "user-apps-section",
  components: {
    UserAppBox,
    RunaiSection,
    ApplicationsCreationModal,
    RunaiDeleteModal,
    RunaiExpansionItem,
  },
  data() {
    return {
      isCreationModalOpen: false,
      isDeleteModalOpen: false,
      selectedApplication: undefined as UserApp | undefined,
      deleting: false,
      userApps: [] as UserApp[],
    };
  },
  async created() {
    await this.getUserApplications();
  },
  computed: {
    applicationsSubheader(): string {
      const appCounts = this.userApps.length;
      return this.userApps.length == 0 ? "None" : appCounts == 1 ? "1 application" : `${appCounts} applications`;
    },
    disableAddApplication(): boolean {
      return this.userApps.length >= 20;
    },
    modalActionType(): TActionTypes {
      const isAppSelected = !!this.selectedApplication;
      return !isAppSelected ? "create" : "regenerate";
    },
  },
  methods: {
    addApplication(): void {
      this.selectedApplication = undefined;
      this.isCreationModalOpen = true;
    },
    async getUserApplications(): Promise<void> {
      this.selectedApplication = undefined;
      this.userApps = await userApplicationsService.getUserApps();
    },
    async removeApplication(): Promise<void> {
      if (!this.selectedApplication) {
        return;
      }
      this.deleting = true;
      try {
        await userApplicationsService.removeUserApp(this.selectedApplication?.id);
        this.$q.notify(alertUtil.getSuccess(`Application ${this.selectedApplication.name} deleted`));
        await this.getUserApplications();
      } catch (error: unknown) {
        console.error("Failed to delete application", error);
        this.$q.notify(alertUtil.getError("Failed to delete application"));
      } finally {
        this.deleting = false;
        this.isDeleteModalOpen = false;
      }
    },
    async openDeleteModal(userApp: UserApp): Promise<void> {
      this.selectedApplication = userApp;
      this.isDeleteModalOpen = true;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
      this.deleting = false;
    },
    async closeCreationModal() {
      await this.getUserApplications();
      this.isCreationModalOpen = false;
    },
    regeneratePassword(userApp: UserApp): void {
      this.selectedApplication = userApp;
      this.isCreationModalOpen = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.user-apps-container {
  margin: auto;
  width: 800px;
  .icon-letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: $white;
  }
  .avatar {
    background-color: $black-30;
    border-radius: 50%;
    font-style: normal;

    &.new-background {
      background: $light-navy-70;
    }
  }
}
</style>
