import { ECustomCell, type ITableColumn } from "@/models/table.model";
import type { IUser } from "@/models/user.model";
import { formatDate } from "@/utils/table-format.util";
import { UsersFilterSortFields } from "@/swagger-models/identity-manager-client";
import { EColumnFilterType, EFilterOperator } from "@/models/filter.model";
import type { IFreeTextFilterModel } from "@/models/filter.model";

export const allUsersColumnsMap: Record<string, ITableColumn> = {
  user: {
    name: UsersFilterSortFields.Username,
    label: "User",
    field: (row: IUser) => row.username,
    sortable: true,
    align: "left",
    display: true,
    filter: {
      type: EColumnFilterType.FreeText,
      excludeOperators: [EFilterOperator.DoesNotContain, EFilterOperator.Equals, EFilterOperator.NotEquals],
    } as IFreeTextFilterModel,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row: IUser) => row.isLocal,
    align: "left",
    display: true,
    format: (isLocal) => (isLocal === null ? "-" : isLocal ? "Local" : "SSO"),
    sortable: true,
    filter: {
      type: EColumnFilterType.EnumBased,
      selectOptions: [
        {
          label: "Local",
          value: true,
        },
        {
          label: "SSO",
          value: false,
        },
      ],
    },
  },
  lastLogin: {
    name: UsersFilterSortFields.LastLogin,
    label: "Last login",
    field: (row: IUser) => row.lastLogin,
    sortable: true,
    align: "left",
    display: true,
    format: (lastLogin) => formatDate(lastLogin),
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  accessRules: {
    name: "accessRules",
    label: "Access rule(s)",
    field: () => "View",
    sortable: false,
    align: "left",
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
    hideFilter: true,
    hideExport: true,
  },
  createdBy: {
    name: UsersFilterSortFields.CreatedBy,
    label: "Created by",
    field: (row: IUser) => row.createdBy,
    sortable: true,
    align: "left",
    display: true,
    format: (createdBy) => createdBy || "-",
    filter: {
      type: EColumnFilterType.FreeText,
      excludeOperators: [EFilterOperator.DoesNotContain, EFilterOperator.Equals, EFilterOperator.NotEquals],
    } as IFreeTextFilterModel,
  },
  creationTime: {
    name: UsersFilterSortFields.CreationTime,
    label: "Creation time",
    field: (row: IUser) => row.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (creationTime) => formatDate(creationTime),
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  lastUpdated: {
    name: UsersFilterSortFields.LastUpdated,
    label: "Last updated",
    field: (row: IUser) => row.updatedAt,
    sortable: true,
    align: "left",
    display: true,
    format: (updatedAt) => formatDate(updatedAt) || "-",
    filter: {
      type: EColumnFilterType.Date,
    },
  },
};

export const allUserColumns: Array<ITableColumn> = [
  allUsersColumnsMap.user,
  allUsersColumnsMap.type,
  allUsersColumnsMap.lastLogin,
  allUsersColumnsMap.accessRules,
  allUsersColumnsMap.createdBy,
  allUsersColumnsMap.creationTime,
  allUsersColumnsMap.lastUpdated,
];

export const usersIndexColumns: Array<ITableColumn> = [
  { ...allUsersColumnsMap.user, display: true, mandatory: true },
  { ...allUsersColumnsMap.type, display: true },
  { ...allUsersColumnsMap.lastLogin, display: true },
  { ...allUsersColumnsMap.accessRules, display: true },
  { ...allUsersColumnsMap.createdBy, display: true },
  { ...allUsersColumnsMap.creationTime, display: true },
  { ...allUsersColumnsMap.lastUpdated, display: true },
];

export const usersDependentColumns = {
  accessRules: new Set([allUsersColumnsMap.accessRules.name]),
};
