<template>
  <section class="row no-wrap items-center">
    <q-expansion-item
      :label="label"
      class="runai-folding-sub-section"
      expand-icon-class="runai-expansion-icon"
      expand-separator
      expand-icon-toggle
      :model-value="isOpen"
      :header-class="headerClass"
      hide-expand-icon
    >
      <template #header>
        <div class="runai-folding-sub-section-header" @click="isOpen = !isOpen" :aid="aid">
          <span class="q-mr-md">{{ label }}</span>
          <q-icon
            class="q-mr-md folding-icon"
            :class="isOpen ? 'open' : ''"
            name="far fa-chevron-down"
            color="primary"
            size="16px"
          />
          <q-icon v-if="hasError" name="fa-regular fa-circle-exclamation" color="red" size="18px" />
        </div>
      </template>
      <q-form class="q-mb-md" greedy ref="formRef" @vue:updated="updateRenderKey" @validation-error="updateRenderKey">
        <slot />
      </q-form>
    </q-expansion-item>
  </section>
</template>

<script lang="ts">
import type { PropType, Ref } from "vue";
import { defineComponent, ref } from "vue";
import type { QField, QForm, QFormChildComponent } from "quasar";
import { useFormChild } from "quasar";

export default defineComponent({
  name: "runai-folding-sub-section",
  emits: ["validation-state-changed"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    defaultOpened: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    aid: {
      type: String as PropType<string>,
      required: false,
    },
    headerClass: {
      type: String as PropType<string>,
      default: "no-padding",
    },
  },
  data() {
    return {
      isOpen: !!this.defaultOpened,
      renderKey: 1000,
    };
  },
  setup() {
    const formRef: Ref<QForm | null> = ref(null);

    const validate = async () => {
      return await (formRef.value as QForm)?.validate();
    };

    useFormChild({
      validate,
    });

    return { formRef };
  },
  computed: {
    hasError(): boolean {
      this.renderKey; // DO NOT REMOVE THIS LINE - it's critical to trigger reactivity when new components are dynamically added/removed to the QForm
      return !!(this.$refs.formRef as QForm)?.getValidationComponents().some((c: QFormChildComponent) => {
        return (c as QField).hasError;
      });
    },
  },
  methods: {
    updateRenderKey() {
      this.renderKey++;
    },
  },
});
</script>

<style lang="scss">
.runai-folding-sub-section {
  width: 100%;

  .runai-folding-sub-section-header {
    font-weight: 500;
    color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;

    .folding-icon {
      transform: rotate(0deg);
      transition: all 0.3s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
