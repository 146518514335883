import { ETableFilters, type ITableColumn } from "@/models/table.model";
import type { IPolicy } from "@/stores/policy.store";
import type {
  ComputeAsset,
  CredentialsListResponseEntry,
  DatasourceListResponseEntry,
  EnvironmentAsset,
  WorkloadTemplate,
} from "@/swagger-models/assets-service-client";
import type { IDepartmentTable } from "@/models/department.model";
import { EDepartmentColumnName } from "@/models/department.model";
import { EProjectColumnName, type IProjectTable } from "@/models/project.model";
import { EIntervalLabels } from "@/models/interval.model";
import { ENodeColumnName } from "@/table-models/node.table-model";
import { departmentIndexColumns } from "@/table-models/department.table-model";
import { projectIndexColumns } from "@/table-models/project.table-model";
import { auditIndexColumns } from "@/table-models/audit.table-model";
import type { AuditLog } from "@/swagger-models/audit-service-client";
import type { User } from "@/swagger-models/backend-client";
import { allUserColumns } from "@/table-models/user.table-model";
import { UsersFilterSortFields } from "@/swagger-models/identity-manager-client";
import type { IApplication } from "@/models/applications.model";
import { type INodePoolTable, nodePoolIndexColumns } from "@/table-models/node-pool.table-model";
import type { DisplayedCluster } from "@/swagger-models/cluster-service-client";
import { ReportFilterAndSortFields } from "@/swagger-models/metrics-service-client";
import type { IReportTable } from "@/table-models/report.table-model";
import { reportIndexColumns } from "@/table-models/report.table-model";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { type AccessRule, AccessRulesSortFilterFields } from "@/swagger-models/authorization-client";
import { accessRulesIndexColumns } from "@/table-models/access-rule.table-model";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { NodepoolSortFilterFields } from "@/swagger-models/cluster-service-client";

interface IIndexTableInfo {
  storageFilterKey: ETableFilters;
  sortByDefault: string;
  /**
   * Text representing the entity type for error messages and notifications.
   */
  entityText: string;
  intervalLabel?: EIntervalLabels;
  intervalLabelPostCreation?: EIntervalLabels;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEntityId: (e: any) => string;
  /**
   * Flag to indicate if the table is in tenant scope. if true the default filers exclude the cluster filter.
   */
  isTenantScope?: boolean;
}

interface IAdvancedIndexTableInfo extends IIndexTableInfo {
  /**
   * Array of all entity columns.
   * Required to enable local search functionality.
   */
  allEntityColumns: Array<ITableColumn>;
  /**
   * Function to get the entity count.
   */
  getEntityCount?: (filterBy?: Array<string>, search?: string) => Promise<number>;
  /**
   * Array of valid filter fields.
   * Used in mapColumnsFilterToFilterParams
   */
  validFilterFields?: string[];
  /**
   * Flag to determine if the page use server side search.
   */
  isAdvancedSearch?: boolean;
}

export enum EIndexPages {
  COMPUTE_RESOURCE = "computeResource",
  DATA_SOURCE = "dataSource",
  ENVIRONMENT = "environment",
  CREDENTIAL = "credential",
  WORKLOAD_TEMPLATE = "workloadTemplate",
  POLICY = "policy",
  NODE = "node",
  CLUSTER = "cluster",
  APPLICATION = "application",
}

export enum EAdvancedIndexPages {
  DEPARTMENT = "department",
  PROJECT = "project",
  AUDIT = "audit",
  USER = "user",
  NODE_POOL = "node-pool",
  REPORT = "report",
  ACCESS_RULE = "access-rule",
}

export const useTableDataIndexMapper: Record<EIndexPages, IIndexTableInfo> = {
  [EIndexPages.COMPUTE_RESOURCE]: {
    storageFilterKey: ETableFilters.COMPUTE_RESOURCE,
    sortByDefault: "compute-resource",
    entityText: "compute resources",
    getEntityId: (e: ComputeAsset) => e.meta?.id || "",
  },
  [EIndexPages.DATA_SOURCE]: {
    storageFilterKey: ETableFilters.DATA_SOURCE,
    sortByDefault: "data-source",
    entityText: "data sources",
    getEntityId: (e: DatasourceListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.DataSourcesIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.ENVIRONMENT]: {
    storageFilterKey: ETableFilters.ENVIRONMENT,
    sortByDefault: "environment",
    entityText: "environments",
    getEntityId: (e: EnvironmentAsset) => e.meta?.id || "",
  },
  [EIndexPages.CREDENTIAL]: {
    storageFilterKey: ETableFilters.CREDENTIAL,
    sortByDefault: "credentials",
    entityText: "credentials",
    getEntityId: (e: CredentialsListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.CredentialsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.WORKLOAD_TEMPLATE]: {
    storageFilterKey: ETableFilters.TEMPLATE,
    sortByDefault: "template",
    entityText: "templates",
    getEntityId: (e: WorkloadTemplate) => e.meta?.id || "",
  },
  [EIndexPages.POLICY]: {
    storageFilterKey: ETableFilters.POLICY,
    sortByDefault: "policy",
    entityText: "policies",
    getEntityId: (e: IPolicy) => e.policyId || "",
    intervalLabel: EIntervalLabels.PolicyIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.NODE]: {
    storageFilterKey: ETableFilters.NODE,
    sortByDefault: ENodeColumnName.Node,
    entityText: "nodes",
    getEntityId: () => "",
    intervalLabel: EIntervalLabels.NodeIndex,
  },
  [EIndexPages.CLUSTER]: {
    storageFilterKey: ETableFilters.CLUSTER,
    sortByDefault: "name",
    entityText: "clusters",
    getEntityId: (cluster: DisplayedCluster) => cluster.uuid || "",
    intervalLabel: EIntervalLabels.ClusterIndex,
  },
  [EIndexPages.APPLICATION]: {
    storageFilterKey: ETableFilters.APPLICATION,
    sortByDefault: "name",
    entityText: "applications",
    getEntityId: (application: IApplication) => application.id || "",
    isTenantScope: true,
  },
};

export const useAdvancedTableDataIndexMapper: Record<EAdvancedIndexPages, IAdvancedIndexTableInfo> = {
  [EAdvancedIndexPages.DEPARTMENT]: {
    storageFilterKey: ETableFilters.DEPARTMENT,
    sortByDefault: EDepartmentColumnName.DepartmentName,
    entityText: "departments",
    getEntityId: (department: IDepartmentTable) => department.id,
    allEntityColumns: departmentIndexColumns,
  },
  [EAdvancedIndexPages.PROJECT]: {
    storageFilterKey: ETableFilters.PROJECT,
    sortByDefault: EProjectColumnName.ProjectName,
    entityText: "projects",
    getEntityId: (e: IProjectTable) => e.id,
    intervalLabel: EIntervalLabels.ProjectsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
    allEntityColumns: projectIndexColumns,
    isAdvancedSearch: true,
  },
  [EAdvancedIndexPages.AUDIT]: {
    storageFilterKey: ETableFilters.AUDIT,
    sortByDefault: "subject",
    entityText: "audit events",
    getEntityId: (e: AuditLog) => e.id || "",
    allEntityColumns: auditIndexColumns,
    isTenantScope: true,
  },
  [EAdvancedIndexPages.USER]: {
    storageFilterKey: ETableFilters.USERS,
    sortByDefault: UsersFilterSortFields.Username,
    entityText: "users",
    getEntityId: (e: User) => e.userId || "",
    allEntityColumns: allUserColumns,
    isAdvancedSearch: true,
    isTenantScope: true,
  },
  [EAdvancedIndexPages.NODE_POOL]: {
    storageFilterKey: ETableFilters.NODE_POOL_V2,
    sortByDefault: NodepoolSortFilterFields.Name,
    entityText: "node pools",
    getEntityId: (nodePool: INodePoolTable) => nodePool.id,
    allEntityColumns: nodePoolIndexColumns,
    intervalLabel: EIntervalLabels.NodePoolIndex,
  },
  [EAdvancedIndexPages.REPORT]: {
    storageFilterKey: ETableFilters.REPORT,
    sortByDefault: ReportFilterAndSortFields.Name,
    entityText: "reports",
    getEntityId: (report: IReportTable) => report.id,
    allEntityColumns: reportIndexColumns,
    intervalLabel: EIntervalLabels.ReportIndex,
    getEntityCount: (filterBy?: Array<string>, search?: string) => orgUnitService.countReports(filterBy, search),
    validFilterFields: Object.values(ReportFilterAndSortFields),
    isAdvancedSearch: true,
    isTenantScope: true,
  },
  [EAdvancedIndexPages.ACCESS_RULE]: {
    storageFilterKey: ETableFilters.ACCESS_RULE,
    sortByDefault: AccessRulesSortFilterFields.SubjectId,
    entityText: "access rules",
    allEntityColumns: accessRulesIndexColumns,
    getEntityId: (accessRule: AccessRule) => accessRule.id.toString(),
    getEntityCount: (filterBy?: Array<string>, search?: string) =>
      accessRuleService.getAccessRulesCount(filterBy, search),
    validFilterFields: Object.values(AccessRulesSortFilterFields),
    isAdvancedSearch: true,
    isTenantScope: true,
  },
};
