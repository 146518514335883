<template>
  <q-td :aid="column.name" class="cluster-col">
    {{ clusterName }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import type { ITableColumn } from "@/models/table.model";
import { useClusterStore } from "@/stores/cluster.store";
import { getFieldContent } from "@/utils/table-format.util";
import { ALL_CLUSTER_FILTER_LABEL } from "@/models/filter.model";

export default defineComponent({
  props: {
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
    };
  },
  computed: {
    clusterUuid(): string {
      return getFieldContent(this.column, this.row);
    },
    clusterName(): string {
      if (!this.clusterUuid) {
        if (this.column.ignoreFilter && this.column.ignoreFilter(this.row)) return ALL_CLUSTER_FILTER_LABEL;
        return "-";
      }

      const clusterName = this.clusterStore.clusterList.find((c) => c.uuid === this.clusterUuid)?.name;
      return clusterName || "-";
    },
  },
});
</script>
