import { MigProfile, type ExtendedResource, GpuRequestType } from "@/swagger-models/assets-service-client";
import { EMemoryUnitValue, type ISelectOption, type IUIAssetCreationMeta } from "./global.model";

export const cpuUnitOptions: Array<ISelectOption> = [
  { label: "Cores", value: 1 },
  { label: "Millicores", value: 1000 },
];

export const gpuRequestTypeOptions = [
  { label: "% (of device)", value: GpuRequestType.Portion },
  { label: "MB", value: EMemoryUnitValue.MB },
  { label: "GB", value: EMemoryUnitValue.GB },
  { label: "MIG profile", value: GpuRequestType.MigProfile },
];

export const migProfileOptions: Array<ISelectOption> = Object.values(MigProfile).map((profile: string) => ({
  value: profile,
  label: profile,
}));

export interface IUIComputeResourceCreation {
  meta: IUIAssetCreationMeta;
  spec: {
    gpuDevicesRequest: number;
    gpuRequestType?: GpuRequestType | null | undefined;
    gpuPortionRequest?: number | null | undefined;
    gpuPortionLimit?: number | null | undefined;
    gpuMemoryRequest?: string | null | undefined;
    gpuMemoryLimit?: string | null | undefined;
    migProfile?: MigProfile | null | undefined;
    cpuCoreRequest: number;
    cpuMemoryRequest: string;
    cpuCoreLimit?: number | null | undefined;
    cpuMemoryLimit?: string | null | undefined;
    largeShmRequest?: boolean | null;
    extendedResources?: Array<ExtendedResource> | null;
  };
}

export interface ICpuCore {
  request: number;
  limit?: number | null | undefined;
}

export interface ICpuMemory {
  request: string;
  limit?: string | null | undefined;
}

export interface IGpuPortion {
  request: number;
  limit?: number | null | undefined;
}

export interface IGpuMemory {
  request: string;
  limit?: string | null | undefined;
}
export interface IResourcesSectionModel {
  gpuDevicesRequest: number;
  gpuRequestType?: GpuRequestType | null;
  gpuPortionRequest?: number | null;
  gpuPortionLimit?: number | null;
  gpuMemoryRequest?: string | null;
  gpuMemoryLimit?: string | null;
  migProfile?: MigProfile | null | undefined;
  cpuCore: ICpuCore;
  cpuMemory: ICpuMemory;
}

export interface IGpuRequestModel {
  gpuDevicesRequest: number;
  gpuRequestType?: GpuRequestType | null;
  gpuPortionRequest?: number | null;
  gpuPortionLimit?: number | null;
  gpuMemoryRequest?: string | null;
  gpuMemoryLimit?: string | null;
  migProfile?: MigProfile | null | undefined;
}
