<template>
  <section class="git-instance-section">
    <div class="q-mb-md">
      <div class="q-py-md">Set the data origin</div>
      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
      >
        <q-input
          :disable="allowOnlyContainerPath"
          aid="repository-input"
          :model-value="modelValue.repository"
          no-error-icon
          label="Repository URL"
          stack-label
          placeholder="e.g: https://github.com/kubernetes/kubernetes"
          input-class="placeholder-italic"
          :rules="[(val) => notEmpty(val) || errorMessages.URL_NOT_EMPTY, isUrl]"
          @update:model-value="$emit('update:model-value', { ...modelValue, repository: $event })"
        />
      </runai-tooltip-wrapper>
    </div>

    <div class="row q-mb-md justify-between">
      <runai-tooltip-wrapper
        v-if="!supportGitSyncOneParameter"
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
        class="col-6 q-mr-md"
      >
        <q-input
          :disable="allowOnlyContainerPath"
          aid="git-branch-input"
          :model-value="modelValue.branch"
          no-error-icon
          label="Git branch"
          stack-label
          placeholder="e.g: main"
          input-class="placeholder-italic"
          hint="If left empty, the repository's default branch will be used"
          @update:model-value="$emit('update:model-value', { ...modelValue, branch: $event === '' ? null : $event })"
        />
      </runai-tooltip-wrapper>

      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
        class="col"
      >
        <q-input
          :disable="allowOnlyContainerPath"
          aid="git-revision-input"
          :model-value="modelValue.revision"
          no-error-icon
          :label="revisionLabel"
          stack-label
          placeholder="e.g: dae86e1950b1277e545cee1805517500..."
          input-class="placeholder-italic"
          hint="if left empty, defaults to 'HEAD' (latest)"
          @update:model-value="$emit('update:model-value', { ...modelValue, revision: $event === '' ? null : $event })"
        />
      </runai-tooltip-wrapper>
    </div>

    <div class="credentials-section row q-mb-md">
      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
        class="col-6"
      >
        <runai-select
          :disabled="allowOnlyContainerPath"
          aid="git-secret-select"
          :model-value="modelValue.passwordSecret || null"
          @update:model-value="$emit('update:model-value', { ...modelValue, passwordAssetId: $event })"
          :loading="loadingSecrets"
          :options="secretOptions"
          label="Secret"
          emit-value
          :rules="[isSecretSelected]"
          map-options
        />
      </runai-tooltip-wrapper>
    </div>
    <div>
      <div class="q-py-md">{{ containerPathTitle }}</div>
      <div class="row">
        <q-input
          class="col-6"
          aid="git-container-input"
          :model-value="modelValue.path"
          no-error-icon
          label="Container path"
          stack-label
          placeholder="e.g: /home/localdata"
          input-class="placeholder-italic"
          :rules="[(val) => notEmpty(val) || errorMessages.PATH_NOT_EMPTY, isDirectoryPath]"
          @update:model-value="$emit('update:model-value', { ...modelValue, path: $event })"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
// Models
import type { IUIGitInstance } from "@/models/workload.model";
import type { ISelectOption } from "@/models/global.model";
// Utils
import { isNotEmpty, isValidDirectoryPath, isValidUrl } from "@/common/form.validators";
import { assetsUtil } from "@/utils/assets.util/assets.util";
// Constants
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "git-instance-section",
  components: {
    RunaiSelect,
    RunaiTooltipWrapper,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Object as PropType<IUIGitInstance>,
      required: true,
    },
    secrets: {
      type: Array as PropType<string[]>,
      required: true,
    },
    loadingSecrets: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    supportGitSyncOneParameter: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    allowOnlyContainerPath: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      errorMessages,
    };
  },
  computed: {
    revisionLabel(): string {
      return this.supportGitSyncOneParameter ? "Revision (branch, tag or hash)" : "Revision (commit hash or tag)";
    },
    containerPathTitle(): string {
      return assetsUtil.containerPathTitle();
    },
    secretOptions(): ISelectOption[] {
      const options: ISelectOption[] = this.secrets.map((secret) => ({ label: secret, value: secret }));
      options.unshift({ label: "None (for public repositories)", value: null });
      return options;
    },
  },
  methods: {
    notEmpty(val: string): boolean {
      return isNotEmpty(val);
    },
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    isUrl(val: string): boolean | string {
      return isValidUrl(val) || errorMessages.INVALID_URL;
    },
    isSecretSelected(val: string): boolean | string {
      return !!val || errorMessages.SELECT_CREDENTIALS;
    },
  },
});
</script>
