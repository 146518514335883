<template>
  <runai-section title="Set the connection for your tool(s)">
    <template v-if="tools.length">
      <tool-box
        v-for="tool in tools"
        :key="tool.id"
        :form-type="formType"
        :tool="tool"
        :tool-names="toolNames"
        :tools="tools"
        removeable
        :disable="sectionDisabled"
        :cluster-id="clusterId"
        @removed="removeTool(tool.id)"
        @update-tool="updateTool($event, tool.id)"
      ></tool-box>
    </template>
    <q-btn flat aid="add-tool-button" color="primary" label="+ tool" @click="addTool"></q-btn>
  </runai-section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Services
import { environmentService } from "@/services/control-plane/environment.service/environment.service";
// Cmps
import type { EToolBoxFormType } from "@/components/environment/form-tools-section/tool-box";
import { ToolBox } from "@/components/environment/form-tools-section/tool-box";
// Models
import type { IUIConnection } from "@/models/environment.model";
import { RunaiSection } from "@/components/common/runai-section";

export default defineComponent({
  name: "form-tools-list",
  components: {
    RunaiSection,
    ToolBox,
  },
  emits: ["update"],
  props: {
    tools: {
      type: Array as PropType<Array<IUIConnection>>,
      required: true,
      default: () => [],
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    formType: {
      type: String as PropType<EToolBoxFormType>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    toolNames(): Array<string> {
      return this.tools.map((tool: IUIConnection): string => tool.name);
    },
    validSection(): boolean {
      return environmentService.validateUIConnections(this.tools);
    },
  },
  methods: {
    addTool(): void {
      const newTool: IUIConnection = environmentService.getConnectionModel();
      this.updateTools([...this.tools, newTool]);
    },
    removeTool(toolId: string): void {
      this.updateTools(this.tools.filter((tool: IUIConnection) => tool.id !== toolId));
    },
    updateTool(newTool: IUIConnection, toolId: string): void {
      if (!this.tools.find((tool: IUIConnection) => tool.id === toolId)) {
        this.updateTools([...this.tools, newTool]);
      } else {
        this.updateTools(this.tools.map((tool: IUIConnection) => (tool.id !== toolId ? tool : newTool)));
      }
    },
    updateTools(toolsToUpdate: IUIConnection[]): void {
      this.$emit("update", toolsToUpdate);
    },
  },
});
</script>
