import type { IStatusColOptions, ITableColumn } from "./table.model";
import type {
  TrainingCreationRequest,
  TrainingSpecSpec,
  WorkloadSortFilterFields,
  WorkspaceCreationRequest,
  WorkspaceSpecSpec,
} from "@/swagger-models/workloads-client";
import {
  type Pod as WorkloadServicePod,
  Phase,
  type Connection,
  PodMetricType,
} from "@/swagger-models/workloads-client";
import type {
  SpecificRunConnectionInfo,
  AssetIdAndKind,
  ToolType,
  DistributedData,
  DistributedFramework,
  Toleration,
  PodAffinity,
  EnvironmentVariableOfAsset,
  HostPathInstance,
  NfsInstance,
  S3Instance,
  GitInstance,
  SecretInstance,
  ConfigMapInstance,
  PvcInstance,
} from "@/swagger-models/assets-service-client";
import type { AssetKind } from "@/swagger-models/assets-service-client";
import type { IConnectionAccess } from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";
import type { ENimAccessModelMethod } from "@/components/section/nim-model-store-section/nim-access-model-selector/nim-access-model-selector.model";

import type { IAutoScaleData } from "@/components/section/compute-resource-section";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { IUIVolume } from "@/models/data-source.model";
import type { DrilldownEventObject, Point, SeriesOptionsRegistry, DrillupEventObject } from "highcharts";
import type { EInferenceType } from "@/models/inference.model";
import type { IEnumBasedFilterSelectOption, IFilterBy } from "@/models/filter.model";
import type { IUIConnection } from "./environment.model";

export type { WorkloadServicePod };

export enum EIdleWorkloadMaxIdleDuration {
  training = "trainingJobMaxIdleDurationSecs",
  interactivePreemptible = "interactivePreemptibleJobMaxIdleDurationSecs",
  interactive = "interactiveJobMaxIdleDurationSecs",
}

export enum EWorkloadTimeLimit {
  training = "trainingJobTimeLimitSecs",
  interactive = "interactiveJobTimeLimitSecs",
}

export enum EWorkloadName {
  Workspace = "workspace",
  Training = "training",
  Inference = "inference",
  Distributed = "distributed",
}

export enum EWorkloadType {
  Workspace = "Workspace",
  Training = "Training",
  Inference = "Inference",
  Distributed = "Distributed",
  // Old clusters return different types for distributed workloads
  DistributedTraining = "DistributedTraining",
  TFJob = "TFJob",
}

export enum EDistributedReplicaType {
  Master = "Master",
  Worker = "Worker",
}

export enum EWorkloadFormType {
  Workspace = "workspace",
  Training = "training",
  Distributed = "distributed",
  Template = "template",
  Deployment = "deployment",
  Inference = "inference",
}

export enum EWorkloadEntity {
  workspace = "workspace",
  training = "training",
  workload = "workload",
  deployment = "deployment",
}

export enum EExternalWorkloadType {
  SeldonDeployment = "SeldonDeployment",
  VirtualMachineInstance = "VirtualMachineInstance",
  TFJob = "TFJob",
  PyTorchJob = "PyTorchJob",
  XGBoostJob = "XGBoostJob",
  MPIJob = "MPIJob",
  Notebook = "Notebook",
  Job = "Job",
  StatefulSet = "StatefulSet",
  ReplicaSet = "ReplicaSet",
  Pod = "Pod",
  AmlJob = "AmlJob",
  Service = "Service",
  CronJob = "CronJob",
  DevWorkspace = "DevWorkspace",
  RayCluster = "RayCluster",
  RayJob = "RayJob",
  RayService = "RayService",
  TaskRun = "TaskRun",
  PipelineRun = "PipelineRun",
  Workflow = "Workflow",
  ScheduledWorkflow = "ScheduledWorkflow",
  NVIDIANIM = "NVIDIA-NIM",
}

export enum EWorkloadAction {
  activate = "activate",
  run = "run",
  stop = "stop",
  connect = "connect",
  delete = "delete",
  clone = "clone",
}

export enum EWorkloadNodeArchitecture {
  Distributed = "Distributed",
  Standard = "Standard",
}

export enum EWorkloadErrorMessage {
  NotRunaiWorkloadMessage = "This action is not supported for the selected workload. To perform the action, go to the application you used to create the workload.",
  FailedToLoadWorkloads = "Failed to load workloads",
  FailedToLoadWorkloadsCount = "Failed to load workloads count",
  FailedToLoadPendingSchedulingMessages = "Failed to load pending scheduling messages",
  FailedToLoadPads = "Failed to load pods",
  FailedToRunWorkload = "Failed to start workload",
  FailedToDeleteWorkloads = "Failed to delete workloads",
  FailedToStopWorkloads = "Failed to stop workload",
  NoDeletePermission = "You don't have permission to delete this workload.",
  DeleteWorkloadIsNotSupported = "This action is not supported for the selected workload. To perform it, make sure you are the creator of the workload or go to the application used to create it.",
  WorkloadIsDeleting = "The selected workload is already deleted or in the process of being deleted",
  NoActionPermission = "You don't have permission to perform this action.",
}

export enum EWorkloadModalEntity {
  Project = "Project",
  Department = "Department",
  Nodepool = "Nodepool",
}

export enum EWorkloadTelemetryGroupBy {
  ClusterId = "ClusterId",
  DepartmentId = "DepartmentId",
  ProjectId = "ProjectId",
  Type = "Type",
  CurrentNodepools = "CurrentNodepools",
  Phase = "Phase",
}

export interface EWorkloadTelemetryPointMeta {
  entityId: string;
  groupByKey: EWorkloadTelemetryGroupBy;
}
//@ts-ignore
export interface DrillupEventWorkloadTelemetryObject extends DrillupEventObject {
  seriesOptions: {
    data: {
      drilldown: boolean;
      meta: EWorkloadTelemetryPointMeta;
      name: string;
      y: number;
    }[];
  };
}
//@ts-ignore
export interface DrilldownEventWorkloadTelemetryObject extends DrilldownEventObject {
  point: { meta: EWorkloadTelemetryPointMeta };
  seriesOptions: {
    data: {
      drilldown: boolean;

      name: string;
      y: number;
    }[];
  };
}
export interface ETelemetrySeriesPoint extends Point {
  meta: EWorkloadTelemetryPointMeta;
}
export interface ETelemetryDrilldownEventObject extends DrilldownEventObject {
  point: ETelemetrySeriesPoint;
  drilldown: SeriesOptionsRegistry;
}

export enum EWorkloadPodPhase {
  Running = "Running",
}

export type ExtendedPhase = typeof ExtendedPhase[keyof typeof ExtendedPhase];

export const ExtendedPhase = {
  ...Phase,
  Succeeded: "Succeeded",
  Deleted: "Deleted",
} as const;

export const WorkloadPhaseMap: Record<ExtendedPhase, IStatusColOptions> = {
  [ExtendedPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Initializing]: {
    status: "Initializing",
    displayAnimation: false,
  },
  [ExtendedPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Pending]: {
    status: "Pending",
    color: "warning",
    displayAnimation: false,
  },
  [ExtendedPhase.Running]: {
    status: "Running",
    displayAnimation: false,
    color: "success",
  },
  [ExtendedPhase.Stopped]: {
    status: "Stopped",
    displayAnimation: false,
  },
  [ExtendedPhase.Degraded]: {
    status: "Running with issues",
    displayAnimation: false,
  },
  [ExtendedPhase.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
  },
  [ExtendedPhase.Completed]: {
    status: "Completed",
    displayAnimation: false,
  },
  [ExtendedPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [ExtendedPhase.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
    filterKey: "status",
  },
  [ExtendedPhase.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
  },
  [ExtendedPhase.Stopping]: {
    status: "Stopping...",
    displayAnimation: true,
  },
  [ExtendedPhase.Terminating]: {
    status: "Terminating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Succeeded]: {
    status: "Succeeded",
    color: "success",
    displayAnimation: false,
  },
  [ExtendedPhase.Resuming]: {
    status: "Resuming...",
    displayAnimation: true,
  },
};

interface IWorkloadMetadataAnnotations {
  workspaceId: string;
}

export interface IWorkloadMetadata {
  name: string;
  namespace: string;
  creationTimestamp?: null;
  annotations: IWorkloadMetadataAnnotations;
}

export interface IWorkloadSpec {
  environment: {
    items: Record<string, { value: string }>;
  };
  name: { value: string };
  image: { value: string };
  username: { value: string };
  command?: { value: string };
  arguments?: { value: string };
  volumes?: {
    items: Record<string, unknown>;
  };
  cpu?: { value: string };
  gpu?: { value: string };
  memory?: { value: string };
  migProfile?: { value: string };
  nodeType?: { value: string };
  gitSync?: {
    items: Record<string, unknown>;
  };
  largeShm: {
    value: boolean;
  };
  imagePullPolicy: {
    value: string;
  };
  annotations: {
    items: Record<string, unknown>;
  };
  preemptible?: { value: string };
}

export interface IWorkload {
  apiVersion: string;
  kind: string;
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadCreate {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadResponse {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
}

export interface IWorkloadDurationOption {
  value: EIdleWorkloadMaxIdleDuration | string;
  label: string;
  duration: number | null | undefined;
  inheritedDuration?: number | null;
}

export interface IWorkloadListModal {
  name: string;
  type: string;
  status: IStatusColOptions;
}

export interface IUIDistributedMaster {
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
}

export interface IUIDistributed extends Omit<DistributedData, "master" | "framework" | "numWorkers"> {
  numWorkers?: number;
  master?: IUIDistributedMaster | null;
  distFramework?: DistributedFramework | null;
  noMaster?: boolean;
}

export interface IUIWorkloadCreation {
  name: string;
  projectId: number;
  namespace: string;
  clusterId: string;
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
  distributed?: IUIDistributed | null;
  enableEditingMaster?: boolean;
}

export interface IUIInferenceCreation extends IUIWorkloadCreation {
  inferenceType: EInferenceType;
  modelId?: string;
  image?: string;
  accessModelMethod?: ENimAccessModelMethod;
  gatedModel?: boolean;
}

export interface IUIWorkloadAssets {
  environment: string | null;
  compute: string | null;
  datasources?: Array<AssetIdAndKind>;
  uiVolumes?: Array<IUIVolume>;
}

export interface IUIWorkloadSpecificEnv {
  command?: string | null;
  args?: string | null;
  environmentVariables?: Array<EnvironmentVariableOfAsset>;
  allowOverQuota?: boolean | null;
  connections?: Array<SpecificRunConnectionInfo> | null;
  runAsGid?: number | null;
  runAsUid?: number | null;
  supplementalGroups?: string | null;
  nodeType?: string | null;
  nodePools?: Array<string> | null;
  annotations?: Array<IItemizedListItem>;
  labels?: Array<IItemizedListItem>;
  autoDeletionTimeAfterCompletionSeconds?: number | null;
  autoScaleData?: IAutoScaleData;
  backoffLimit?: number | null;
  tolerations?: Array<Toleration> | null;
  podAffinity?: PodAffinity | null;
  completions?: number | null;
  parallelism?: number | null;
  servingPortAccess?: IConnectionAccess | null;
  terminationGracePeriodSeconds?: number | null;
}

export interface IWorkloadMeta {
  name: string;
  namespace: string;
  clusterId: string;
  projectId: number;
}

export interface IWorkloadPodsModalOptions {
  columns: ITableColumn[];
  header: string;
  displayRedirection?: boolean;
  getPods: (filterBy: IFilterBy) => Promise<WorkloadServicePod[]>;
  getPodsCount: () => Promise<number>;
  clusterId?: string;
}
export interface IWorkloadConnectionsModalOptions {
  connections: Connection[];
  header: string;
  workloadDetails?: {
    currentUser: string;
    isRunning: boolean;
    name: string;
    type: string;
    projectName: string;
  };
}

export interface IWorkloadListModalOptions {
  entityFilter: string;
  //in order to support the table to pick the correct filter need to supply both filterName and entityName
  filterName: WorkloadSortFilterFields;
  entityName: string;
  entityType: EWorkloadModalEntity;
  columns: ITableColumn[];
  clusterId: string;
}

export interface IWorkloadListModalRow {
  name: string;
  status: IStatusColOptions;
  type: IDisplayWorkloadType;
}

export type IDisplayWorkloadType = "Workspace" | "Training" | "Inference" | "Distributed";

const workloadFilterDisplayedPhasesSet: Set<string> = new Set([
  Phase.Creating,
  Phase.Initializing,
  Phase.Pending,
  Phase.Running,
  Phase.Degraded,
  Phase.Stopped,
  Phase.Failed,
  Phase.Completed,
  Phase.Unknown,
]);
export const workloadPhaseFilterOptions: IEnumBasedFilterSelectOption[] = Object.keys(Phase).map((key) => ({
  label: key,
  value: key,
  hidden: !workloadFilterDisplayedPhasesSet.has(key),
}));

export interface IWorkloadSupportedRoutes {
  environment: {
    name: string;
    query: { fromPage: string };
  };
  compute: {
    name: string;
    query: { fromPage: string };
  };
  datasource: {
    pages: Record<string, string>;
  };
  onLeave: {
    name: string;
  };
}

export interface IToolItem {
  toolType: ToolType;
  toolName: string;
  url: string;
  authorizedUsers?: string[] | null;
}
//advanced workload metric

export const podMetricTypes: PodMetricType[] = [
  PodMetricType.GpuGraphicsEngineActivityPerGpu,
  PodMetricType.GpuSmActivityPerGpu,
  PodMetricType.GpuSmOccupancyPerGpu,
  PodMetricType.GpuTensorActivityPerGpu,
  PodMetricType.GpuFp64EngineActivityPerGpu,
  PodMetricType.GpuFp32EngineActivityPerGpu,
  PodMetricType.GpuFp16EngineActivityPerGpu,
];

export const podMetricTypeLabels: { [key: string]: string } = {
  [PodMetricType.GpuGraphicsEngineActivityPerGpu]: "Graphics engine activity",
  [PodMetricType.GpuSmActivityPerGpu]: "GPU SM activity",
  [PodMetricType.GpuSmOccupancyPerGpu]: "GPU SM occupancy",
  [PodMetricType.GpuTensorActivityPerGpu]: "GPU tensor activity",
  [PodMetricType.GpuFp64EngineActivityPerGpu]: "GPU FP64 engine activity",
  [PodMetricType.GpuFp32EngineActivityPerGpu]: "GPU FP32 engine activity",
  [PodMetricType.GpuFp16EngineActivityPerGpu]: "GPU FP16 engine activity",
};

export interface IUIHostPathInstance extends HostPathInstance {
  id: string;
  type: AssetKind;
}

export interface IUINfsInstance extends NfsInstance {
  id: string;
  type: AssetKind;
}

export interface IUIGitInstance extends GitInstance {
  id: string;
  type: AssetKind;
}

export interface IUIS3Instance extends S3Instance {
  id: string;
  type: AssetKind;
}

export interface IUIConfigMapInstance extends ConfigMapInstance {
  id: string;
  type: AssetKind;
}

export interface IUISecretVolumeInstance extends SecretInstance {
  id: string;
  type: AssetKind;
}

export interface IUIPvcInstance extends PvcInstance {
  id: string;
  type: AssetKind;
}

export interface IUIStorageFields {
  nfs?: IUINfsInstance[];
  hostPath?: IUIHostPathInstance[];
  git?: IUIGitInstance[];
  s3?: IUIS3Instance[];
  configMapVolume?: IUIConfigMapInstance[];
  secretVolume?: IUISecretVolumeInstance[];
  pvc?: IUIPvcInstance[];
  volumes?: IUIVolume[];
}
export type IUIStorageVolumesExcludedFields = Omit<IUIStorageFields, "volumes">;

export interface IUIWorkspaceCreationRequest extends Omit<WorkspaceCreationRequest, "spec"> {
  spec: IUIWorkspaceSpec;
}
export interface IUITrainingCreationRequest extends Omit<TrainingCreationRequest, "spec"> {
  spec: IUITrainingSpec;
}

export interface IUIWorkspaceSpec extends Omit<WorkspaceSpecSpec, "ports" | "exposedUrls" | "relatedUrls" | "storage"> {
  connections?: Array<IUIConnection>;
  storage?: IUIStorageFields;
}
export interface IUITrainingSpec extends Omit<TrainingSpecSpec, "ports" | "exposedUrls" | "relatedUrls" | "storage"> {
  connections?: Array<IUIConnection>;
  storage?: IUIStorageFields;
}

export type IUIEnvrionmentFields = Omit<
  IUIWorkspaceSpec,
  | "compute"
  | "storage"
  | "nodeType"
  | "nodePools"
  | "podAffinity"
  | "annotations"
  | "labels"
  | "tolerations"
  | "terminateAfterPreemption"
  | "autoDeletionTimeAfterCompletionSeconds"
  | "backoffLimit"
  | "tty"
  | "stdin"
>;
