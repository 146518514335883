import {
  Configuration,
  ApplicationsApi,
  ClientsApi,
  IdpsApi,
  RealmApi,
  TokensApi,
  UsersApi,
  type ConfigurationParameters,
  ConfigApi,
  UserApplicationsApi,
} from "@/swagger-models/identity-manager-client";

class IdentityManagerApi {
  configuration: Configuration;

  applicationsApi: ApplicationsApi;
  clientsApi: ClientsApi;
  idpsApi: IdpsApi;
  realmApi: RealmApi;
  tokensApi: TokensApi;
  usersApi: UsersApi;
  configApi: ConfigApi;
  userApplicationsApi: UserApplicationsApi;

  constructor() {
    this.configuration = new Configuration();

    this.applicationsApi = new ApplicationsApi(this.configuration, this.baseUrl);
    this.clientsApi = new ClientsApi(this.configuration, this.baseUrl);
    this.idpsApi = new IdpsApi(this.configuration, this.baseUrl);
    this.realmApi = new RealmApi(this.configuration, this.baseUrl);
    this.tokensApi = new TokensApi(this.configuration, this.baseUrl);
    this.usersApi = new UsersApi(this.configuration, this.baseUrl);
    this.configApi = new ConfigApi(this.configuration, this.baseUrl);
    this.configApi = new ConfigApi(this.configuration, this.baseUrl);
    this.userApplicationsApi = new UserApplicationsApi(this.configuration, this.baseUrl);
  }
  get baseUrl() {
    return `${window.location.origin}`;
  }

  public set setAccessToken(accessToken: ConfigurationParameters["accessToken"]) {
    this.configuration.accessToken = accessToken;
  }
}

export const identityManagerApi = new IdentityManagerApi();
