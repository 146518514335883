import type { TEnvVarNoneCustomSourceOption } from "@/models/environment.model";
import { credentialService } from "@/services/control-plane/credential.service/credential.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import {
  AssetKind,
  type CredentialsListResponseEntry,
  type EnvironmentVariable,
  type EnvironmentVariableOfAsset,
  Scope,
} from "@/swagger-models/assets-service-client";
import { ScopeType } from "@/swagger-models/authorization-client";
import { type SecretListInfo, SecretType } from "@/swagger-models/k8s-objects-tracker-client";
import { computed, type Ref, ref } from "vue";

export function useEnvironmentVariablesSection() {
  async function loadSecretsForEnvironmentVariables(projectId: string) {
    const secrets = await credentialService.listExistingSecrets(Scope.Project, projectId, undefined, "all");

    return secrets
      .filter((s: SecretListInfo) => s.type !== SecretType.DockerRegistry)
      .map((s: SecretListInfo) => ({
        label: s.name,
        value: s.name,
        type: s.type,
      }));
  }

  async function loadCredentialsForEnvironmentVariables(
    clusterId?: string,
    departmentId?: string,
    projectId?: number,
  ): Promise<TEnvVarNoneCustomSourceOption[]> {
    const credentials = (
      await credentialService.listCredentials({
        clusterUuid: clusterId || undefined,
        departmentId: departmentId,
        projectId: projectId,
      })
    )
      .filter((c: CredentialsListResponseEntry) => c.meta.kind !== AssetKind.DockerRegistry)
      .map((c) => ({
        label: c.meta.name,
        value: c.meta.id,
        type: c.meta.kind,
      }));

    return credentials;
  }

  async function loadConfigMapsForEnvironmentVariables(
    scope: ScopeType,
    scopeId: string,
  ): Promise<TEnvVarNoneCustomSourceOption[]> {
    const configMaps = (await dataSourceService.listExistingConfigMaps(scope, scopeId)).map((cm) => ({
      label: cm.name,
      value: cm.name,
      type: AssetKind.ConfigMap,
    }));

    return configMaps;
  }

  const environmentVariablesSourceValueOptions: Ref<TEnvVarNoneCustomSourceOption[]> = ref<
    TEnvVarNoneCustomSourceOption[]
  >([]);

  const environmentVariablesOptionsMap = computed(() =>
    environmentVariablesSourceValueOptions.value.reduce((acc, option: TEnvVarNoneCustomSourceOption) => {
      acc[option.value] = option.value;
      return acc;
    }, {} as Record<string, string>),
  );

  async function loadSourceValueOptions(
    isHybrid: boolean,
    projectId?: string,
    departmentId?: string,
    clusterId?: string,
  ) {
    if (isHybrid) {
      if (!projectId) {
        throw new Error("Project ID is required for hybrid mode");
      }
      const [secrets, configMaps] = await Promise.all([
        loadSecretsForEnvironmentVariables(projectId),
        loadConfigMapsForEnvironmentVariables(Scope.Project, projectId),
      ]);
      environmentVariablesSourceValueOptions.value = [...secrets, ...configMaps];
    } else {
      let scope: ScopeType = ScopeType.Project;
      let scopeId = "";
      projectId && (scope = ScopeType.Project) && (scopeId = projectId);
      departmentId && (scope = ScopeType.Department) && (scopeId = departmentId);
      clusterId && (scope = ScopeType.Cluster) && (scopeId = clusterId);
      if (!scopeId) {
        console.warn("No scope ID provided for environment variables source value options");
        return;
      }
      const [credentials, configMaps] = await Promise.all([
        loadCredentialsForEnvironmentVariables(clusterId, departmentId, projectId ? Number(projectId) : undefined),
        loadConfigMapsForEnvironmentVariables(scope, scopeId),
      ]);
      environmentVariablesSourceValueOptions.value = [...credentials, ...configMaps];
    }
  }

  // validateEnvironmentVariables is used to validate that the environment variables values are still valid (after scope change or copy&edit)
  function validateEnvironmentVariablesOfAsset(environmentVariables: EnvironmentVariableOfAsset[]): void {
    if (!environmentVariables) return;
    environmentVariables.forEach((envVar: EnvironmentVariableOfAsset) => {
      if (envVar.credential?.assetId) {
        const credential = environmentVariablesOptionsMap.value[envVar.credential.assetId];
        if (!credential) {
          envVar.credential.assetId = null;
        }
      }
      if (envVar.configMap?.name) {
        const configMap = environmentVariablesOptionsMap.value[envVar.configMap.name];
        if (!configMap) {
          envVar.configMap.name = null;
        }
      }
    });
  }

  // validateEnvironmentVariables is used to validate that the environment variables values are still valid (after scope change or copy&edit)
  function validateEnvironmentVariables(environmentVariables: EnvironmentVariable[]): void {
    if (!environmentVariables) return;
    environmentVariables.forEach((envVar: EnvironmentVariable) => {
      if (envVar.secret?.name) {
        const secret = environmentVariablesOptionsMap.value[envVar.secret.name];
        if (!secret) {
          envVar.secret.name = null;
        }
      }
      if (envVar.configMap?.name) {
        const configMap = environmentVariablesOptionsMap.value[envVar.configMap.name];
        if (!configMap) {
          envVar.configMap.name = null;
        }
      }
    });
  }

  return {
    loadSourceValueOptions,
    environmentVariablesSourceValueOptions,
    validateEnvironmentVariables,
    validateEnvironmentVariablesOfAsset,
  };
}
