import type { Department } from "@/swagger-models/org-unit-service-client";
import type { IFilterBy } from "@/models/filter.model";

export const DEFAULT_DEPARTMENT_NAME = "default";
export const DEFAULT_DEPARTMENT_DESERVED_GPUS = -1;

export interface IDepartmentTable extends Department {
  rolesNames?: string[];
  averageGpuAllocation?: number;
  averageGpuUtilization?: number;
}

export enum EDepartmentColumnName {
  DepartmentName = "name",
  NodePools = "node-pools",
  GpuQuota = "gpu-quota",
  ProjectsGpus = "projects-gpus",
  AccessRules = "accessRules",
  Projects = "projects",
  CpuQuota = "cpu-quota",
  MemoryQuota = "memory-quota",
  AllocatedCpu = "allocated-cpu",
  AllocatedMemory = "allocated-memory",
  AllocatedGpus = "allocated-gpus",
  GpuUtilization = "gpu-utilization",
  CpuUtilization = "cpu-utilization",
  MemoryUtilization = "memory-utilization",
  CreatedAt = "createdAt",
  Workloads = "workloads",
}

export interface IDepartmentTableFilterBy extends IFilterBy {
  avgGpuUtilizationTimeframe: string;
  avgGpuAllocationTimeframe: string;
}
