<template>
  <section class="s3-instance-section">
    <div class="q-mb-md">
      <div class="q-py-md">Set the data origin</div>
      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
      >
        <q-input
          :disable="allowOnlyContainerPath"
          aid="s3-service-url-input"
          :model-value="modelValue.url"
          no-error-icon
          label="S3 service URL"
          stack-label
          placeholder="e.g: https://s3.us-west-2.amazonaws.com/"
          input-class="placeholder-italic"
          :rules="[(val) => notEmpty(val) || errorMessages.URL_NOT_EMPTY, isUrl]"
          @update:model-value="$emit('update:model-value', { ...modelValue, url: $event })"
        />
      </runai-tooltip-wrapper>
    </div>

    <div class="row">
      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
        class="col"
      >
        <q-input
          :disable="allowOnlyContainerPath"
          aid="s3-bucket-input"
          :model-value="modelValue.bucket"
          no-error-icon
          label="Bucket name"
          stack-label
          placeholder="e.g: my-bucket"
          input-class="placeholder-italic"
          :rules="[(val) => notEmpty(val) || errorMessages.NAME_NOT_EMPTY]"
          @update:model-value="$emit('update:model-value', { ...modelValue, bucket: $event })"
        />
      </runai-tooltip-wrapper>
    </div>

    <div class="credentials-section row q-mb-md">
      <runai-tooltip-wrapper
        :display-tooltip="allowOnlyContainerPath"
        tooltip-text="This was defined when the data source was created and can't be modified"
        anchor="bottom middle"
        self="bottom middle"
        class="col-6"
      >
        <runai-select
          :disable="allowOnlyContainerPath"
          aid="s3-secret-select"
          :model-value="modelValue.accessKeySecret || null"
          @update:model-value="$emit('update:model-value', { ...modelValue, accessKeyAssetId: $event })"
          :loading="loadingSecrets"
          :options="secretOptions"
          map-options
          label="Secret"
          emit-value
          :rules="[isSecretSelected]"
        />
      </runai-tooltip-wrapper>
    </div>

    <div>
      <div class="q-py-md">{{ containerPathTitle }}</div>
      <div class="row">
        <q-input
          class="col-6"
          aid="s3-container-path-input"
          :model-value="modelValue.path"
          no-error-icon
          label="Container path"
          stack-label
          placeholder="e.g: /home/localdata"
          input-class="placeholder-italic"
          :rules="[(val) => notEmpty(val) || errorMessages.PATH_NOT_EMPTY, isDirectoryPath]"
          @update:model-value="$emit('update:model-value', { ...modelValue, path: $event })"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
// Models
import type { IUIS3Instance } from "@/models/workload.model";
import type { ISelectOption } from "@/models/global.model";
// Utils
import { isNotEmpty, isValidDirectoryPath, isValidUrl } from "@/common/form.validators";
import { assetsUtil } from "@/utils/assets.util/assets.util";
// Constants
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "s3-instance-section",
  components: {
    RunaiSelect,
    RunaiTooltipWrapper,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Object as PropType<IUIS3Instance>,
      required: true,
    },
    secrets: {
      type: Array as PropType<string[]>,
      required: true,
    },
    loadingSecrets: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    allowOnlyContainerPath: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      errorMessages,
    };
  },
  computed: {
    containerPathTitle(): string {
      return assetsUtil.containerPathTitle();
    },
    secretOptions(): ISelectOption[] {
      const options: ISelectOption[] = this.secrets.map((secret) => ({ label: secret, value: secret }));
      options.unshift({ label: "None (for public buckets)", value: null });
      return options;
    },
  },
  methods: {
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    isUrl(val: string): boolean | string {
      return isValidUrl(val) || errorMessages.INVALID_URL;
    },
    notEmpty(val: string): boolean {
      return isNotEmpty(val);
    },
    isSecretSelected(val: string): boolean | string {
      return !!val || errorMessages.SELECT_CREDENTIALS;
    },
  },
});
</script>
