import type { Setting } from "@/swagger-models/backend-client";
import type { Mappers } from "@/swagger-models/identity-manager-client";
import { EAuthType } from "@/models/notifications.model";
import type { EmailAuthType } from "@/swagger-models/notifications-proxy-client";

export const enum MappersKeys {
  Uid = "uid",
  Gid = "gid",
  Groups = "groups",
  SupplementaryGroups = "supplementaryGroups",
  Email = "email",
}

export enum SettingKeys {
  DashboardShowOld = "dashboard.show_old",
  DashboardOptimization = "dashboard.optimized_analytics_queries",
  DepartmentsUse = "departments.use",
  ProjectPermissionUse = "project.permissions.use",
  ProjectManualOverQuota = "project.quota.manual_over_quota",
  CPUResourcesQuota = "project.quota.cpu_resources",
  LoginWithSSO = "tenant.login_with_sso",
  AllowSwap = "tenant.allow_swap",
  RegistryIntegration = "tenant.enable_registry_integration",
  AllowGPT2Model = "tenant.allow_gpt2_model",
  ConsumptionReport = "tenant.show_consumption_report",
  ResourcesCost = "tenant.resources_cost",
  AdvancedGPUMetrics = "tenant.enable_advanced_gpu_metrics",
  EnableWandbSweeps = "tenant.enable_wandb_sweeps",
  EnableLegacyMpi = "tenant.enable_legacy_mpi",
  WandbHost = "tenant.wandb_host",
  EnableInactivityLogout = "tenant.enable_inactivity_logout",
  InactivityMinutesTillLogout = "tenant.inactivity_minutes_till_logout",
  ExcludeViewerInactivityLogout = "tenant.exclude_viewers_from_inactivity_logout",
  EnableCpuDashboards = "tenant.enable_cpu_dashboards",
  EnableJobSubmitForm = "tenant.enable_job_submit_form",
  LimitQuotaOverSubscription = "tenant.limit_quota_over_subscription",
  LogoutURI = "tenant.logout_uri",
  EnablePolicies = "tenant.enable_policies",
  EnableModelCatalog = "tenant.enable_model_catalog",
  AdminMessage = "tenant.admin_message",
  GpuOverProvisioning = "tenant.gpu_over_provisioning",
  WorkloadSubmissionV2Enabled = "tenant.workload_submission_v2_enabled",
  EnableNewCliInstaller = "tenant.is_new_cli_installer_enabled",
  SmtpEnabled = "tenant.smtp_enabled",
  DeletedWorkloadsView = "tenant.deleted_workloads_view",
  EnableWorkloadTolerations = "tenant.enable_workload_tolerations",
  EnableWorkloadTopology = "tenant.enable_workload_topology",
}

export const defaultMappers: Mappers = {
  uid: "UID",
  gid: "GID",
  groups: "GROUPS",
  supplementaryGroups: "SUPPLEMENTARYGROUPS",
  email: "email",
};

export const defaultOcpMappers: Mappers = {
  uid: "metadata.uid",
  gid: "metadata.gid",
  groups: "groups",
  supplementaryGroups: "supplementarygroups",
  email: "metadata.name",
};

export const settingUpdateSuccessMessage = "Settings saved";
export const defaultEntityId = "runai-app.run.ai/runai";
export const urlHintMessage = "If left empty, the latest metadata file will be used";
export const EmailErrorMessages = {
  EMAIL_SAVED_ERROR: "Email server couldn't be saved. Try again. If the issue persists, contact Run:ai support.",
  DELETE_SERVER_ERROR: "Email server couldn't be deleted. Try again. If the issue persists, contact Run:ai support.",
  EMAIL_NOTIFICATION_ENABLE_ERROR:
    "Email notifications couldn't be enabled. Try again. If the issue persists, contact Run:ai support.",
  EMAIL_NOTIFICATION_DISABLE_ERROR:
    "Email notifications couldn't be disabled. Try again. If the issue persists, contact Run:ai support.",
};

export const DEFAULT_SMTP_PORT = 587;
export const DEFAULT_EMPTY_EMAIL_SETTINGS: ISettingEmailServer = {
  smtpHost: "",
  authType: EAuthType.AuthPlain,
  smtpPort: DEFAULT_SMTP_PORT,
  username: "",
  password: "",
  fromEmail: "",
  fromName: "",
};
export enum EBadgeLabel {
  Experimental = "Experimental",
  Beta = "Beta",
  Legacy = "Legacy",
}

export const AlphaStage = "Alpha";

export type TSettingValue = boolean | string | number | IResourcesCost | IAdminMessage;

export interface IResourcesCost {
  gpu: number | null;
  cpu: number | null;
  memory: number | null;
}

export interface ISecuritySettings {
  timeout: number;
  logoutUrl: string | null;
}

export interface IAdminMessage {
  msg: string;
  id: string;
  createdAt: Date;
  enableDismiss: boolean;
}

export interface IAdminMessageSetting extends Omit<Setting, "value"> {
  value: IAdminMessage;
}

export interface IAnalyticsSectionSettings {
  cpu: Setting;
  consumption: Setting;
  advancedGpuMetrics: Setting;
  optimizationEnabled: Setting;
}

export interface IWorkloadsSectionSettings {
  policies: Setting;
  models: Setting;
  tolerations: Setting;
  jobSubmission: Setting;
  wabSweeps: Setting;
  wabHost: string;
  mpiDistribution: Setting;
  topology: Setting;
  dockerRegistry: Setting;
  allowGPT2Model: Setting;
  dockerRegistryData?: IRegistryData;
  newCliInstaller: Setting;
  deletedWorkloadsView: Setting;
}

export interface IResourceSectionSettings {
  departments: Setting;
  projectsOverDepartmentQuota: Setting;
  cpuOverQuota: Setting;
  overQuotaPriority: Setting;
  gpuOptimization: Setting;
}

export interface IIdpMapperItem {
  key: string;
  label: string;
  value: string;
}

export interface IWrapperBoxSettingMessages {
  headerTitle: string;
  editToolTip: string;
  removeToolTip: string;
  removeDisable: string;
  confirmRemoveTitle: string;
  confirmRemoveSubTitle: string;
}

export interface IRegistryData {
  url: string;
  user: string;
  password: string;
}

export interface ISettingEmailServer {
  smtpHost: string;
  smtpPort: number;
  username: string;
  password: string;
  fromEmail: string;
  fromName: string;
  authType: EmailAuthType;
}

export interface SamlConfigSettings {
  idpEntityId: string;
  singleSignOnServiceUrl: string;
  signingCertificate: string;
}
